import React, { useState, useMemo } from "react";
import { Box, TextField } from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";

const ReusableTableMovimientos = ({ columns, rows, loading, searchField }) => {
  const [searchText, setSearchText] = useState("");

  const filteredRows = useMemo(() => {
    const searchLowercase = searchText.toLowerCase();
    return rows.filter((row) => {
      const searchValue = row[searchField] ? row[searchField].toString().toLowerCase() : "";
      return searchValue.includes(searchLowercase);
    });
  }, [searchText, rows, searchField]);

  return (
    <Box sx={{ height: 540, width: 1 }}>
      <TextField
        fullWidth
        size="small"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        variant="outlined"
        placeholder={`Buscar por usuario...`}
        sx={{ mb: 0.5 }}
      />
      <DataGrid
        rows={filteredRows}
        columns={columns.map((column) => ({
          ...column,
        }))}
        pageSize={5}
        loading={loading}
        checkboxSelection={false}
        autoPageSize={true}
        disableColumnMenu={true}
        disableColumnSelector
        disableRowSelectionOnClick
        disableColumnFilter
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        getRowId={(row) => row.ID}
        sx={{
          ".MuiDataGrid-virtualScroller": {
            overflowX: "auto",
          },
        }}
      />
    </Box>
  );
};

export default ReusableTableMovimientos;

