import React from "react";
import { Box, Button } from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";
import * as XLSX from "xlsx";
import "react-toastify/dist/ReactToastify.css";

const TableHome = ({ columns, rows, loading }) => {

  const downloadExcel = () => {
    const baseURL = "https://backend.cargoexteam.cl/inicio/obtenerFoto/";
  
    const dataToExport = rows.map((row) => {
      const filteredRow = {};
      columns.forEach((column) => {
        filteredRow[column.headerName] = row[column.field];
      });
      filteredRow["Foto URL"] = { f: `HYPERLINK("${baseURL + row["OD_PAPEL"]}", "Ver Foto")` };
      return filteredRow;
    });
  
    const worksheet = XLSX.utils.json_to_sheet(dataToExport, {cellDates:true, bookSST:true});
  
    const range = XLSX.utils.decode_range(worksheet['!ref']);
    for(let R = range.s.r + 1; R <= range.e.r; ++R) {
      const cellRef = XLSX.utils.encode_cell({r: R, c: columns.length});
      if(worksheet[cellRef]) {
        worksheet[cellRef].t = 's';
      }
    }
  
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Pendientes");
  
    XLSX.writeFile(workbook, "ListadoPendientes.xlsx");
  };  
  
  return (
    <Box sx={{ height: 400, width: "100%" }}>
      <Box sx={{ display: "flex", gap: 1, alignItems: "center", mb: 1 }}>
        <Button
          variant="contained"
          onClick={downloadExcel}
          sx={{ maxHeight: 40, backgroundColor: "#1A7042", color: "white" }}
        >
          Descargar Excel
        </Button>
      </Box>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        loading={loading}
        checkboxSelection={false}
        autoPageSize={true}
        disableRowSelectionOnClick
        getRowId={(row) => row.OD_PAPEL}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        sx={{
          ".MuiDataGrid-virtualScroller": {
            overflowX: "auto",
          },
        }}
      />
    </Box>
  );
};

export default TableHome;
