import React, { useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { obtenerGraficosGeneral } from "../../services/reportabilidadEspecialService";
import { Container, Grid, Typography, Card, CardContent, LinearProgress, Box } from "@mui/material";

const ReportabilidadEjecutiva = () => {
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [datos, setDatos] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [isCarouselReady, setIsCarouselReady] = useState(false);

  useEffect(() => {
    const cargarDatos = async () => {
      if (!isDataLoaded) {
        let interval = setInterval(() => {
          setLoadingProgress((oldProgress) => {
            if (oldProgress >= 80) {
              clearInterval(interval);
              return oldProgress;
            }
            const diff = Math.random() * 10;
            return Math.min(oldProgress + diff, 80);
          });
        }, 500);
      }

      try {
        const data = await obtenerGraficosGeneral();
        setDatos(data);
        setIsDataLoaded(true);
        setLoadingProgress(100);
      } catch (error) {
        console.error("Error al cargar los datos:", error);
        setLoadingProgress(0);
      }
    };

    cargarDatos();
    const intervalId = setInterval(cargarDatos, 60000);
    return () => clearInterval(intervalId);
  }, [isDataLoaded]);

  useEffect(() => {
    const processData = () => {
      const data = {};
      datos.forEach((dato) => {
        const ejecutiva = dato.EJECUTIVA || "No asignada";
        if (!data[ejecutiva]) {
          data[ejecutiva] = { clientes: [], totalOrdenes: 0, totalClientes: 0, ejecutiva };
        }
        let clienteFound = data[ejecutiva].clientes.find((c) => c.cliente === dato.CLIENTE);
        if (!clienteFound) {
          clienteFound = {
            cliente: dato.CLIENTE,
            enTransito: 0,
            enTransitoConEvento: 0,
            entregadas: 0,
            total: 0,
          };
          data[ejecutiva].clientes.push(clienteFound);
          data[ejecutiva].totalClientes++;
        }
        clienteFound.total++;

        if (dato.TIENE_ENTREGA === "SI") {
          clienteFound.entregadas++;
        } else if (dato.TIENE_ENTREGA === "NO" && dato.TIENE_EVENTO === "SI") {
          clienteFound.enTransitoConEvento++;
        } else if (dato.TIENE_ENTREGA === "NO" && dato.TIENE_EVENTO === "NO") {
          clienteFound.enTransito++;
        }
        data[ejecutiva].totalOrdenes++;
      });

      setCustomerData(Object.values(data));
    };

    if (datos.length) {
      processData();
    }
  }, [datos]);

  useEffect(() => {
    if (customerData.length > 0) {
      setIsCarouselReady(true);
    }
  }, [customerData]);

  return (
    <>
      {!isDataLoaded && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "90vh",
          }}
        >
          <LinearProgress
            variant="determinate"
            value={loadingProgress}
            sx={{ width: "50%", height: 8, borderRadius: 5 }}
          />
          <Typography variant="body2" sx={{ mt: 1 }}>
            Cargando... {loadingProgress.toFixed(0)}%
          </Typography>
        </Box>
      )}

      {isCarouselReady && (
        <Carousel
          showArrows={true}
          emulateTouch={false}
          useKeyboardArrows={true}
          infiniteLoop={true}
          autoPlay={true}
          interval={30000}
          showStatus={false}
          showThumbs={false}
        >
          {customerData.map((ejecutiva, index) => (
            <Container key={index} maxWidth="lg" sx={{ marginBottom: 3 }}>
              {" "}
              {/* Espacio inferior reducido */}
              <Typography variant="h6" gutterBottom sx={{ textAlign: "center", fontSize: "1rem" }}>
                {" "}
                {/* Tamaño de fuente reducido */}
                Ejecutiva: {ejecutiva.ejecutiva} / Total de Órdenes: {ejecutiva.totalOrdenes} / Total de Clientes:{" "}
                {ejecutiva.totalClientes}
              </Typography>
              <Grid
                container
                spacing={2}
                sx={{
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {ejecutiva.clientes.map((client, idx) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={idx}>
                    <Card raised sx={{ minHeight: "90%" }}>
                      {" "}
                      <CardContent
                        sx={{
                          padding: 1,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          noWrap
                          sx={{ textAlign: "center", width: "100%", fontSize: "0.8rem" }}
                        >
                          {" "}
                          {client.cliente}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          sx={{ color: "#DA251C", mt: 0.1, textAlign: "center", fontSize: "0.8rem" }}
                        >
                          {" "}
                          En Transito: {client.enTransito}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          sx={{ color: "#041562", mt: 0.1, textAlign: "center", fontSize: "0.8rem" }}
                        >
                          {" "}
                          En transito con eventos: {client.enTransitoConEvento}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          sx={{ color: "#4fa662", mt: 0.1, textAlign: "center", fontSize: "0.8rem" }}
                        >
                          {" "}
                          Entregadas: {client.entregadas}
                        </Typography>
                        <Typography
                          variant="h6"
                          color="primary"
                          sx={{ fontWeight: "bold", textAlign: "center", fontSize: "0.875rem", mt: 1 }} // Tamaño de fuente ajustado
                        >
                          Efectividad: {client.total > 0 ? ((client.entregadas / client.total) * 100).toFixed(2) : 0}%
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Container>
          ))}
        </Carousel>
      )}
    </>
  );
};

export default ReportabilidadEjecutiva;
