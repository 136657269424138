import axios from "axios";
/* Usa la variable de entorno para la URL base */
const BASE_API_URL = process.env.REACT_APP_BACKEND_URL;

/* obtener comunas */
export const obtenerComunas = async () => {
  try {
    const response = await axios.get(`${BASE_API_URL}consolidado/obtenerComunas`);
    return response.data;
  } catch (error) {
    console.error("Error fetching obtenerComunas:", error);
    throw error;
  }
};

/* obtener choferes segun comuna */
export const obtenerChoferesPorComuna = async (iata) => {
  try {
    const response = await axios.get(`${BASE_API_URL}consolidado/ChoferesPorComuna/${iata}`);
    return response.data;
  } catch (error) {
    console.error(`Error obteniendo choferes para la comuna ${iata}:`, error);
    throw error;
  }
};

/* obtener estados */
export const obtenerEstados = async () => {
  try {
    const response = await axios.get(`${BASE_API_URL}consolidado/getEstados`);
    return response.data;
  } catch (error) {
    console.error("Error fetching obtenerEstados:", error);
    throw error;
  }
};

/* crear evento */
export const createCertificacion = async (certificacionData) => {
  try {
    const response = await axios.post("https://app.cargoex.cl/app/cargoex/app/gestiones", certificacionData, {
      headers: {
        "X-API-KEY": "55IcsddHxiy2E3q653RpYtb",
      },
    });
    if (response.status !== 200) {
      throw new Error("Error al crear la orden");
    }
    return response.data;
  } catch (error) {
    console.log("Hubo un error al hacer la solicitud:", error);
    throw error;
  }
};

/* obtener info od */
export const obtenerInfoOd = async (od) => {
  try {
    const response = await axios.post(`${BASE_API_URL}consolidado/obtenerInfoOd`, { od });
    return response.data;
  } catch (error) {
    console.error("Error obteniendo info de la orden", error);
    throw error;
  }
};
