import React, { memo, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { obtenerCiudades } from "../../services/PortalCiudadesService";
import TableListadoCiudades from "../../components/DespachoComponentes/componentesListadoCiudades/tableListadoCiudades";
import "react-toastify/dist/ReactToastify.css";

const ListadoCiudades = () => {
  /* accedemos al id de usuario */
  // eslint-disable-next-line no-unused-vars
  const userInfo = useSelector((state) => state.user.userInfo);
  const [ciudades, setCiudades] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const cargarCiudades = async () => {
      setLoading(true);
      try {
        const data = await obtenerCiudades();
        setCiudades(data);
      } catch (error) {
        console.error("Error al obtener los ciudades:", error);
      } finally {
        setLoading(false);
      }
    };
    cargarCiudades();
  }, []);

  const columns = [
    { field: "IATA", headerName: "Iata", width: 60 },
    { field: "NOMBRE", headerName: "Nombre", width: 180 },
    { field: "REGION", headerName: "Region", width: 100 },
    { field: "SECTOR", headerName: "Sector", width: 100 },
    { field: "TIPO", headerName: "Tipo", width: 70 },
    { field: "TIPO_DESCRIPCION", headerName: "Descripcion", width: 130 },
    { field: "IATA_PADRE", headerName: "Iata padre", width: 120 },
    { field: "NOMBRE_PADRE", headerName: "Nombre padre", width: 140 },
    { field: "VIA", headerName: "Via", width: 130 },
    { field: "TIEMPO_TRANSITO", headerName: "Tiempo transito", width: 130 },
  ];

  return (
    <div>
      <TableListadoCiudades columns={columns} rows={ciudades} loading={loading} />
    </div>
  );
};

export default memo(ListadoCiudades);
