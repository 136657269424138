import axios from "axios";
/* Usa la variable de entorno para la URL base */
const BASE_API_URL = process.env.REACT_APP_BACKEND_URL;

const apiClient = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

/* obtenemos agentes de negocios */
export const obtenerAgentesNegocios = async () => {
  try {
    const response = await axios.get(`${BASE_API_URL}usuarios/obtenerAgentesNegocios`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/* obtenemos clientes */
export const obtenerClientes = async () => {
  try {
    const response = await axios.get(`${BASE_API_URL}cedibles/obtenerClientes`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/* obtenemos usuarios */
export const obtenerUsuarios = async () => {
  try {
    const response = await axios.get(`${BASE_API_URL}usuarios/obtenerUsuario`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/* actualizar usuarios */
export const actualizarUsuarios = async (id, usuariosData) => {
  try {
    const response = await axios.put(`${BASE_API_URL}usuarios/actualizarUsuario/${id}`, usuariosData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/* crear usuarios */
export const crearUsuarios = async (usuariosData) => {
  try {
    const response = await axios.post(`${BASE_API_URL}usuarios/crearUsuario`, usuariosData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/* eliminar usuarios */
export const eliminarUsuarios = async (id) => {
  try {
    const response = await axios.delete(`${BASE_API_URL}usuarios/eliminarUsuario/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/* aqui actualizamos el hash */
export const actualizarPasswordUsuario = async (nuevaPassword) => {
  try {
    const respuesta = await apiClient.post("/usuarios/actualizarPassword", {
      nuevaPassword,
    });
    return respuesta.data;
  } catch (error) {
    console.error("Error al actualizar la contraseña:", error);
    throw error;
  }
};
