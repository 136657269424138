import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  IconButton,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  actualizarPasswordUsuario,
  obtenerCodigoChofer,
  obtenerAgentesNegocios,
} from "../../../services/PortalChoferesService";
import EditIcon from "@mui/icons-material/Edit";
import Autocomplete from "@mui/material/Autocomplete";
import { obtenerComunas } from "../../../services/EventosManualServices";
import { validate, clean, format } from "rut.js";

const emptyFormData = {
  name: "",
  email: "",
  tipo_usuario: "",
  password_palabras: "",
  password: "",
  token: "",
  cod_cliente: "",
  canal: "",
  iata_sucursal: "",
  iata_agentes: "",
  ESTADO: "",
  permite_rebaja_manual: "",
  permite_rebaja_masiva: "",
  permite_modificacion_orden: "",
};

const CreateModalChoferes = ({ open, onClose, onSave }) => {
  const [agentesNegocios, setAgentesNegocios] = useState([]);
  const [proximoCodChofer, setProximoCodChofer] = useState("");
  const [comunas, setComunas] = useState([]);
  const [comunaSeleccionada, setComunaSeleccionada] = useState(null);
  const [isValid, setIsValid] = useState(true);

  const [formData, setFormData] = useState({
    NOMBRE: "",
    RUT: "",
    PASSWORD: "",
    PASSWORD_HASH: "",
    COD_CHOFER: "",
    CIUDAD: "",
    TELEFONO: "",
    MAIL: "",
    ESTADO: "",
    TRANSPORTISTA: "",
    CHOFER_ADMINISTRATIVO: "",
    AGENTE_ASIGNADO: "",
    PERMITE_APP: "",
    PERMITE_RETIROS: "",
    PERMITE_IMPRESORA: "",
    PERMITE_ENTREGAS: "",
    PERMITE_INSPECCION: "",
    PERMITE_RETORNOS: "",
  });

  const handleRutChange = (event) => {
    const rutInput = event.target.value;
    const cleanedRut = clean(rutInput);
    // Limitar la longitud del RUT limpio a un máximo de 9 caracteres (8 dígitos + 1 para el verificador)
    const limitedRut = cleanedRut.slice(0, 9);

    if (limitedRut.length >= 7 && validate(limitedRut)) {
      // Valida el RUT limpio y verifica la longitud
      const formattedRut = format(limitedRut, { dots: false });
      setFormData((prev) => ({ ...prev, RUT: formattedRut }));
      setIsValid(true);
    } else {
      setFormData((prev) => ({ ...prev, RUT: limitedRut }));
      setIsValid(false);
    }
  };

  useEffect(() => {
    if (!formData.COD_CHOFER) {
      setFormData((prev) => ({ ...prev, COD_CHOFER: proximoCodChofer }));
    }
  }, [formData.COD_CHOFER, proximoCodChofer]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  /* Función para resetear el formulario */
  const resetForm = () => {
    setFormData(emptyFormData);
    setComunaSeleccionada(null);
    setProximoCodChofer("");
  };
  
  const handleEditPasswordClick = async () => {
    const campoPassword = "PASSWORD";
    const nuevaPassword = formData[campoPassword];
    if (!nuevaPassword || nuevaPassword.length < 6) {
      toast.error("La contraseña debe tener al menos 6 caracteres");
      return;
    }
    try {
      const response = await actualizarPasswordUsuario(nuevaPassword);
      const { hash } = response;
      setFormData((prevFormData) => ({
        ...prevFormData,
        PASSWORD_HASH: hash,
      }));
      toast.success("Hash actualizado con éxito");
    } catch (error) {
      console.error("Error al actualizar el Hash:", error);
      toast.error("Error al actualizar el Hash");
    }
  };

  useEffect(() => {
    if (open) {
      const cargarCodigoChofer = async () => {
        try {
          const response = await obtenerCodigoChofer();
          setProximoCodChofer(response.proximoCod);
        } catch (error) {
          console.error("Error al obtener el código de chofer:", error);
          toast.error("Error al cargar el código de chofer.");
        }
      };
      cargarCodigoChofer();
    }
  }, [open]);

  useEffect(() => {
    const cargarAgentesNegocios = async () => {
      try {
        const listaAgentes = await obtenerAgentesNegocios();
        setAgentesNegocios(listaAgentes);
      } catch (error) {
        console.error("Error al obtener los agentes de negocios:", error);
        toast.error("Error al cargar la lista de agentes de negocios.");
      }
    };
    cargarAgentesNegocios();
  }, []);

  useEffect(() => {
    const cargarComunas = async () => {
      try {
        const comunasObtenidas = await obtenerComunas();
        setComunas(comunasObtenidas);
      } catch (error) {
        console.error("Error al obtener comunas:", error);
      }
    };
    cargarComunas();
  }, []);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Crear Nuevo Choferes</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Nombre"
          type="text"
          fullWidth
          variant="outlined"
          name="NOMBRE"
          value={formData.NOMBRE || ""}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          label="Rut"
          type="text"
          fullWidth
          variant="outlined"
          name="RUT"
          value={formData.RUT || ""}
          onChange={handleRutChange}
        />
        {!isValid && (
          <Typography style={{ color: "red", textAlign: "center", marginBottom: 10 }}>
            Por favor, ingrese un RUT válido.
          </Typography>
        )}
        <TextField
          margin="dense"
          label="Password"
          type="text"
          fullWidth
          variant="outlined"
          name="PASSWORD"
          value={formData.PASSWORD || ""}
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="toggle password edit" onClick={handleEditPasswordClick}>
                  <EditIcon style={{ color: "#041562" }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          margin="dense"
          label="Password Hash"
          type="text"
          fullWidth
          variant="outlined"
          name="PASSWORD_HASH"
          value={formData.PASSWORD_HASH || ""}
          onChange={handleChange}
          disabled
        />
        <Autocomplete
          id="combo-box-comuna"
          options={comunas}
          getOptionLabel={(option) => (option ? `${option.NOMBRE} (${option.IATA})` : "")}
          isOptionEqualToValue={(option, value) => option.IATA === value.IATA}
          value={comunaSeleccionada}
          onChange={(event, newValue) => {
            setComunaSeleccionada(newValue);
            setFormData((prev) => ({
              ...prev,
              CIUDAD: newValue ? newValue.IATA : "",
            }));
          }}
          renderInput={(params) => (
            <TextField {...params} label="Seleccione una comuna" variant="outlined" fullWidth margin="dense" />
          )}
        />
        {agentesNegocios && (
          <Autocomplete
            id="combo-box-agente"
            options={agentesNegocios}
            getOptionLabel={(option) => (option ? `${option.NOMBRE_AGENTE} (${option.IATA_PADRE})` : "")}
            value={
              agentesNegocios.find(
                (agent) => `${agent.NOMBRE_AGENTE} (${agent.IATA_PADRE})` === formData.AGENTE_ASIGNADO
              ) || null
            }
            onChange={(event, newValue) => {
              setFormData({
                ...formData,
                AGENTE_ASIGNADO: newValue ? `${newValue.NOMBRE_AGENTE} (${newValue.IATA_PADRE})` : "",
              });
            }}
            renderInput={(params) => (
              <TextField
                style={{ marginTop: "10px" }}
                {...params}
                label="Agente Asignado"
                variant="outlined"
                fullWidth
              />
            )}
          />
        )}
        <TextField
          margin="dense"
          label="Correo"
          type="email"
          fullWidth
          variant="outlined"
          name="MAIL"
          value={formData.MAIL || ""}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          label="Telefono"
          type="email"
          fullWidth
          variant="outlined"
          name="TELEFONO"
          value={formData.TELEFONO || ""}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          label="Código de Chofer"
          type="text"
          fullWidth
          variant="outlined"
          name="COD_CHOFER"
          value={formData.COD_CHOFER || proximoCodChofer}
          onChange={handleChange}
          helperText={`El próximo código de chofer disponible es: ${proximoCodChofer}`}
        />
        <FormControl style={{ marginTop: "5px" }} fullWidth>
          <InputLabel id="demo-simple-select-label">Seleccione un estado</InputLabel>
          <Select
            margin="dense"
            label="Seleccione un estado"
            fullWidth
            variant="outlined"
            name="ESTADO"
            value={formData.ESTADO}
            onChange={handleChange}
          >
            <MenuItem value="ACTIVO">ACTIVO</MenuItem>
            <MenuItem value="INACTIVO">INACTIVO</MenuItem>
          </Select>
        </FormControl>
        <FormControl style={{ marginTop: "10px" }} fullWidth>
          <InputLabel id="demo-simple-select-label">Es Transportista?</InputLabel>
          <Select
            margin="dense"
            label="Es Transportista?"
            fullWidth
            variant="outlined"
            name="TRANSPORTISTA"
            value={formData.TRANSPORTISTA}
            onChange={handleChange}
          >
            <MenuItem value="SI">SI</MenuItem>
            <MenuItem value="NO">NO</MenuItem>
          </Select>
        </FormControl>
        <FormControl style={{ marginTop: "10px" }} fullWidth>
          <InputLabel id="demo-simple-select-label">Chofer administrativo?</InputLabel>
          <Select
            margin="dense"
            label="Chofer Administrativo"
            fullWidth
            variant="outlined"
            name="CHOFER_ADMINISTRATIVO"
            value={formData.CHOFER_ADMINISTRATIVO}
            onChange={handleChange}
          >
            <MenuItem value="SI">SI</MenuItem>
            <MenuItem value="NO">NO</MenuItem>
          </Select>
        </FormControl>
        <FormControl style={{ marginTop: "10px" }} fullWidth>
          <InputLabel id="demo-simple-select-label">Permite Resto de app?</InputLabel>
          <Select
            margin="dense"
            label="Permite Resto de app?"
            fullWidth
            variant="outlined"
            name="PERMITE_APP"
            value={formData.PERMITE_APP}
            onChange={handleChange}
          >
            <MenuItem value="SI">SI</MenuItem>
            <MenuItem value="NO">NO</MenuItem>
          </Select>
        </FormControl>
        <FormControl style={{ marginTop: "10px" }} fullWidth>
          <InputLabel id="demo-simple-select-label">Permite Retiros?</InputLabel>
          <Select
            margin="dense"
            label="Permite Retiros?"
            fullWidth
            variant="outlined"
            name="PERMITE_RETIROS"
            value={formData.PERMITE_RETIROS}
            onChange={handleChange}
          >
            <MenuItem value="SI">SI</MenuItem>
            <MenuItem value="NO">NO</MenuItem>
          </Select>
        </FormControl>
        <FormControl style={{ marginTop: "10px" }} fullWidth>
          <InputLabel id="demo-simple-select-label">Permite Impresora?</InputLabel>
          <Select
            margin="dense"
            label="Permite Impresora?"
            fullWidth
            variant="outlined"
            name="PERMITE_IMPRESORA"
            value={formData.PERMITE_IMPRESORA}
            onChange={handleChange}
          >
            <MenuItem value="SI">SI</MenuItem>
            <MenuItem value="NO">NO</MenuItem>
          </Select>
        </FormControl>
        <FormControl style={{ marginTop: "10px" }} fullWidth>
          <InputLabel id="demo-simple-select-label">Permite Entregas?</InputLabel>
          <Select
            margin="dense"
            label="Permite Entregas?"
            fullWidth
            variant="outlined"
            name="PERMITE_ENTREGAS"
            value={formData.PERMITE_ENTREGAS}
            onChange={handleChange}
          >
            <MenuItem value="SI">SI</MenuItem>
            <MenuItem value="NO">NO</MenuItem>
          </Select>
        </FormControl>
        <FormControl style={{ marginTop: "10px" }} fullWidth>
          <InputLabel id="demo-simple-select-label">Permite Inspeccion?</InputLabel>
          <Select
            margin="dense"
            label="Permite Inspeccion?"
            fullWidth
            variant="outlined"
            name="PERMITE_INSPECCION"
            value={formData.PERMITE_INSPECCION}
            onChange={handleChange}
          >
            <MenuItem value="SI">SI</MenuItem>
            <MenuItem value="NO">NO</MenuItem>
          </Select>
        </FormControl>
        <FormControl style={{ marginTop: "10px" }} fullWidth>
          <InputLabel id="demo-simple-select-label">Permite Retornos?</InputLabel>
          <Select
            margin="dense"
            label="Permite Retornos?"
            fullWidth
            variant="outlined"
            name="PERMITE_RETORNOS"
            value={formData.PERMITE_RETORNOS}
            onChange={handleChange}
          >
            <MenuItem value="SI">SI</MenuItem>
            <MenuItem value="NO">NO</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            resetForm();
            onClose();
          }}
        >
          Cancelar
        </Button>
        <Button
          onClick={() => {
            resetForm();
            onSave(formData);
          }}
        >
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateModalChoferes;
