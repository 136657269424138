import React, { useState, useMemo } from "react";
import { Box, TextField, Button } from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ReusableTableCediblesPendientes = ({ columns, rows, loading, searchField }) => {
  const [searchText, setSearchText] = useState("");

  const filteredRows = useMemo(() => {
    const searchLowercase = searchText.toLowerCase();
    return rows.filter((row) => {
      const searchValue = row[searchField] ? row[searchField].toString().toLowerCase() : "";
      return searchValue.includes(searchLowercase);
    });
  }, [searchText, rows, searchField]);

  const formatDataForExport = (data) => {
    return data.map((row) => ({
      Guía: row.ID_REFERENCIA_MODIFICADO,
      "OD Papel": row.OD_PAPEL,
      "Nombre Cliente": row.NOMBRE_CLIENTE,
      "Fecha creación": new Date(row.FH_SYS).toLocaleString("es-CL", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      }),
    }));
  };

  const exportToExcel = () => {
    if (filteredRows.length === 0) {
      toast.error("No hay datos para descargar.");
      return;
    }
    const formattedData = formatDataForExport(filteredRows);
    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Pendientes");
    XLSX.writeFile(workbook, "GuiasPendientes.xlsx");
  };

  return (
    <Box sx={{ height: 430, width: 1 }}>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 1 }}>
        <TextField
          fullWidth
          size="small"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          variant="outlined"
          placeholder={`Buscar por guia...`}
          sx={{ mt: 1, flex: 1 }}
        />
        <Button
          variant="contained"
          onClick={exportToExcel}
          sx={{
            mt: 1,
            backgroundColor: "#1A7042",
            color: "white",
            "&:hover": {
              backgroundColor: "#145A32",
            },
          }}
        >
          Descargar Excel
        </Button>
      </Box>

      <DataGrid
        rows={filteredRows}
        columns={columns.map((column) => ({
          ...column,
        }))}
        pageSize={5}
        loading={loading}
        checkboxSelection={false}
        autoPageSize={true}
        disableColumnMenu={true}
        disableColumnSelector
        disableRowSelectionOnClick
        disableColumnFilter
        getRowId={(row) => row.ID}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        sx={{
          ".MuiDataGrid-virtualScroller": {
            overflowX: "auto",
          },
        }}
      />
    </Box>
  );
};

export default ReusableTableCediblesPendientes;
