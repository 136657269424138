import React from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { MenuItem, MenuList } from "@mui/material";
import { useSelector } from "react-redux";
function MobileMenu({ tipoUsuario, handleMobileMenuClose, navigate, openSubmenu, handleToggleSubmenu, handleLogout }) {
  const userInfo = useSelector((state) => state.user.userInfo);
  const menuRules = {
    ADMIN: {
      Inicio: "/",
      Consultas: {
        "Consulta individual": "/ConsultaIndividual",
        "Consulta masiva": "/ConsultaMasivo",
        "Consulta masiva nueva": "/ConsultaPage",
      },
      Despacho: {
        "Gestion de eventos": "/Eventos",
        "Correccion de orden": "/correccionOrden",
        "Carga de retiros": "/RetirosSac",
        "Carga de cedibles": "/cedibles",
        "Control cedibles": "/controlCedibles",
        "Rendicion de cedibles": "/RendicionCedibles",
        Listados: "/Listados",
        "Carga Retiros Santiago APP": "/RetirosAppStgo",
        "Carga Retiros Inter_Region APP": "/RetirosAppInter",
        "Retiros Santiago": "/RetirosSantiago",
        "Retiros Inter-Region": "/RetirosInterRegion",
        "Manifiesto Ruta Agentes": "/ManifiestoRutaAgentes",
      },
      Facturacion: {
        "Gestion de ordenes": "/ordenes",
        "Portal de movimientos": "/portalMovimientos",
      },
      Operaciones: {
        "Manifiesto ingreso bodega": "/ManifiestoIngresoBodega",
        "Manifiesto de salida": "/ManifiestoSalida",
        "Manifiesto de retorno": "/ManifiestosRetorno",
        "Manifiestos de Rutas": "/ListadosManifiestos",
        "Manifiesto salida inhouse": "/ManifiestosSalidaInhouse",
        "Editor de cubicos": "/CubicosOperaciones",
        "Gestor de correos": "/ListaCorreos",
        "Descarga de manifiesto": "/DescargaManifiestoProceso",
      },
      Administracion: {
        "Administrar usuarios": "/PortalUsuarios",
        "Administrar choferes": "/PortalChoferes",
        "Administrar clientes": "/PortalClientes",
        "Asignar clientes": "/AsignacionClientesEjecutivas",
        ControlSupervisora: "/ControlSupervisora",
      },
      GRAFICOS: {
        Inicio: "/",
        /*       "ReportabilidadAgentes": "/ReportabilidadAgentes", */
        ReportabilidadGeneral: "/ReportabilidadGeneral",
        "Reportabilidad ejecutiva": "/ReportabilidadEjecutiva",
      },
      /* "Seguridad y Estadisticas": {
        "Mapa interactivo": "/MapInteractivo",
      }, */
    },
    SAC: {
      Inicio: "/",
      consultas: {
        "Consulta individual": "/ConsultaIndividual",
        "Consulta masiva": "/ConsultaMasivo",
        "Consulta masiva nueva": "/ConsultaPage",
      },
      Despacho: {
        "Gestion de eventos": "/Eventos",
        "Correccion de orden": "/correccionOrden",
        "Carga de retiros": "/RetirosSac",
        "Carga de cedibles": "/cedibles",
        "Control de cedibles": "/controlCedibles",
        "Rendicion de cedibles": "/RendicionCedibles",
        Listados: "/Listados",
        "Carga Retiros Santiago APP": "/RetirosAppStgo",
        "Carga Retiros Inter_Region APP": "/RetirosAppInter",
        "Retiros Santiago": "/RetirosSantiago",
        "Retiros Inter-Region": "/RetirosInterRegion",
        "Manifiesto Ruta Agentes": "/ManifiestoRutaAgentes",
      },
      Facturacion: {
        Ordenes: "/ordenes",
        "Portal de movimientos": "/portalMovimientos",
      },
      Operaciones: {
        "Descarga de manifiesto": "/DescargaManifiestoProceso",
      },
    },
    OPERACIONES: {
      Inicio: "/",
      Consultas: {
        "Consulta individual": "/ConsultaIndividual",
      },
      Operaciones: {
        "Manifiesto ingreso bodega": "/ManifiestoIngresoBodega",
        "Manifiesto de salida": "/ManifiestoSalida",
        "Manifiesto de retorno": "/ManifiestosRetorno",
        "Manifiestos de Rutas": "/ListadosManifiestos",
        "Manifiesto salida inhouse": "/ManifiestosSalidaInhouse",
        "Manifiesto Entrada Santiago": "/ManifiestosEntradaSantiago",
        "Editor de cubicos": "/CubicosOperaciones",
        "Descarga de manifiesto": "/DescargaManifiestoProceso",
      },
    },
    SUPERVISOR: {
      Inicio: "/",
      Consultas: {
        "Consulta individual": "/ConsultaIndividual",
        "Consulta masiva": "/ConsultaMasivo",
      },
      Operaciones: {
        "Manifiesto ingreso bodega": "/ManifiestoIngresoBodega",
        "Manifiesto de salida": "/ManifiestoSalida",
        "Manifiesto de retorno": "/ManifiestosRetorno",
        "Manifiestos de Rutas": "/ListadosManifiestos",
        "Manifiesto salida inhouse": "/ManifiestosSalidaInhouse",
        "Manifiesto Entrada Santiago": "/ManifiestosEntradaSantiago",
        "Editor de cubicos": "/CubicosOperaciones",
        "Gestor de correos": "/ListaCorreos",
        "Descarga de manifiesto": "/DescargaManifiestoProceso",
      },
    },
    SUPERVISORA: {
      Inicio: "/",
      consultas: {
        "Consulta individual": "/ConsultaIndividual",
        "Consulta masiva": "/ConsultaMasivo",
        "Consulta masiva nueva": "/ConsultaPage",
      },
      Despacho: {
        "Gestion de eventos": "/Eventos",
        "Correccion de orden": "/correccionOrden",
        "Carga de retiros": "/RetirosSac",
        "Carga de cedibles": "/cedibles",
        "Control de cedibles": "/controlCedibles",
        "Rendicion de cedibles": "/RendicionCedibles",
        Listados: "/Listados",
        "Carga Retiros Santiago APP": "/RetirosAppStgo",
        "Carga Retiros Inter_Region APP": "/RetirosAppInter",
        "Retiros Santiago": "/RetirosSantiago",
        "Retiros Inter-Region": "/RetirosInterRegion",
        "Manifiesto Ruta Agentes": "/ManifiestoRutaAgentes",
      },
      Facturacion: {
        Ordenes: "/ordenes",
        "Portal de movimientos": "/portalMovimientos",
      },
      Operaciones: {
        "Descarga de manifiesto": "/DescargaManifiestoProceso",
      },
      Administracion: {
        "Asignar clientes": "/AsignacionClientesEjecutivas",
        ControlSupervisora: "/ControlSupervisora",
        PortalChoferes: "/PortalChoferes",
      },
    },
    CLIENTE: {
      Inicio: "/",
      Consultas: {
        // Renderizar dinámicamente las rutas según el cod_cliente
        ...(userInfo.cod_cliente === "632"
          ? {
              "Consulta por Código de Barra": "/ConsultaIndividualClientesCodigoBarra",
            }
          : {
              "Consulta Individual": "/ConsultaIndividualClientes",
            }),
        "Consulta masivo cliente": "/ConsultaMasivoCliente",
      },
      Clientes: {
        "Carga de retiros Santiago": "/RetirosClientesStgo",
        "Carga de retiros Inter-Region": "/RetirosClientesInter",
        "Control de cedibles": "/CediblesClientePage",
        "Control de cedible": "/ControlCediblesNovo",
        "Manifiesto salida inhouse": "/ManifiestosSalidaInhouse",
      },
    },
    GERENCIA: {
      Inicio: "/",
    },
    AGENTE: {
      Inicio: "/",
      Consultas: {
        "Consulta individual": "/ConsultaIndividualAgentes",
        "Consulta masiva": "/ConsultaMasivoAgentes",
      },
      Operaciones: {
        "Manifiesto de retorno": "/ManifiestosRetorno",
        "Manifiestos de Rutas": "/ListadosManifiestos",
        "Descarga de manifiesto": "/DescargaManifiestoProceso",
      },
    },
    FACTURACION: {
      Inicio: "/",
      Consultas: {
        "Consulta individual": "/ConsultaIndividual",
        "Consulta masiva": "/ConsultaMasivo",
        "Consulta masiva nueva": "/ConsultaPage",
      },
      Facturacion: {
        Listados: "/Listados",
        "Correccion de ordenes": "/correccionOrden",
        "Gestion de ordenes": "/ordenes",
        "Portal de movimientos": "/portalMovimientos",
      },
    },
    GRAFICOS: {
      Inicio: "/",
      /*       "ReportabilidadAgentes": "/ReportabilidadAgentes", */
      ReportabilidadGeneral: "/ReportabilidadGeneral",
      "Reportabilidad ejecutiva": "/ReportabilidadEjecutiva",
    },
    /* "SEGURIDAD Y ESTADISTICAS" : {
      inicio: true,
      MapInteractivo: true,
    }, */
  };

  const renderMenuItems = (items, parentKey = "") => {
    return Object.entries(items)
      .map(([key, value]) => {
        const isSubmenu = typeof value === "object";
        const menuItemKey = parentKey ? `${parentKey}-${key}` : key;

        if (isSubmenu) {
          return [
            <MenuItem key={menuItemKey} onClick={() => handleToggleSubmenu(menuItemKey)}>
              {key} {openSubmenu === menuItemKey ? <ExpandLess /> : <ExpandMore />}
            </MenuItem>,
            openSubmenu === menuItemKey && (
              <MenuList key={`submenu-${menuItemKey}`}>{renderMenuItems(value, menuItemKey)}</MenuList>
            ),
          ];
        } else {
          return (
            <MenuItem
              key={menuItemKey}
              onClick={() => {
                navigate(value);
                handleMobileMenuClose();
              }}
            >
              {key}
            </MenuItem>
          );
        }
      })
      .flat();
  };

  return (
    <MenuList>
      {renderMenuItems(menuRules[tipoUsuario])}
      <MenuItem onClick={handleLogout} sx={{ color: "#DA251C" }}>
        Cerrar sesión
      </MenuItem>
    </MenuList>
  );
}

export default MobileMenu;
