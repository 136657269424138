import React from "react";
import { Modal, Paper, Button } from "@mui/material";

const ImageModal = ({
  showModal,
  setShowModal,
  base64Images,
  currentImageIndex,
  setCurrentImageIndex,
  totalImages,
  handlePrint,
}) => {
  return (
    <Modal
      open={showModal}
      onClose={() => setShowModal(false)}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Paper style={{ padding: "20px", width: "auto" }}>
        {base64Images && base64Images.length > 0 ? (
          <>
            <div
              style={{
                textAlign: "center",
                marginBottom: "20px",
                fontSize: "16px",
                color: "#666",
              }}
            >
              <span>{`${currentImageIndex + 1}/${totalImages}`}</span>
            </div>

            <img
              src={`data:image/png;base64,${base64Images[currentImageIndex]}`}
              alt={`Etiqueta ${currentImageIndex}`}
              style={{
                display: "block",
                margin: "auto",
                maxWidth: "none",
                border: "2px solid #000",
                borderRadius: "10px",
              }}
            />
            <div style={{ textAlign: "center", marginTop: "10px" }}>
              {currentImageIndex > 0 && (
                <button
                  style={{
                    background: "#041562",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    padding: "8px 12px",
                    margin: "0 5px",
                    color: "#FFFFFF",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease, opacity 0.3s ease",
                  }}
                  onMouseOver={(e) => {
                    e.target.style.opacity = "0.8";
                  }}
                  onMouseOut={(e) => {
                    e.target.style.opacity = "1";
                  }}
                  onClick={() => setCurrentImageIndex(currentImageIndex - 1)}
                >
                  Anterior
                </button>
              )}

              {currentImageIndex < totalImages - 1 && (
                <button
                  style={{
                    background: "#041562",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    padding: "8px 12px",
                    margin: "0 5px",
                    color: "#FFFFFF",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                  }}
                  onMouseOver={(e) => {
                    e.target.style.opacity = "0.8";
                  }}
                  onMouseOut={(e) => {
                    e.target.style.opacity = "1";
                  }}
                  onClick={() => setCurrentImageIndex(currentImageIndex + 1)}
                >
                  Siguiente
                </button>
              )}
            </div>
          </>
        ) : (
          <p>No hay imágenes disponibles</p>
        )}
        <div style={{ textAlign: "center", marginTop: "10px" }}>
          <Button
            onClick={handlePrint}
            variant="contained"
            sx={{
              backgroundColor: "#041562",
              color: "#FFFFFF",
              "&:hover": {
                backgroundColor: "#031042",
              },
              height: "40px",
              width: "150px",
            }}
          >
            Imprimir
          </Button>
        </div>
      </Paper>
    </Modal>
  );
};

export default ImageModal;
