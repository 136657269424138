import React, { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { fetchCorreos, reenviarCorreo } from "../../services/ListaCorreosServices";
import TableMailGestor from "../../components/OperacionesComponentes/componentesMailGestor/tableMailGestor";
import { Dialog, DialogTitle, DialogContent, Button, TextField } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ListaCorreos() {
  const [correos, setCorreos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [viewContentOpen, setViewContentOpen] = useState(false);
  const [editContentOpen, setEditContentOpen] = useState(false);
  const [htmlContent, setHtmlContent] = useState("");
  const [editContent, setEditContent] = useState({});

  const cabecerasListaCorreos = [
    { field: "id", headerName: "ID", width: 80 },
    { field: "proceso", headerName: "PROCESO", width: 150 },
    { field: "asunto", headerName: "ASUNTO", width: 300 },
    { field: "destinatario", headerName: "DESTINATARIO", width: 200 },
    {
      field: "cuerpo",
      headerName: "CUERPO",
      width: 100,
      renderCell: (params) => (
        <>
          <Button onClick={() => handleOpenViewContent(params.row.cuerpo)}>
            <VisibilityIcon style={{ color: "#041562" }} />
          </Button>
        </>
      ),
    },
    { field: "fh_envio", headerName: "FECHA DE ENVIO", width: 200 },
    {
      field: "acciones",
      headerName: "Acciones",
      width: 130,
      renderCell: (params) => (
        <>
          <Button onClick={() => handleOpenEditModal(params.row)}>
            <EditIcon style={{ color: "#DA251C" }} />
          </Button>
          <Button onClick={() => handleReenviarEmail(params.row)}>
            <SendIcon style={{ color: "#041562" }} />
          </Button>
        </>
      ),
    },
  ];

  const handleOpenViewContent = (content) => {
    setHtmlContent(content);
    setViewContentOpen(true);
  };

  const handleOpenEditModal = (correo) => {
    setEditContent(correo);
    setEditContentOpen(true);
  };

  const handleReenviarEmail = async (correoInfo) => {
    let correoParaReenviar = { ...correoInfo };
    correoParaReenviar.destinatario = editContent.destinatario;
    const result = await reenviarCorreo(correoParaReenviar);
    if (result.success) {
      toast.success("Correo reenviado con éxito");
      setEditContentOpen(false);
    } else {
      toast.error(`Error al reenviar el correo: ${result.message}`);
    }
  };

  useEffect(() => {
    const obtenerCorreos = async () => {
      setLoading(true);
      try {
        const response = await fetchCorreos();
        if (response.success && Array.isArray(response.datos)) {
          setCorreos(
            response.datos.map((correo) => ({
              ...correo,
              id: correo.ID,
              proceso: correo.PROCESO,
              asunto: correo.ASUNTO,
              destinatario: correo.DESTINATARIO,
              cuerpo: correo.CUERPO,
              fh_envio: correo.FH_ENVIO,
            }))
          );
        } else {
          console.error("La respuesta no contiene un arreglo válido de correos:", response);
        }
      } catch (error) {
        console.error("Error al obtener los correos:", error);
      } finally {
        setLoading(false);
      }
    };
    obtenerCorreos();
    const intervalId = setInterval(obtenerCorreos, 5 * 60 * 1000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <TableMailGestor columns={cabecerasListaCorreos} rows={correos} loading={loading} searchField="destinatario" />
      {/* Dialog para ver contenido */}
      <Dialog open={viewContentOpen} onClose={() => setViewContentOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>Contenido del Correo</DialogTitle>
        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
        </DialogContent>
      </Dialog>
      {/* Dialog para editar y reenviar */}
      <Dialog open={editContentOpen} onClose={() => setEditContentOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>Editar Destinatario y Reenviar Correo</DialogTitle>
        <DialogContent>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleReenviarEmail(editContent);
            }}
          >
            <TextField
              label="Destinatario"
              fullWidth
              margin="normal"
              value={editContent.destinatario || ""}
              onChange={(e) => setEditContent({ ...editContent, destinatario: e.target.value })}
            />
            {/* Considera agregar un campo para editar el cuerpo del correo si es necesario */}
            <div style={{ marginTop: 20, display: "flex", justifyContent: "flex-end" }}>
              <Button onClick={() => setEditContentOpen(false)} style={{ color: "#DA251C", marginRight: 8 }}>
                Cancelar
              </Button>
              <Button type="submit" style={{ color: "#041562" }}>
                Reenviar
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ListaCorreos;

