import React, { useState } from "react";
import { Modal, Card, CardContent, Avatar, Typography, Divider, CardActions, Button } from "@mui/material";
import ChangePasswordModal from "./ChangePasswordModal";

const UserProfileModal = ({ open, handleClose, userInfo }) => {
  const [openChangePassword, setOpenChangePassword] = useState(false);

  const handleOpenChangePassword = () => {
    setOpenChangePassword(true);
  };

  const handleCloseChangePassword = () => {
    setOpenChangePassword(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        <Card
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxWidth: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 1,
            borderRadius: 2,
          }}
        >
          <CardContent>
            <Avatar
              sx={{
                bgcolor: "primary.dark",
                width: 56,
                height: 56,
                mt: 0,
                mr: "auto",
                mb: 0,
                ml: "auto",
              }}
            ></Avatar>
            <Typography variant="h5" component="h2" align="center" gutterBottom>
              Información del Perfil
            </Typography>
            <Divider variant="middle" sx={{ my: 1, backgroundColor: "#DA251C" }} />
            <Typography variant="body1" align="center">
              {userInfo.name}
            </Typography>
            <Divider variant="middle" sx={{ my: 1, backgroundColor: "#DA251C" }} />
            <Typography variant="body1" align="center">
              {userInfo.tipo_usuario}
            </Typography>
            <Divider variant="middle" sx={{ my: 1, backgroundColor: "#DA251C" }} />
            <Typography variant="body1" align="center">
              {userInfo.email}
            </Typography>
            <Divider variant="middle" sx={{ my: 1, backgroundColor: "#DA251C" }} />
            <Typography variant="body1" align="center">
              {userInfo.ESTADO}
            </Typography>
          </CardContent>
              <CardActions sx={{ justifyContent: "center" }}>
              <Button style={{ backgroundColor: "primary.main" }} variant="contained" onClick={handleOpenChangePassword}>
                Cambiar Contraseña
              </Button>
          </CardActions>
          <CardActions sx={{ justifyContent: "center" }}>
              <Button style={{ backgroundColor: "#DA251C" }} variant="contained" onClick={handleClose}>
                Cerrar
              </Button>
          </CardActions>
        </Card>
        <ChangePasswordModal open={openChangePassword} handleClose={handleCloseChangePassword} userInfo={userInfo} />
      </>
    </Modal>
  );
};

export default UserProfileModal;
