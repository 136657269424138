import React, { useState, useMemo } from "react";
import { DataGrid, esES } from "@mui/x-data-grid";
import { MenuItem, Grid, TextField, Box } from "@mui/material";

const ReusableTableConsultasCedibles = ({ columns, rows, loading, searchField }) => {
  // eslint-disable-next-line no-unused-vars
  const [searchText, setSearchText] = useState("");
  const [estado, setEstado] = useState("todas");

  const filteredRows = useMemo(() => {
    const searchLowercase = searchText.toLowerCase();
    return rows.filter((row) => {
      const searchValue = row[searchField] ? row[searchField].toString().toLowerCase() : "";
      const matchesSearch = searchValue.includes(searchLowercase);
      if (estado === "todas") {
        return matchesSearch;
      } else if (estado === "cediblesRendidas") {
        return matchesSearch && row.COD_MANIFIESTO != null;
      } else if (estado === "cediblesNoRendidas") {
        return matchesSearch && row.COD_MANIFIESTO == null;
      }
      return false;
    });
  }, [searchText, rows, searchField, estado]);

  return (
    <Box sx={{ height: 400, width: '100%' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <TextField
            select
            label="Estado"
            value={estado}
            onChange={(e) => setEstado(e.target.value)}
            fullWidth
            margin="dense"
            variant="outlined"
            size="small"
          >
            <MenuItem value="cediblesRendidas">CEDIBLES RENDIDAS</MenuItem>
            <MenuItem value="cediblesNoRendidas">CEDIBLES NO RENDIDAS</MenuItem>
            <MenuItem value="todas">TODAS</MenuItem>
          </TextField>
        </Grid>
      </Grid>
      <DataGrid
        rows={filteredRows}
        columns={columns.map((column) => ({
          ...column,
        }))}
        pageSize={5}
        loading={loading}
        checkboxSelection={false}
        autoPageSize={true}
        disableColumnMenu={true}
        disableColumnSelector
        disableRowSelectionOnClick
        disableColumnFilter
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        getRowId={(row) => row.ID || row.OD_PAPEL}
        sx={{
          ".MuiDataGrid-virtualScroller": {
            overflowX: "auto",
          },
        }}
      />
    </Box>
  );
};

export default ReusableTableConsultasCedibles;
