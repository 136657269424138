import React, { memo, useState } from "react";
import GestorEventos from "./GestorEventos";
import CreateModalEventos from "../../components/DespachoComponentes/componentesEventos/CreateModalEventos";
import CreateModalEventosMasivo from "../../components/DespachoComponentes/componentesEventos/CreateModalEventosMasivo";
import { Box, Button, ButtonGroup, Typography } from "@mui/material";

const Eventos = () => {
  const [selectedModule, setSelectedModule] = useState("");

  const renderModule = () => {
    switch (selectedModule) {
      case "gestorDeEventos":
        return <GestorEventos />;
      case "creacionEventoManual":
        return <CreateModalEventos />;
      case "creacionEventoMasivo":
        return <CreateModalEventosMasivo />;
      default:
        return <Typography variant="h6">Seleccione un módulo para mostrar</Typography>;
    }
  };

  return (
    <Box p={3}>
      <ButtonGroup variant="outlined" color="primary" aria-label="outlined primary button group">
        <Button
          onClick={() => setSelectedModule("gestorDeEventos")}
          sx={{
            backgroundColor: selectedModule === "gestorDeEventos" ? "#041562" : undefined,
            color: selectedModule === "gestorDeEventos" ? "#fff" : undefined,
            "&:hover": {
              backgroundColor: selectedModule === "gestorDeEventos" ? "#DA251C" : undefined,
            },
          }}
        >
          Gestor De Eventos
        </Button>
        <Button
          onClick={() => setSelectedModule("creacionEventoManual")}
          sx={{
            backgroundColor: selectedModule === "creacionEventoManual" ? "#041562" : undefined,
            color: selectedModule === "creacionEventoManual" ? "#fff" : undefined,
            "&:hover": {
              backgroundColor: selectedModule === "creacionEventoManual" ? "#DA251C" : undefined,
            },
          }}
        >
          Creacion Evento Manual
        </Button>
        <Button
          onClick={() => setSelectedModule("creacionEventoMasivo")}
          sx={{
            backgroundColor: selectedModule === "creacionEventoMasivo" ? "#041562" : undefined,
            color: selectedModule === "creacionEventoMasivo" ? "#fff" : undefined,
            "&:hover": {
              backgroundColor: selectedModule === "creacionEventoMasivo" ? "#DA251C" : undefined,
            },
          }}
        >
          Creacion Evento Masivo
        </Button>
      </ButtonGroup>
      <Box mt={3}>{renderModule()}</Box>
    </Box>
  );
};

export default memo(Eventos);
