import React, { memo, useEffect, useState } from "react";
import {
  obtenerEjecutivasClientes,
  agregarClienteAEjecutiva,
  eliminarClienteDeEjecutiva,
  obtenerEjecutivasSac,
} from "../../services/EjecutivasClientesService";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Delete as DeleteIcon, Add as AddIcon } from "@mui/icons-material";
import CustomAutocomplete from "../../utils/CustomAutocomplete";
import { obtenerClientes } from "../../services/ordenesServices";
import { Button, Box, Modal } from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const AsignacionClientesEjecutivas = () => {
  const [datosAgrupados, setDatosAgrupados] = useState({});
  const [expanded, setExpanded] = useState(false);
  const [clientes, setClientes] = useState([]);
  const [ejecutivas, setEjecutivas] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedCliente, setSelectedCliente] = useState(null);
  const [selectedEjecutiva, setSelectedEjecutiva] = useState(null);
  const [currentEjecutiva, setCurrentEjecutiva] = useState("");
  const [currentEjecutivaId, setCurrentEjecutivaId] = useState(null);
  const [openNewAssignmentModal, setOpenNewAssignmentModal] = useState(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleOpenNewAssignmentModal = () => {
    setOpenNewAssignmentModal(true);
  };

  const handleCloseNewAssignmentModal = () => {
    setOpenNewAssignmentModal(false);
  };

  const handleNewAssignment = async () => {
    if (!selectedCliente || !selectedEjecutiva) {
      toast.error("Por favor, selecciona tanto una ejecutiva como un cliente.");
      return;
    }
    try {
      const fecha = new Date().toISOString().split('T')[0];
      await agregarClienteAEjecutiva(selectedEjecutiva.id, selectedCliente.ID, fecha);
      toast.success("Cliente asignado a ejecutiva con éxito!");
      setDatosAgrupados((prevDatos) => {
        const newDatos = { ...prevDatos };
        const nombreEjecutiva = selectedEjecutiva.name;
        if (!newDatos[nombreEjecutiva]) {
          newDatos[nombreEjecutiva] = {
            idEjecutiva: selectedEjecutiva.id,
            clientes: []
          };
        }
        newDatos[nombreEjecutiva].clientes.push({
          nombre: selectedCliente.NOMBRE,
          id: selectedCliente.ID
        });
        return newDatos;
      });
      setSelectedCliente(null);
      setSelectedEjecutiva(null);
      handleCloseNewAssignmentModal();
    } catch (error) {
      console.error("Error al asignar cliente a ejecutiva:", error);
      toast.error("Error al asignar cliente. Inténtalo de nuevo.");
    }
  };
  
  const handleAddCliente = async (idEjecutiva, cliente) => {
    console.log("Cliente seleccionado:", cliente);
    if (!cliente || !idEjecutiva) {
      toast.error("Por favor, selecciona un cliente y verifica que la ejecutiva está seleccionada.");
      return;
    }
    try {
      const fecha = new Date().toISOString();
      await agregarClienteAEjecutiva(idEjecutiva, cliente.ID, fecha);
      toast.success("Cliente agregado con éxito!");

      setDatosAgrupados((prevDatos) => {
        const newDatos = { ...prevDatos };
        const nombreEjecutiva = Object.keys(newDatos).find((key) => newDatos[key].idEjecutiva === idEjecutiva);
        if (nombreEjecutiva) {
          newDatos[nombreEjecutiva].clientes.push({
            nombre: cliente.NOMBRE,
            id: cliente.ID,
          });
        }
        return newDatos;
      });
      setSelectedCliente(null);
      handleCloseModal();
    } catch (error) {
      console.error("Error al agregar cliente:", error);
      toast.error("Error al agregar cliente. Inténtalo de nuevo.");
    }
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleDelete = async (idEjecutiva, codCliente) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, eliminar!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        eliminarClienteDeEjecutiva(idEjecutiva, codCliente)
          .then((response) => {
            toast.success("Asignación eliminada con éxito!");
            setDatosAgrupados((prevDatos) => {
              const newDatos = { ...prevDatos };
              Object.keys(newDatos).forEach((ejecutiva) => {
                if (newDatos[ejecutiva].idEjecutiva === idEjecutiva) {
                  newDatos[ejecutiva].clientes = newDatos[ejecutiva].clientes.filter(
                    (cliente) => cliente.id !== codCliente
                  );
                  if (newDatos[ejecutiva].clientes.length === 0) {
                    delete newDatos[ejecutiva];
                  }
                }
              });
              return newDatos;
            });
          })
          .catch((error) => {
            console.error("Error al eliminar la asignación:", error);
            toast.error("Error al eliminar la asignación. Inténtalo de nuevo.");
          });
      }
    });
  };

  const handleAdd = (nombreEjecutiva, idEjecutiva) => {
    setCurrentEjecutiva(nombreEjecutiva);
    setCurrentEjecutivaId(idEjecutiva);
    handleOpenModal();
  };

  useEffect(() => {
    const cargarDatos = async () => {
      try {
        const datosRecibidos = await obtenerEjecutivasClientes();
        const agrupados = datosRecibidos.reduce((acc, item) => {
          const nombreEjecutiva = item.nombre_ejecutiva;
          if (!acc[nombreEjecutiva]) {
            acc[nombreEjecutiva] = {
              idEjecutiva: item.id_users,
              clientes: [],
            };
          }
          acc[nombreEjecutiva].clientes.push({
            nombre: item.nombre_cliente,
            id: item.cod_cliente,
          });
          return acc;
        }, {});
        setDatosAgrupados(agrupados);
      } catch (error) {
        console.error("Error al cargar los datos de ejecutivas y clientes", error);
      }
    };

    cargarDatos();
  }, []);

  /* cargar ejecutivas */
  useEffect(() => {
    const cargarEjecutivas = async () => {
      try {
        const ejecutivasObtenidas = await obtenerEjecutivasSac();
        setEjecutivas(ejecutivasObtenidas);
      } catch (error) {}
    };
    cargarEjecutivas();
  }, []);

  /* cargar clientes */
  useEffect(() => {
    const cargarClientes = async () => {
      try {
        const clientesObtenidos = await obtenerClientes();
        setClientes(clientesObtenidos);
      } catch (error) {}
    };
    cargarClientes();
  }, []);

  return (
    <div>
      <Button
        onClick={handleOpenNewAssignmentModal}
        style={{
          textTransform: "none",
          justifyContent: "flex-start",
          color: "#041562",
          padding: "6px 0",
          width: "100%",
          margin: "10px",
        }}
        startIcon={<AddCircleOutlineIcon />}
      >
        <Typography component="span">Asignar Cliente a Nueva Ejecutiva</Typography>
      </Button>
      <Modal
        open={openNewAssignmentModal}
        onClose={handleCloseNewAssignmentModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Asignar Cliente a Ejecutiva Nueva
          </Typography>
           <CustomAutocomplete
            label="Ejecutivas"
            options={ejecutivas}
            getOptionLabel={(option) => `${option.name} (COD: ${option.id})`}
            value={selectedEjecutiva}
            onChange={(event, newValue) => {
              setSelectedEjecutiva(newValue);
            }}
          />
           <CustomAutocomplete
            label="Clientes"
            options={clientes}
            getOptionLabel={(option) => `${option.NOMBRE} (COD: ${option.ID})`}
            value={selectedCliente}
            onChange={(event, newValue) => {
              setSelectedCliente(newValue);
            }}
          />
          <Button onClick={() => handleNewAssignment(currentEjecutivaId, selectedCliente)} style={{ marginTop: 20 }}>
            Asignar Cliente
          </Button>
        </Box>
      </Modal>
      {Object.entries(datosAgrupados).map(([nombreEjecutiva, data], index) => (
        <Accordion key={index} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}a-content`}
            id={`panel${index}a-header`}
          >
            <Typography>{nombreEjecutiva}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography component="div">
              {data.clientes.map((cliente, idx) => (
                <div
                  key={idx}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                  }}
                >
                  {cliente.nombre}
                  <div>
                    <DeleteIcon
                      style={{ cursor: "pointer", color: "#DA251C" }}
                      onClick={() => handleDelete(data.idEjecutiva, cliente.id)}
                    />
                  </div>
                </div>
              ))}
              <div style={{ marginTop: "20px" }}>
                <Button
                  onClick={() => handleAdd(nombreEjecutiva, data.idEjecutiva)}
                  style={{
                    textTransform: "none",
                    justifyContent: "flex-start",
                    color: "#041562",
                    padding: "6px 0",
                    width: "100%",
                  }}
                  startIcon={<AddIcon />}
                >
                  <Typography component="span">Agregar nuevo cliente</Typography>
                </Button>
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "#fff",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Agregar Cliente a {currentEjecutiva}
          </Typography>
          <CustomAutocomplete
            label="Clientes"
            options={clientes}
            getOptionLabel={(option) => `${option.NOMBRE} (COD: ${option.ID})`}
            value={selectedCliente}
            onChange={(event, newValue) => {
              setSelectedCliente(newValue);
              console.log("Nuevo cliente seleccionado:", newValue);
            }}
          />
          <Button onClick={() => handleAddCliente(currentEjecutivaId, selectedCliente)} style={{ marginTop: 20 }}>
            Agregar Cliente
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default memo(AsignacionClientesEjecutivas);
