import { createSlice } from '@reduxjs/toolkit';

/* Estado inicial */
const initialState = {
  userInfo: null,
  isLoggedIn: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login: (state, action) => {
      state.userInfo = action.payload;
      state.isLoggedIn = true;
      localStorage.setItem('token', action.payload.token);
    },
    logout: (state) => {
      state.userInfo = null;
      state.isLoggedIn = false;
      localStorage.removeItem('token');
    },    
  },
});

/* Acciones */
export const { login, logout } = userSlice.actions;

/* Selector (acceso más fácil al estado en los componentes) */
export const selectIsLoggedIn = (state) => state.user.isLoggedIn;

/* Reducer */
export default userSlice.reducer;
