import axios from "axios";
/* Usa la variable de entorno para la URL base */
const BASE_API_URL = process.env.REACT_APP_BACKEND_URL;

const apiClient = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const descargarArchivo = (downloadId) => {
  try {
    const url = `${BASE_API_URL}consulta/descargar/${downloadId}`;
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Reporte.xlsx");
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  } catch (error) {
    console.error("Error al descargar el archivo:", error);
    throw error;
  }
};

export const iniciarDescarga = async (data) => {
  try {
    const response = await axios.post(`${BASE_API_URL}consulta/DescargarPeriodo`, data);
    if (response.data.downloadId) {
      console.log(response.data);
      return response.data;
    } else {
      throw new Error("Download ID not received");
    }
  } catch (error) {
    console.error("Error al iniciar la descarga:", error);
    throw error;
  }
};

export const verificarEstadoDescarga = async (downloadId) => {
  try {
    const response = await axios.get(`${BASE_API_URL}consulta/estadoDescarga/${downloadId}`);
    return response.data;
  } catch (error) {
    console.error("Error al verificar el estado de la descarga:", error);
    throw error;
  }
};

export const obtenerClientesControlSac = async () => {
  try {
    const response = await axios.get(`${BASE_API_URL}cedibles/obtenerClientes`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const obtenerDetallesPorGuia = async (guia, codCliente) => {
  try {
    const response = await apiClient.post("consulta/obtenerDetallesPorGuia", { guia, codCliente });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/* Función para obtener los detalles de una orden por agente */
export const obtenerDetallesOrdenAgente = async (od, nombreAgente) => {
  try {
    const response = await apiClient.post("consulta/obtenerDetallesOrdenAgente", {
      od,
      agenteAsignado: nombreAgente,
    });
    return response.data;
  } catch (error) {
    console.error("Error al obtener detalles de la orden:", error.response || error);
    throw error;
  }
};

export const obtenerClienteModuloCliente = async (cod_cliente) => {
  try {
    const response = await apiClient.post("consulta/obtenerClienteModuloCliente", { cod_cliente });
    return response.data;
  } catch (error) {
    console.error("Error al obtener detalles de la orden:", error.response || error);
    throw error;
  }
};

export const obtenerDetallesOrdenClienteCodigoBarra = async (codigoBarra, cod_cliente) => {
  console.log(codigoBarra, cod_cliente);
  try {
    const response = await apiClient.post("consulta/obtenerDetallesOrdenClienteCodigoBarra", {
      codigoBarra,
      cod_cliente,
    });

    return response.data;
  } catch (error) {
    console.error("Error al obtener detalles de la orden:", error.response || error);
    throw error;
  }
};

export const obtenerDetallesOrdenCliente = async (od, cod_cliente) => {
  console.log(od, cod_cliente);
  try {
    const response = await apiClient.post("consulta/obtenerDetallesOrdenCliente", { od, cod_cliente });

    return response.data;
  } catch (error) {
    console.error("Error al obtener detalles de la orden:", error.response || error);
    throw error;
  }
};

/* Función para obtener los detalles de una orden */
export const obtenerDetallesOrden = async (od) => {
  try {
    const response = await apiClient.post("consulta/obtenerDetallesOrden", { od });
    return response.data;
  } catch (error) {
    console.error("Error al obtener detalles de la orden:", error.response || error);
    throw error;
  }
};

/* Función para obtener los detalles de una orden */
export const obtenerDetallesOrdenCodigoBarra = async (codigo) => {
  try {
    const response = await apiClient.post("consulta/obtenerDetallesOrdenCodigoBarra", { codigo });
    return response.data;
  } catch (error) {
    console.error("Error al obtener detalles de la orden:", error.response || error);
    throw error;
  }
};

/* consulta individual */
export const Descargarconsulta = async (data) => {
  try {
    const response = await axios.post(`${BASE_API_URL}consulta/DescargarPeriodo`, data, {
      responseType: "blob", // Importante para tratar la respuesta como un archivo
    });

    // Crear un URL para el blob recibido y disparar la descarga
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Reporte.xlsx"); // o cualquier otro nombre de archivo
    document.body.appendChild(link);
    link.click();

    link.parentNode.removeChild(link); // limpiar el DOM
    window.URL.revokeObjectURL(url); // limpiar el objeto URL
  } catch (error) {
    throw error;
  }
};

/* ObtenerConsultaCedibles */
export const ObtenerConsultaCedibles = async (data) => {
  try {
    const response = await axios.post(`${BASE_API_URL}consulta/ObtenerConsultaCedibles`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/* obtener comunas */
export const obtenerComunas = async () => {
  try {
    const response = await axios.get(`${BASE_API_URL}consolidado/obtenerComunas`);
    return response.data;
  } catch (error) {
    console.error("Error fetching obtenerComunas:", error);
    throw error;
  }
};

export const getNovofarmaPorRango = async (data) => {
  try {
    const response = await axios.post(
      `${BASE_API_URL}gestion/getNovoFarmaPorRango`,

      data
    );
    return response.data;
  } catch (error) {
    console.error("Error obteniendo datos Ordenes", error);
    throw error;
  }
};

export const ObtenerLaboratorioNovo = async (data) => {
  try {
    const response = await axios.post(
      `${BASE_API_URL}consulta/ObtenerLaboratorioNovo`,

      data
    );
    return response.data;
  } catch (error) {
    console.error("Error obteniendo datos Laboratorio desde API.js", error);
    throw error;
  }
};
