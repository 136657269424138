
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export function toastCabecera() {
  toast.error("Error en cabecera. Por favor, especificar cabecera valida." );
}

export function toastDatosEnBlanco(COLUMNA, FILA) {
  toast.error(
    `Error en archivo. En la columna ${COLUMNA}, la fila ${FILA} esta vacía` );
}

export function certificacioneNoCreada() {
  toast.error(
    "Error de creacion certificaciones. Verificar datos para la creacion de retiros" );
}

export function certificacioneCreadas() {
  toast.success(
    "Certificaciones creadas correctamente. 👌" );
}

