import axios from "axios";
/* Usa la variable de entorno para la URL base */
const BASE_API_URL = process.env.REACT_APP_BACKEND_URL;

/* iniciar sesion */
export const iniciarSesion = async (email, password) => {
  try {
    const response = await axios.post(`${BASE_API_URL}login/iniciarSesion`, {
      email,
      password,
    });
    return response.data;
  } catch (error) {
    console.error("Error en iniciarSesion:", error);
    throw error;
  }
};
