import React from "react";
import { Link } from "react-router-dom";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Edit, Description, Assessment, Download } from "@mui/icons-material";
import PrintIcon from "@mui/icons-material/Print";

function MenuFacturacion({ isDrawerOpen, openSubMenu, handleClick, isVisible, hasVisibleSubmenus, location }) {
    return (
      hasVisibleSubmenus(["ordenes", "portalMovimientos", "ImpresionEtiqueta"]) && (
        <>
          <ListItemButton onClick={() => handleClick("facturacion")}>
            <ListItemIcon sx={{ color: "white" }}>
              <Description />
            </ListItemIcon>
            {isDrawerOpen && <ListItemText primary="Facturación" sx={{ color: "white" }} />}
            {isDrawerOpen &&
              (openSubMenu === "facturacion" ? (
                <ExpandLess sx={{ color: "white" }} />
              ) : (
                <ExpandMore sx={{ color: "white" }} />
              ))}
          </ListItemButton>
          <Collapse in={openSubMenu === "facturacion"} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {isVisible("ordenes") && (
                <ListItemButton
                  component={Link}
                  to="/ordenes"
                  sx={{
                    bgcolor: location.pathname === "/ordenes" ? "white" : "transparent",
                    color: location.pathname === "/ordenes" ? "#041562" : "white",
                    ".MuiListItemText-primary": {
                      fontSize: "0.775rem",
                    },
                    pl: isDrawerOpen ? 4 : 2,
                    "& .MuiListItemIcon-root": {
                      color: location.pathname === "/ordenes" ? "#041562" : "white",
                    },
                    "&:hover": {
                      bgcolor: "white",
                      ".MuiListItemIcon-root": { color: "#041562" },
                      ".MuiListItemText-primary": { color: "#041562" },
                      borderRadius: "10px",
                    },
                    borderRadius: location.pathname === "/ordenes" ? "10px" : "0px",
                    margin: location.pathname === "/ordenes" ? "8px" : "0px",
                    overflow: "hidden",
                  }}
                >
                  <ListItemIcon>
                    <Edit />
                  </ListItemIcon>
                  {isDrawerOpen && <ListItemText primary="Gestion de ordenes" />}
                </ListItemButton>
              )}
              {isVisible("portalMovimientos") && (
                <ListItemButton
                  component={Link}
                  to="/portalMovimientos"
                  sx={{
                    bgcolor: location.pathname === "/portalMovimientos" ? "white" : "transparent",
                    color: location.pathname === "/portalMovimientos" ? "#041562" : "white",
                    ".MuiListItemText-primary": {
                      fontSize: "0.775rem",
                    },
                    pl: isDrawerOpen ? 4 : 2,
                    "& .MuiListItemIcon-root": {
                      color: location.pathname === "/portalMovimientos" ? "#041562" : "white",
                    },
                    "&:hover": {
                      bgcolor: "white",
                      ".MuiListItemIcon-root": { color: "#041562" },
                      ".MuiListItemText-primary": { color: "#041562" },
                      borderRadius: "10px",
                    },
                    borderRadius: location.pathname === "/portalMovimientos" ? "10px" : "0px",
                    margin: location.pathname === "/portalMovimientos" ? "8px" : "0px",
                    overflow: "hidden",
                  }}
                >
                  <ListItemIcon>
                    <Assessment />
                  </ListItemIcon>
                  {isDrawerOpen && <ListItemText primary="Portal de movimientos" />}
                </ListItemButton>
              )}

              {isVisible("ImpresionEtiqueta") && (
                <ListItemButton
                  component={Link}
                  to="/ImpresionEtiqueta"
                  sx={{
                    bgcolor: location.pathname === "/ImpresionEtiqueta" ? "white" : "transparent",
                    color: location.pathname === "/ImpresionEtiqueta" ? "#041562" : "white",
                    ".MuiListItemText-primary": {
                      fontSize: "0.775rem",
                    },
                    pl: isDrawerOpen ? 4 : 2,
                    "& .MuiListItemIcon-root": {
                      color: location.pathname === "/ImpresionEtiqueta" ? "#041562" : "white",
                    },
                    "&:hover": {
                      bgcolor: "white",
                      ".MuiListItemIcon-root": { color: "#041562" },
                      ".MuiListItemText-primary": { color: "#041562" },
                      borderRadius: "10px",
                    },
                    borderRadius: location.pathname === "/ImpresionEtiqueta" ? "10px" : "0px",
                    margin: location.pathname === "/ImpresionEtiqueta" ? "8px" : "0px",
                    overflow: "hidden",
                  }}
                >
                  <ListItemIcon>
                    <PrintIcon />
                  </ListItemIcon>
                  {isDrawerOpen && <ListItemText primary="Impresion de etiqueta" />}
                </ListItemButton>
              )}
              {isVisible("DescargaNovofarmaRango") && (
                <ListItemButton
                  component={Link}
                  to="/DescargaNovofarmaRango"
                  sx={{
                    bgcolor: location.pathname === "/DescargaNovofarmaRango" ? "white" : "transparent",
                    color: location.pathname === "/DescargaNovofarmaRango" ? "#041562" : "white",
                    ".MuiListItemText-primary": {
                      fontSize: "0.775rem",
                    },
                    pl: isDrawerOpen ? 4 : 2,
                    "& .MuiListItemIcon-root": {
                      color: location.pathname === "/DescargaNovofarmaRango" ? "#041562" : "white",
                    },
                    "&:hover": {
                      bgcolor: "white",
                      ".MuiListItemIcon-root": { color: "#041562" },
                      ".MuiListItemText-primary": { color: "#041562" },
                      borderRadius: "10px",
                    },
                    borderRadius: location.pathname === "/DescargaNovofarmaRango" ? "10px" : "0px",
                    margin: location.pathname === "/DescargaNovofarmaRango" ? "8px" : "0px",
                    overflow: "hidden",
                  }}
                >
                  <ListItemIcon>
                    <Download />
                  </ListItemIcon>
                  {isDrawerOpen && <ListItemText primary="Consulta Novofarma" />}
                </ListItemButton>
              )}
            </List>
          </Collapse>
        </>
      )
  );
}

export default MenuFacturacion;

