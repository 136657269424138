import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";
import * as XLSX from "xlsx";
import "react-toastify/dist/ReactToastify.css";

const TableEjecutivas = ({ columns, rows, loading, title }) => {
  const downloadExcel = () => {
    const baseURL = "https://backend.cargoexteam.cl/inicio/obtenerFoto/";

    const dataToExport = rows.map((row) => {
      const filteredRow = {};
      columns.forEach((column) => {
        filteredRow[column.headerName] = row[column.field];
      });
      filteredRow["Foto URL"] = { f: `HYPERLINK("${baseURL + row["OD_PAPEL"]}", "Ver Foto")` };
      return filteredRow;
    });

    const worksheet = XLSX.utils.json_to_sheet(dataToExport, { cellDates: true, bookSST: true });

    const range = XLSX.utils.decode_range(worksheet["!ref"]);
    for (let R = range.s.r + 1; R <= range.e.r; ++R) {
      const cellRef = XLSX.utils.encode_cell({ r: R, c: columns.length });
      if (worksheet[cellRef]) {
        worksheet[cellRef].t = "s";
      }
    }

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Informacion");

    XLSX.writeFile(workbook, "Listado.xlsx");
  };

  return (
    <Box sx={{ height: 400, width: "100%" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 1 }}>
        <Typography variant="h6">{title}</Typography>
        <Button
          variant="contained"
          onClick={downloadExcel}
          sx={{ maxHeight: 40, backgroundColor: "#1A7042", color: "white" }}
        >
          Descargar Excel
        </Button>
      </Box>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        loading={loading}
        checkboxSelection={false}
        autoPageSize={true}
        disableRowSelectionOnClick
        getRowId={(row) => row.OD_PAPEL}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        sx={{
          ".MuiDataGrid-virtualScroller": {
            overflowX: "auto",
          },
          // Height adjustment to take into account the space used by the title and button
          height: 350,
        }}
      />
    </Box>
  );
};

export default TableEjecutivas;
