import React, { useEffect, useState } from "react";
import { VictoryBar, VictoryChart, VictoryAxis, VictoryTheme, VictoryLabel } from "victory";
import { useSelector } from "react-redux";
import { obtenerGraficoEjecutiva } from "../../services/InicioServices";
import { Grid, Paper, Typography, useTheme, useMediaQuery, Box, List, ListItem, ListItemText } from "@mui/material";
import { Avatar, Modal, IconButton } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Link } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import TableHome from "../../components/DespachoComponentes/componentesHome/tableHome";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ReportIcon from "@mui/icons-material/Report";
import { keyframes } from "@emotion/react";
import UserProfileModal from "../../utils/UserProfileModal";

const blink = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
`;

const statusStyles = (isActive, color, isPendiente = false) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: 2,
  cursor: "pointer",
  padding: "12px",
  borderRadius: "8px",
  border: isActive ? `2px solid ${color}` : `2px solid ${isPendiente ? "#b20000" : "transparent"}`,
  bgcolor: isActive ? `${color}22` : isPendiente ? "#FC6E6E" : "rgba(0,0,0,0.04)",
  transition: "all 0.3s",
  "&:hover": {
    bgcolor: `${color}44`,
  },
  width: "100%",
  animation: isPendiente && !isActive ? `${blink} 2s linear infinite` : "none",
});

const InicioSac = () => {
  const userInfo = useSelector((state) => state.user.userInfo);
  const idUsuario = userInfo ? userInfo.id : null;
  const [dataRetiro, setDataRetiro] = useState([]);
  const [dataEntrega, setDataEntrega] = useState([]);
  const [totalRetiros, setTotalRetiros] = useState(0);
  const [totalEntregas, setTotalEntregas] = useState(0);
  const [dataClientesRetiros, setDataClientesRetiros] = useState([]);
  const [dataClientesEntregas, setDataClientesEntregas] = useState([]);
  const [totalEnTransitoSinGestion, setTotalEnTransitoSinGestion] = useState(0);
  const [totalCerrados, setTotalCerrados] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [showLabels, setShowLabels] = useState(false);
  const [detallesPorCliente, setDetallesPorCliente] = useState([]);
  const [detallesPorAgente, setDetallesPorAgente] = useState([]);
  const [mostrarGrafico, setMostrarGrafico] = useState("");
  const [detallesPorClienteConGestion, setDetallesPorClienteConGestion] = useState([]);
  const [detallesPorAgenteConGestion, setDetallesPorAgenteConGestion] = useState([]);
  const [totalEnTransitoConGestion, setTotalEnTransitoConGestion] = useState(0);
  const [detallesPorClienteCerrado, setDetallesCerradosPorCliente] = useState([]);
  const [detallesPorAgenteCerrado, setDetallesCerradosPorAgente] = useState([]);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState(null);

  const agentDetailsColumns = [
    { field: "CLIENTE", headerName: "Cliente", width: 200 },
    { field: "OD_PAPEL", headerName: "OD", width: 100 },
    { field: "FH_DIGITACION", headerName: "Fecha Digitacion", width: 160 },
    { field: "NOMBRE", headerName: "Nombre", width: 150 },
    { field: "DIRECCION", headerName: "Direccion", width: 200 },
    { field: "BULTOS", headerName: "Bultos", width: 130 },
    { field: "COMUNA_ORIGEN_NOMBRE", headerName: "Comuna Origen", width: 130 },
    { field: "COMUNA_ORIGEN", headerName: "Iata Origen", width: 130 },
    { field: "COMUNA_DESTINO_NOMBRE", headerName: "Comuna Destino", width: 130 },
    { field: "COMUNA", headerName: "Iata Destino", width: 130 },
    { field: "TIPO_ORDEN", headerName: "Tipo De Orden", width: 130 },
    { field: "DESCRIPCION_EVENTO", headerName: "Evento", width: 130 },
  ];

  let datosClientesActual = [];
  let datosAgentesActual = [];
  if (mostrarGrafico === "enTransitoSinGestion") {
    datosClientesActual = detallesPorCliente;
    datosAgentesActual = detallesPorAgente;
  } else if (mostrarGrafico === "enTransitoConGestion") {
    datosClientesActual = detallesPorClienteConGestion;
    datosAgentesActual = detallesPorAgenteConGestion;
  } else if (mostrarGrafico === "cerrados") {
    datosClientesActual = detallesPorClienteCerrado;
    datosAgentesActual = detallesPorAgenteCerrado;
  }

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleOpenInfoModal = (agent) => {
    setSelectedAgent(agent);
    setOpenInfoModal(true);
  };

  const handleCloseInfoModal = () => {
    setOpenInfoModal(false);
  };

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLabels(true);
    }, 3000);
    return () => clearTimeout(timer);
  }, [dataRetiro, dataEntrega]);

  useEffect(() => {
    /* Definimos una función que carga los datos */
    const cargarDatos = () => {
      if (idUsuario) {
        obtenerGraficoEjecutiva(idUsuario)
          .then((data) => {
            let totalEnTransitoSinGestion = 0;
            let totalEnTransitoConGestion = 0;
            let cerrados = 0;
            let detallesPorClienteTemp = {};
            let detallesPorAgenteTemp = {};
            let detallesPorClienteConGestionTemp = {};
            let detallesPorAgenteConGestionTemp = {};
            let detallesCerradosPorCliente = {};
            let detallesCerradosPorAgente = {};

            data.forEach((item) => {
              detallesPorClienteTemp[item.CLIENTE] = detallesPorClienteTemp[item.CLIENTE] || [];
              detallesPorAgenteTemp[item.AGENTE_ASIGNADO] = detallesPorAgenteTemp[item.AGENTE_ASIGNADO] || [];

              if (item.ENTREGA === null && item.EVENTO === null) {
                totalEnTransitoSinGestion++;
                detallesPorClienteTemp[item.CLIENTE].push(item);
                detallesPorAgenteTemp[item.AGENTE_ASIGNADO].push(item);
              } else if (item.ENTREGA === null && item.EVENTO !== null) {
                totalEnTransitoConGestion++;
                detallesPorClienteConGestionTemp[item.CLIENTE] = detallesPorClienteConGestionTemp[item.CLIENTE] || [];
                detallesPorClienteConGestionTemp[item.CLIENTE].push(item);
                detallesPorAgenteConGestionTemp[item.AGENTE_ASIGNADO] =
                  detallesPorAgenteConGestionTemp[item.AGENTE_ASIGNADO] || [];
                detallesPorAgenteConGestionTemp[item.AGENTE_ASIGNADO].push(item);
              } else if (item.ENTREGA !== null && item.EVENTO === null) {
                cerrados++;
                detallesCerradosPorCliente[item.CLIENTE] = detallesCerradosPorCliente[item.CLIENTE] || [];
                detallesCerradosPorCliente[item.CLIENTE].push(item);
                detallesCerradosPorAgente[item.AGENTE_ASIGNADO] = detallesCerradosPorAgente[item.AGENTE_ASIGNADO] || [];
                detallesCerradosPorAgente[item.AGENTE_ASIGNADO].push(item);
              }
            });

            const mapDetails = (details) =>
              Object.entries(details).map(([key, value]) => ({
                x: key,
                y: value.length,
                detalles: value,
              }));

            setTotalEnTransitoSinGestion(totalEnTransitoSinGestion);
            setTotalEnTransitoConGestion(totalEnTransitoConGestion);
            setTotalCerrados(cerrados);
            setDetallesPorCliente(mapDetails(detallesPorClienteTemp));
            setDetallesPorAgente(mapDetails(detallesPorAgenteTemp));
            setDetallesPorClienteConGestion(mapDetails(detallesPorClienteConGestionTemp));
            setDetallesPorAgenteConGestion(mapDetails(detallesPorAgenteConGestionTemp));
            setDetallesCerradosPorCliente(mapDetails(detallesCerradosPorCliente));
            setDetallesCerradosPorAgente(mapDetails(detallesCerradosPorAgente));
            processData(data);
          })
          .catch((error) => {
            console.error("Error al obtener datos del gráfico:", error);
          });
      }
    };
    /* Llamamos a la función inmediatamente para cargar datos cuando el componente se monta */
    cargarDatos();
    /* Configuramos el intervalo para actualizar los datos cada 2 minutos */
    const intervalId = setInterval(cargarDatos, 120000); /* 120000 ms = 2 minutos */
    /* Limpieza: limpiamos el intervalo cuando el componente se desmonte */
    return () => clearInterval(intervalId);
  }, [idUsuario]);

  const processData = (data) => {
    const formatData = (tipoOrden) =>
      data
        .filter((item) => item.TIPO_ORDEN === tipoOrden)
        .reduce((acc, curr) => {
          const date = new Date(curr.FH_DIGITACION);
          const fecha = date.toLocaleDateString("es-CL", { day: "2-digit", month: "2-digit" });
          acc[fecha] = (acc[fecha] || 0) + 1;
          return acc;
        }, {});

    const formatArray = (processedData) =>
      Object.keys(processedData).map((fecha) => ({
        fecha,
        cantidad: processedData[fecha],
      }));

    const retirosData = formatData("RETIRO");
    const entregasData = formatData("ENTREGA");

    setTotalRetiros(Object.values(retirosData).reduce((sum, curr) => sum + curr, 0));
    setTotalEntregas(Object.values(entregasData).reduce((sum, curr) => sum + curr, 0));

    setDataRetiro(formatArray(retirosData));
    setDataEntrega(formatArray(entregasData));

    const clientesData = data.reduce((acc, curr) => {
      if (!acc[curr.CLIENTE]) {
        acc[curr.CLIENTE] = { retiros: 0, entregas: 0 };
      }
      if (curr.TIPO_ORDEN === "RETIRO") {
        acc[curr.CLIENTE].retiros += 1;
      } else if (curr.TIPO_ORDEN === "ENTREGA") {
        acc[curr.CLIENTE].entregas += 1;
      }
      return acc;
    }, {});

    const clientesRetirosData = Object.keys(clientesData).map((cliente) => ({
      x: cliente,
      y: clientesData[cliente].retiros,
    }));
    const clientesEntregasData = Object.keys(clientesData).map((cliente) => ({
      x: cliente,
      y: clientesData[cliente].entregas,
    }));

    setDataClientesRetiros(clientesRetirosData);
    setDataClientesEntregas(clientesEntregasData);
  };

  const mostrarDetalles = (tipo) => {
    setMostrarGrafico(tipo);
  };

  const barColorsEntregas = [
    "#437cee",
    "#326cdb",
    "#225dc8",
    "#114db5",
    "#003da2",
    "#435eb1",
    "#5870c4",
    "#6d82d8",
    "#8195eb",
    "#96a7ff",
    "#8a96d7",
    "#6870ae",
    "#454b84",
    "#23255b",
    "#000031",
    "#71728f",
    "#8182a0",
    "#9293b0",
    "#a2a3c1",
    "#b2b3d2",
    "#041562",
    "#bbbbc2",
  ];

  const barColorsRetiros = [
    "#ec2c0d",
    "#f62913",
    "#911b07",
    "#c60a05",
    "#db2815",
    "#f1351c",
    "#db2815",
    "#c61a0e",
    "#b00d07",
    "#9b0000",
    "#fa1e0c",
    "#fb472d",
    "#fc714e",
    "#c42d19",
    "#fd482f",
    "#c43c24",
    "#a32d1b",
    "#821e12",
    "#620f09",
    "#410000",
    "#dc442a",
    "#f37956",
  ];

  /* Colores para los puntos */
  const colorRetiros = "#DA251C";
  const colorEntregas = "#041562";

  return (
    <Grid container spacing={1} justifyContent="center">
      <Grid item xs={12}>
        <Paper elevation={3} sx={{ p: 1, minHeight: 100, position: "relative" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography
              variant="poster"
              sx={{ fontFamily: "'Nunito', sans-serif", fontSize: "1.5rem", flexGrow: 1, textAlign: "center" }}
              gutterBottom
            >
              BIENVENIDO/A {userInfo.name}
            </Typography>
            <IconButton onClick={handleOpenModal} sx={{ flexShrink: 0 }}>
              <Avatar>
                <AccountCircleIcon style={{ color: "#041562" }} />
              </Avatar>
            </IconButton>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", p: 1 }}>
            <Typography sx={{ fontFamily: "'Nunito', sans-serif", fontSize: "1rem", textAlign: "center" }} gutterBottom>
              Si necesitas algún requerimiento de informática, puedes acceder a este{" "}
              <Link href="https://app.cargoex.cl/requerimientos/" underline="hover" target="_blank" rel="noopener">
                enlace
              </Link>{" "}
              y generar un ticket. Los datos corresponden a los últimos 31 días.
            </Typography>
          </Box>
        </Paper>
        <UserProfileModal open={openModal} handleClose={handleCloseModal} userInfo={userInfo} />
      </Grid>

      <Grid item xs={12}>
        <Paper elevation={3} sx={{ p: 1, display: "flex", justifyContent: "space-around" }}>
          <Box
            sx={statusStyles(mostrarGrafico === "enTransitoSinGestion", "#ff0000", true)}
            onClick={() => mostrarDetalles("enTransitoSinGestion")}
          >
            <ReportIcon sx={{ color: mostrarGrafico === "enTransitoSinGestion" ? "#ff0000" : "#b20000" }} />
            <Typography sx={{ fontFamily: "'Nunito', sans-serif", fontSize: "1rem", fontWeight: "bold" }}>
              Pendientes: {totalEnTransitoSinGestion}
            </Typography>
          </Box>
          <Box
            sx={statusStyles(mostrarGrafico === "enTransitoConGestion", "#0000ff")}
            onClick={() => mostrarDetalles("enTransitoConGestion")}
          >
            <AssignmentTurnedInOutlinedIcon sx={{ color: "#0000ff" }} />
            <Typography sx={{ fontFamily: "'Nunito', sans-serif", fontSize: "1rem", fontWeight: "bold" }}>
              En tránsito: {totalEnTransitoConGestion}
            </Typography>
          </Box>
          <Box sx={statusStyles(mostrarGrafico === "cerrados", "#008000")} onClick={() => mostrarDetalles("cerrados")}>
            <CheckCircleOutlineOutlinedIcon sx={{ color: "#008000" }} />
            <Typography sx={{ fontFamily: "'Nunito', sans-serif", fontSize: "1rem", fontWeight: "bold" }}>
              Cerrados: {totalCerrados}
            </Typography>
          </Box>
        </Paper>
      </Grid>

      {mostrarGrafico && (
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 1 }}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
                    {mostrarGrafico === "enTransitoSinGestion"
                      ? "Por Cliente (Sin Gestión)"
                      : mostrarGrafico === "enTransitoConGestion"
                      ? "Por Cliente (Con Gestión)"
                      : "Por Cliente (Cerrados)"}
                  </Typography>
                  <Paper style={{ maxHeight: 200, overflow: "auto" }}>
                    <TableContainer component={Paper}>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Cliente</TableCell>
                            <TableCell>Cantidad</TableCell>
                            <TableCell>Detalles</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {datosClientesActual
                            .sort((a, b) => b.y - a.y)
                            .map((row) => (
                              <TableRow key={row.x}>
                                <TableCell>{row.x}</TableCell>
                                <TableCell>{row.y}</TableCell>
                                <TableCell>
                                  <IconButton onClick={() => handleOpenInfoModal(row)}>
                                    <InfoIcon style={{ color: "#DA251C" }} />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
                    {mostrarGrafico === "enTransitoSinGestion"
                      ? "Por Agente (Sin Gestión)"
                      : mostrarGrafico === "enTransitoConGestion"
                      ? "Por Agente (Con Gestión)"
                      : "Por Agente (Cerrados)"}
                  </Typography>

                  <Paper style={{ maxHeight: 200, overflow: "auto" }}>
                    <TableContainer component={Paper}>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Agente</TableCell>
                            <TableCell>Cantidad</TableCell>
                            <TableCell>Detalles</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {datosAgentesActual
                            .sort((a, b) => b.y - a.y)
                            .map((row) => (
                              <TableRow key={row.x}>
                                <TableCell>{row.x}</TableCell>
                                <TableCell>{row.y}</TableCell>
                                <TableCell>
                                  <IconButton onClick={() => handleOpenInfoModal(row)}>
                                    <InfoIcon style={{ color: "#DA251C" }} />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>

                  <Modal
                    open={openInfoModal}
                    onClose={handleCloseInfoModal}
                    aria-labelledby="info-modal-title"
                    aria-describedby="info-modal-description"
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 800,
                        maxHeight: "90vh",
                        overflow: "auto",
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 4,
                      }}
                    >
                      <Typography id="info-modal-title" variant="h6" component="h2">
                        Información de {selectedAgent ? selectedAgent.x : ""}
                      </Typography>
                      {selectedAgent && (
                        <TableHome columns={agentDetailsColumns} rows={selectedAgent.detalles} loading={false} />
                      )}
                    </Box>
                  </Modal>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      )}

      <Grid item xs={12} md={6}>
        <Paper elevation={3} sx={{ p: 1, minHeight: 360 }}>
          <Typography sx={{ fontFamily: "'Nunito', sans-serif", fontSize: "1rem" }} align="center" gutterBottom>
            RETIROS
          </Typography>
          <VictoryChart
            domainPadding={10}
            theme={VictoryTheme.material}
            width={matches ? 300 : 500}
            padding={{ top: 20, bottom: 40, left: 50, right: 50 }}
            /*   animate={{
              duration: 2000,
              onLoad: { duration: 1000 },
            }} */
          >
            <VictoryAxis
              tickFormat={(x) => (typeof x === "string" ? `${x.split("-").reverse().join("/").substring(0, 5)}` : "")}
              style={{ tickLabels: { fontSize: matches ? 6 : 8, angle: -45, textAnchor: "end" } }}
            />
            <VictoryAxis dependentAxis tickFormat={(x) => `${x}`} />
            <VictoryBar
              barRatio={0.8}
              data={dataRetiro}
              x="fecha"
              y="cantidad"
              labels={({ datum }) => (showLabels ? `${datum.cantidad}` : "")}
              labelComponent={<VictoryLabel dy={-10} />}
              style={{
                data: { fill: ({ index }) => barColorsRetiros[index % barColorsRetiros.length] },
                labels: { fill: "black", fontSize: 8, fontWeight: "bold" },
              }}
              /*      animate={{
                onEnter: {
                  duration: 500,
                  before: () => ({ y: 0, label: " " }),
                  after: (datum) => ({ y: datum.y, label: datum.cantidad }),
                },
              }} */
            />
          </VictoryChart>

          <List sx={{ width: "100%", bgcolor: "background.paper", pt: 0 }}>
            <ListItem sx={{ py: 1 }}>
              <ListItemText
                primary={`Total de retiros: ${totalRetiros}`}
                primaryTypographyProps={{
                  sx: {
                    fontFamily: "'Nunito', sans-serif",
                    fontWeight: "bold",
                    fontSize: "0.9rem",
                  },
                }}
              />
            </ListItem>
            {dataClientesRetiros.map((cliente, index) => (
              <ListItem key={index} sx={{ py: 0 }}>
                <Box
                  sx={{
                    width: 10,
                    height: 10,
                    borderRadius: "50%",
                    bgcolor: colorRetiros,
                    marginRight: "10px",
                    display: "inline-block",
                  }}
                />
                <ListItemText
                  primary={`${cliente.x}: ${cliente.y}`}
                  sx={{ ".MuiTypography-root": { fontSize: "0.75rem" } }}
                />
              </ListItem>
            ))}
          </List>
        </Paper>
      </Grid>
      {/* graficos */}
      <Grid item xs={12} md={6}>
        <Paper elevation={3} sx={{ p: 1, minHeight: 360 }}>
          <Typography sx={{ fontFamily: "'Nunito', sans-serif", fontSize: "1rem" }} align="center" gutterBottom>
            ENTREGAS
          </Typography>

          <VictoryChart
            /*  animate={{
              duration: 3000,
              onLoad: { duration: 1000 },
            }} */
            domainPadding={10}
            theme={VictoryTheme.material}
            width={matches ? 300 : 500}
            padding={{ top: 20, bottom: 40, left: 50, right: 50 }}
          >
            <VictoryAxis
              tickFormat={(x) => (typeof x === "string" ? `${x.split("-").reverse().join("/").substring(0, 5)}` : "")}
              style={{ tickLabels: { fontSize: matches ? 6 : 8, angle: -45, textAnchor: "end" } }}
            />
            <VictoryAxis dependentAxis tickFormat={(x) => `${x}`} />
            <VictoryBar
              barRatio={0.8}
              data={dataEntrega}
              x="fecha"
              y="cantidad"
              labels={({ datum }) => (showLabels ? `${datum.cantidad}` : "")}
              labelComponent={<VictoryLabel dy={-10} />}
              style={{
                data: { fill: ({ index }) => barColorsEntregas[index % barColorsEntregas.length] },
                labels: { fill: "black", fontSize: 8, fontWeight: "bold" },
              }}
              /*    animate={{
                onEnter: {
                  duration: 500,
                  before: () => ({ y: 0, label: " " }),
                  after: (datum) => ({ y: datum.y, label: datum.cantidad }),
                },
              }} */
            />
          </VictoryChart>

          <List sx={{ width: "100%", bgcolor: "background.paper", pt: 0 }}>
            <ListItem sx={{ py: 1 }}>
              <ListItemText
                primary={`Total de entregas: ${totalEntregas}`}
                primaryTypographyProps={{
                  sx: {
                    fontFamily: "'Nunito', sans-serif",
                    fontWeight: "bold",
                    fontSize: "0.9rem",
                  },
                }}
              />
            </ListItem>
            {dataClientesEntregas.map((cliente, index) => (
              <ListItem key={index} sx={{ py: 0 }}>
                <Box
                  sx={{
                    width: 10,
                    height: 10,
                    borderRadius: "50%",
                    bgcolor: colorEntregas,
                    marginRight: "10px",
                    display: "inline-block",
                  }}
                />
                <ListItemText
                  primary={`${cliente.x}: ${cliente.y}`}
                  sx={{ ".MuiTypography-root": { fontSize: "0.75rem" } }}
                />
              </ListItem>
            ))}
          </List>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default InicioSac;

