import React, { useEffect, useState }  from "react";
import { Box} from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";
import "react-toastify/dist/ReactToastify.css";

const TablaManifiestosAgentes = ({ columns, rows, loading }) => {
  const sortedRows = [...rows].reverse();
  const [gridHeight, setGridHeight] = useState(150); // Altura mínima inicial

  useEffect(() => {
    // Calcula la nueva altura basada en la cantidad de filas
    const newHeight = Math.min(150 + sortedRows.length * 50, 550); // Ajusta estos valores según tus necesidades
    setGridHeight(newHeight);
  }, [sortedRows]);

  return (
    <div>
      <Box sx={{ width: "100%", height: `${gridHeight}px` }}>
        <DataGrid
          rows={sortedRows}
          columns={columns}
          loading={loading}
          checkboxSelection={false}
          disableColumnMenu
          disableColumnSelector
          disableRowSelectionOnClick
          disableColumnFilter
          getRowId={(row) => row.OD}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          sx={{
            "& .MuiDataGrid-virtualScroller": {
              overflowY: "auto",
            },
          }}
        />
      </Box>
    </div>
  );
};

export default TablaManifiestosAgentes;

