import axios from "axios";
/* Usa la variable de entorno para la URL base */
const BASE_API_URL = process.env.REACT_APP_BACKEND_URL;

export const getOrdenesPendientesProcesos= async (data) => {
    try {
      const response = await axios.post(`${BASE_API_URL}manifiestos/getOrdenesPendientesProcesos`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
  
      return response.data;
    } catch (error) {
      console.error("Error Insert Picking:", error);
      throw error;
    }
  };

  export const getOrdenesPendientesEntrada= async (data) => {
    try {
      const response = await axios.post(`${BASE_API_URL}manifiestos/getOrdenesPendientesEntradaSantiago`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
  
      return response.data;
    } catch (error) {
      console.error("Error Insert Picking:", error);
      throw error;
    }
  };

  export const getOrdenesPendientesRetornoClientes= async () => {
    try {
      const response = await axios.get(`${BASE_API_URL}manifiestosConsultas/getOrdenesPendientesRetornoClientes`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
  
      return response.data;
    } catch (error) {
      console.error("Error Insert Picking:", error);
      throw error;
    }
  };