import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Grid, Paper, TextField, Container } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { es } from "date-fns/locale";
import { buscarAgentes } from "../../services/ManifiestoRutaAgenteServices";
import { getCiudades } from "../../services/ManifiestosServices";

const EstadisticasGlobal = () => {
  // eslint-disable-next-line no-unused-vars
  const userInfo = useSelector((state) => state.user.userInfo);
  const [selectedOption, setSelectedOption] = useState("");
  const [fechaDesde, setFechaDesde] = useState(null);
  const [fechaHasta, setFechaHasta] = useState(null);
  const [selectedAgente, setSelectedAgente] = useState(null);
  const [agentesOption, setAgentesOption] = useState([]);
  const [selectedCiudad, setSelectedCiudad] = useState(null);
  const [ciudadesOption, setCiudadesOption] = useState([]);

  const handleChange = (event, newValue) => {
    setSelectedOption(newValue);
  };

  const handleFechaDesdeChange = (newValue) => {
    setFechaDesde(newValue);
  };

  const handleFechaHastaChange = (newValue) => {
    setFechaHasta(newValue);
  };

  const handleChangeCiudades = async (event, newValue) => {
    setSelectedCiudad(newValue);
    const dataAgentes = await buscarAgentes(newValue.value);
    const agenteOption = dataAgentes.map((agentes) => ({
      value: agentes.ID,
      label: agentes.NOMBRE_AGENTE,
    }));
    setAgentesOption(agenteOption);
  };

  const handleChangeAgentes = async (event, newValue) => {
    setSelectedAgente(newValue.label);
    console.log(newValue.label);
    try {
    } catch (error) {
      console.error("Failed to fetch choferes", error);
    }
  };

  useEffect(() => {
    const obtenerCiudades = async () => {
      const dataCiudades = await getCiudades();
      const ciudadesOption = dataCiudades.map((ciudades) => ({
        value: ciudades.IATA,
        label: ciudades.NOMBRE,
      }));
      setCiudadesOption(ciudadesOption);
    };
    obtenerCiudades();
  }, [userInfo]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
      <Container maxWidth="lg">
        <Paper style={{ padding: "10px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <DesktopDatePicker
                label="Desde"
                inputFormat="dd/MM/yyyy"
                value={fechaDesde}
                onChange={handleFechaDesdeChange}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    margin: "dense",
                    variant: "outlined",
                    size: "small",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <DesktopDatePicker
                label="Hasta"
                inputFormat="dd/MM/yyyy"
                value={fechaHasta}
                onChange={handleFechaHastaChange}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    margin: "dense",
                    variant: "outlined",
                    size: "small",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Autocomplete
                disableClearable
                value={selectedCiudad}
                disablePortal
                id="size-small-outlined-multi"
                size="small"
                options={ciudadesOption}
                onChange={handleChangeCiudades}
                sx={{ width: "100%" }}
                PaperComponent={({ children }) => (
                  <Paper
                    sx={{
                      width: "100%",
                      textAlign: "left",
                      "& li": { textAlign: "left" },
                    }}
                  >
                    {children}
                  </Paper>
                )}
                renderInput={(params) => <TextField {...params} label="DESTINO" />}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Autocomplete
                disableClearable
                value={selectedAgente}
                disablePortal
                id="size-small-outlined-multi"
                size="small"
                options={agentesOption}
                onChange={handleChangeAgentes}
                sx={{ width: "100%" }}
                PaperComponent={({ children }) => (
                  <Paper
                    sx={{
                      width: "100%",
                      textAlign: "left",
                      "& li": { textAlign: "left" },
                    }}
                  >
                    {children}
                  </Paper>
                )}
                renderInput={(params) => <TextField {...params} label="AGENTE" />}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Autocomplete
                id="combo-box-rango"
                options={["24H", "48H", "12H"]}
                getOptionLabel={(option) => option}
                isOptionEqualToValue={(option, value) => option === value}
                value={selectedOption}
                onChange={handleChange}
                renderInput={(params) => (
                  <TextField {...params} fullWidth margin="dense" label="Rango" variant="outlined" size="small" />
                )}
              />
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </LocalizationProvider>
  );
};

export default EstadisticasGlobal;
