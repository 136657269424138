import axios from "axios";
/* Usa la variable de entorno para la URL base */
const BASE_API_URL = process.env.REACT_APP_BACKEND_URL;

const apiClient = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

/* generar manifiestos */
export const generarManifiestoPDF = async (manifiestoId) => {
  try {
    const response = await apiClient.get(`/medicion/creacionManifiestoPDF/${manifiestoId}`, {
      responseType: "blob",
    });
    const pdfBlob = new Blob([response.data], { type: "application/pdf" });
    const pdfUrl = URL.createObjectURL(pdfBlob);
    return pdfUrl;
  } catch (error) {
    console.error("Hubo un error al obtener el PDF", error);
    throw error;
  }
};

export const generarManifiestoPDF_UserBarCode = async (manifestId, useBarcode) => {
  try {
    const response = await apiClient.get(
      `/medicion/creacionManifiestoPDFBarCode/${manifestId}?useBarcode=${useBarcode}`,
      {
        responseType: "blob",
      }
    );
    const pdfBlob = new Blob([response.data], { type: "application/pdf" });
    const pdfUrl = URL.createObjectURL(pdfBlob);
    return pdfUrl;
  } catch (error) {
    console.error("Hubo un error al obtener el PDF", error);
    throw error;
  }
};

//Obtener Tipo usuario por Id usuario
export const buscarChoferesManifiestoRuta = async (id) => {
  try {
    const response = await axios.post(`${BASE_API_URL}manifiestos/getChoferes`, {
      id,
    });
    if (response.data && response.data.length > 0) {
      return response.data;
    } else {
      console.log("No hay datos desde APS.JS  Chofer");
      return null;
    }
  } catch (error) {
    console.error("Error obteniendo datos Chofer desde API.js", error);
    throw error;
  }
};

export const buscarComunasChoferManifiestoRuta = async (id) => {
  try {
    const response = await axios.post(`${BASE_API_URL}manifiestos/getChoferesComunas`, {
      id,
    });
    return response.data;
  } catch (error) {
    console.error("Error obteniendo datos Usuario desde API.js", error);
    throw error;
  }
};

export const buscarDatosPickingTempManifiestoRuta = async (data) => {
  try {
    const response = await axios.post(
      `${BASE_API_URL}manifiestos/getDatosPickinTemp`,

      data
    );

    return response.data;
  } catch (error) {
    console.error("Error obteniendo datos PT desde API.js", error);
    throw error;
  }
};

export const downloadManifestPdfManifiestoRuta = async (chofer, manifestId, useCod_Barra) => {
  const url = `${BASE_API_URL}medicion/creacionManifiestoPDF/${manifestId}?useCod_Barra=${useCod_Barra}`;
  try {
    const response = await axios.get(url, {
      responseType: "blob",
    });

    const pdfBlobUrl = URL.createObjectURL(response.data);

    const downloadLink = document.createElement("a");
    downloadLink.href = pdfBlobUrl;
    downloadLink.download = `MANIFIESTO_RUTA_${chofer}_${manifestId}.pdf`;

    document.body.appendChild(downloadLink);
    downloadLink.click();

    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(pdfBlobUrl);
  } catch (error) {
    console.error("Error al descargar el PDF:", error);
    throw error;
  }
};

/* Obtener Tipo usuario por Id usuario */
export const buscarTransportistas = async () => {
  try {
    const response = await axios.get(`${BASE_API_URL}manifiestos/getTransportistas`);
    if (response.data && response.data.length > 0) {
      return response.data;
    } else {
      console.log("No hay datos desde APS.JS  Transportes");
      return null;
    }
  } catch (error) {
    console.error("Error obteniendo datos Transportistas desde API.js", error);
    throw error;
  }
};

/* buscar datos de la orden */
export const buscarDatosOrden = async (od) => {
  try {
    const response = await axios.post(`${BASE_API_URL}manifiestos/getDatosOrden`, {
      od,
    });
    if (response.data && response.data.length > 0) {
      return response.data;
    } else {
      console.log("No hay datos desde APS.JS  Ordenes");
      return null;
    }
  } catch (error) {
    console.error("Error obteniendo datos Ordenes desde API.js", error);
    throw error;
  }
};

/* insertar en picking temporal */
export const insertPickingTemp = async (data) => {
  try {
    const response = await axios.post(`${BASE_API_URL}manifiestos/getInsertPickingTemp`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error Insert PickingTemp:", error);
    throw error;
  }
};

/* insertar manifiestos */
export const insertManifiesto = async (data) => {
  try {
    const response = await axios.post(`${BASE_API_URL}manifiestos/getInsertManifiesto`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    const idManifiesto = response.data.idManifiesto;
    return idManifiesto;
  } catch (error) {
    console.error("Error Insert Manifiesto:", error);
    throw error;
  }
};

/* borrar en picking temporal */
export const deletePickingTemp = async (data) => {
  try {
    const response = await axios.post(`${BASE_API_URL}manifiestos/getDeletePickingTemp`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error delete PickingTemp:", error);
    throw error;
  }
};

/* insertar el picking */
export const insertPicking = async (data) => {
  try {
    const response = await axios.post(`${BASE_API_URL}manifiestos/getInsertPicking`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error Insert PickingTemp:", error);
    throw error;
  }
};

/* borrar picking temporal */
export const deletePickingTempMasivo = async (data) => {
  try {
    const response = await axios.post(`${BASE_API_URL}manifiestos/getDeletePickingTempMasivo`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error delete PickingTemp:", error);
    throw error;
  }
};

/* buscar datos del picking temporal */
export const buscarDatosPickingTempRetornos = async (data) => {
  try {
    const response = await axios.post(
      `${BASE_API_URL}manifiestos/getDatosPickinTempRetornos`,

      data
    );

    return response.data;
  } catch (error) {
    console.error("Error obteniendo datos PT desde API.js", error);
    throw error;
  }
};

/* buscar ciudades */
export const buscarCiudades = async () => {
  try {
    const response = await axios.get(`${BASE_API_URL}manifiestos/getCiudadesPrincipales`);
    if (response.data && response.data.length > 0) {
      return response.data;
    } else {
      console.log("No hay datos desde APS.JS ");
      return null;
    }
  } catch (error) {
    console.error("Error obteniendo datos Ciudades desde API.js", error);
    throw error;
  }
};

/* descargar pdf de manifiesto */
export const downloadManifestPdf = async (manifestId, ciudad, useCod_Barra) => {
  const url = `${BASE_API_URL}medicion/creacionManifiestoPDF/${manifestId}?useCod_Barra=${useCod_Barra}`;
  try {
    const response = await axios.get(url, {
      responseType: "blob",
    });

    // Crear una URL para el blob de datos del PDF
    const pdfBlobUrl = URL.createObjectURL(response.data);

    // Crear un enlace de descarga
    const downloadLink = document.createElement("a");
    downloadLink.href = pdfBlobUrl;
    downloadLink.download = `MANIFIESTO_SALIDA_ANDEN_${manifestId}_${ciudad}.pdf`; // Nombre del archivo de descarga

    // Simular un clic en el enlace de descarga para iniciar la descarga
    document.body.appendChild(downloadLink);
    downloadLink.click();

    // Limpiar después de la descarga
    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(pdfBlobUrl);
  } catch (error) {
    console.error("Error al descargar el PDF:", error);
    throw error;
  }
};

export const downloadManifestPdfEntrada = async (manifestId, ciudad) => {
  const url = `${BASE_API_URL}medicion/creacionManifiestoPDF/${manifestId}`;
  try {
    const response = await axios.get(url, {
      responseType: "blob",
    });

    // Crear una URL para el blob de datos del PDF
    const pdfBlobUrl = URL.createObjectURL(response.data);

    // Crear un enlace de descarga
    const downloadLink = document.createElement("a");
    downloadLink.href = pdfBlobUrl;
    downloadLink.download = `MANIFIESTO_ENTRADA_SANTIAGO_${manifestId}_${ciudad}.pdf`; // Nombre del archivo de descarga

    // Simular un clic en el enlace de descarga para iniciar la descarga
    document.body.appendChild(downloadLink);
    downloadLink.click();

    // Limpiar después de la descarga
    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(pdfBlobUrl);
  } catch (error) {
    console.error("Error al descargar el PDF:", error);
    throw error;
  }
};

export const buscarDatosPickingTempInhouse = async (data) => {
  try {
    const response = await axios.post(
      `${BASE_API_URL}manifiestos/getDatosPickinTempInhouse`,

      data
    );

    return response.data;
  } catch (error) {
    console.error("Error obteniendo datos PT desde API.js", error);
    throw error;
  }
};

export const downloadManifestPdfInhouse = async (manifestId, useCod_Barra) => {
  const url = `${BASE_API_URL}medicion/creacionManifiestoPDF/${manifestId}?useCod_Barra=${useCod_Barra}`;
  try {
    const response = await axios.get(url, {
      responseType: "blob",
    });

    const pdfBlobUrl = URL.createObjectURL(response.data);

    const downloadLink = document.createElement("a");
    downloadLink.href = pdfBlobUrl;
    downloadLink.download = `manifiesto_${manifestId}.pdf`;

    document.body.appendChild(downloadLink);
    downloadLink.click();

    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(pdfBlobUrl);
  } catch (error) {
    console.error("Error al descargar el PDF:", error);
    throw error;
  }
};

//Obtener Tipo usuario por Id usuario
export const buscarChoferesInhouse = async (id) => {
  try {
    const response = await axios.get(`${BASE_API_URL}manifiestos/getChoferesStgo`, {
      id,
    });
    if (response.data && response.data.length > 0) {
      return response.data;
    } else {
      console.log("No hay datos desde APS.JS  Chofer");
      return null;
    }
  } catch (error) {
    console.error("Error obteniendo datos Chofer desde API.js", error);
    throw error;
  }
};

export const buscarDatosPickingTempManifiestoEntradaAgente = async (data) => {
  try {
    const response = await axios.post(
      `${BASE_API_URL}manifiestos/getDatosPickinTempEntradaAgentes`,

      data
    );

    return response.data;
  } catch (error) {
    console.error("Error obteniendo datos PT desde API.js", error);
    throw error;
  }
};

export const downloadManifestPdfManifiestoEntradaAgente = async (ciudadUser, manifestId, useCod_Barra) => {
  const url = `${BASE_API_URL}medicion/creacionManifiestoPDF/${manifestId}?useCod_Barra=${useCod_Barra}`;
  try {
    const response = await axios.get(url, {
      responseType: "blob",
    });

    const pdfBlobUrl = URL.createObjectURL(response.data);

    const downloadLink = document.createElement("a");
    downloadLink.href = pdfBlobUrl;
    downloadLink.download = `MANIFIESTO_ENTRADA_AGENTE_${ciudadUser}_${manifestId}.pdf`;

    document.body.appendChild(downloadLink);
    downloadLink.click();

    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(pdfBlobUrl);
  } catch (error) {
    console.error("Error al descargar el PDF:", error);
    throw error;
  }
};

export const buscarManifiestosEntAgentesManifiestoEntradaAgente = async (AGENTE_ASIGNADO) => {
  try {
    const response = await axios.post(`${BASE_API_URL}manifiestos/getDatosManifEntAgentes`, {
      AGENTE_ASIGNADO,
    });

    return response.data;
  } catch (error) {
    console.error("Error obteniendo datos Manifiestos desde API.js", error);
    throw error;
  }
};

export const buscarManifiestosEntAgentesManifiestoEntradaAgenteRef = async (AGENTE_ASIGNADO, COD_CLIENTE) => {
  try {
    const response = await axios.post(`${BASE_API_URL}manifiestos/getDatosManifEntAgentesRef`, {
      AGENTE_ASIGNADO,
      COD_CLIENTE,
    });

    return response.data;
  } catch (error) {
    console.error("Error obteniendo datos Manifiestos desde API.js", error);
    throw error;
  }
};

/*  */

export const getCiudades = async () => {
  try {
    const response = await axios.get(`${BASE_API_URL}manifiestos/getCiudadesPrincipales`);
    return response.data;
  } catch (error) {
    console.error("Error obteniendo datos Usuario desde API.js", error);
    throw error;
  }
};

/*  */

export const buscarDatosPickingTemp = async (data) => {
  try {
    const response = await axios.post(
      `${BASE_API_URL}manifiestos/getDatosPickinTempSalida`,

      data
    );
    return response.data;
  } catch (error) {
    //console.error("Error obteniendo datos PT desde API.js", error);
    throw error;
  }
};

export const downloadManifestoSalidaPdf = async (manifestId, ciudadNombre) => {
  const url = `${BASE_API_URL}medicion/creacionManifiestoPDF/${manifestId}`;
  try {
    const response = await axios.get(url, {
      responseType: "blob",
    });

    const pdfBlobUrl = URL.createObjectURL(response.data);

    const downloadLink = document.createElement("a");
    downloadLink.href = pdfBlobUrl;
    downloadLink.download = `MANIFIESTO_SALIDA_ANDEN_${manifestId}_${ciudadNombre}.pdf`;

    document.body.appendChild(downloadLink);
    downloadLink.click();

    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(pdfBlobUrl);
  } catch (error) {
    console.error("Error al descargar el PDF:", error);
    throw error;
  }
};

export const downloadManifestoRetornosPdf = async (manifestId, ciudadNombre, useCod_Barra) => {
  const url = `${BASE_API_URL}medicion/creacionManifiestoPDF/${manifestId}?useCod_Barra=${useCod_Barra}`;
  try {
    const response = await axios.get(url, {
      responseType: "blob",
    });

    const pdfBlobUrl = URL.createObjectURL(response.data);

    const downloadLink = document.createElement("a");
    downloadLink.href = pdfBlobUrl;
    downloadLink.download = `MANIFIESTO_RETORNO_AGENTE_${ciudadNombre}_${manifestId}.pdf`;

    document.body.appendChild(downloadLink);
    downloadLink.click();

    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(pdfBlobUrl);
  } catch (error) {
    console.error("Error al descargar el PDF:", error);
    throw error;
  }
};

export const buscarPendientesSalida = async (IATA_PADRE) => {
  try {
    const response = await axios.post(`${BASE_API_URL}manifiestos/getPendientesSalida`, {
      IATA_PADRE,
    });

    return response.data;
  } catch (error) {
    console.error("Error obteniendo datos Manifiestos desde API.js", error);
    throw error;
  }
};

export const buscarPendientesSalidaRef = async (IATA_PADRE, COD_CLIENTE) => {
  try {
    const response = await axios.post(`${BASE_API_URL}manifiestos/getPendientesSalidaRef`, {
      IATA_PADRE,
      COD_CLIENTE,
    });

    return response.data;
  } catch (error) {
    console.error("Error obteniendo datos Manifiestos desde API.js", error);
    throw error;
  }
};

export const getPassword = async () => {
  try {
    const response = await axios.get(`${BASE_API_URL}manifiestos/getPassword`);
    return response.data;
  } catch (error) {
    console.error("Error obteniendo datos PasswordUsuario desde API.js", error);
    throw error;
  }
};

export const getUser = async (name) => {
  try {
    const response = await axios.post(`${BASE_API_URL}manifiestos/getUser`, {
      name,
    });

    return response.data;
  } catch (error) {
    console.error("Error obteniendo datos User desde API.js", error);
    throw error;
  }
};

export const getAgentes = async (IATA_PADRE) => {
  try {
    const response = await axios.post(`${BASE_API_URL}manifiestos/getAgentesxCiudad`, {
      IATA_PADRE,
    });
    return response.data;
  } catch (error) {
    console.error("Error obteniendo datos User desde API.js", error);
    throw error;
  }
};

export const insertMailGestor = async (data) => {
  try {
    const response = await axios.post(`${BASE_API_URL}manifiestos/getInsertMailGestor`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error Insert MailGestor:", error);
    throw error;
  }
};

/*  */

export const buscarManifiestosxId = async (ids) => {
  try {
    const response = await axios.post(`${BASE_API_URL}manifiestos/getDatosManifiestoxID`, {
      ids,
    });

    return response.data;
  } catch (error) {
    //console.error("Error obteniendo datos Manifiestos desde API.js", error);
    throw error;
  }
};

export const buscarManifiestosxOd = async (od) => {
  try {
    const response = await axios.post(`${BASE_API_URL}manifiestos/getDatosManifiestoxOD`, {
      od,
    });

    return response.data;
  } catch (error) {
    console.error("Error obteniendo datos Manifiestos desde API.js", error);
    throw error;
  }
};

export const buscarManifiestosxOdRef = async (COD_BARRA, ID_CLIENTE) => {
  try {
    const response = await axios.post(`${BASE_API_URL}manifiestos/getDatosManifiestoxODRef`, {
      COD_BARRA,
      ID_CLIENTE,
    });

    return response.data;
  } catch (error) {
    console.error("Error obteniendo datos Manifiestos desde API.js", error);
    throw error;
  }
};

export const DatosPendientesEntradaSantiago = async (CIUDAD) => {
  try {
    const response = await axios.post(`${BASE_API_URL}manifiestos/getDatosPendientesEntradaSantiago`, {
      CIUDAD,
    });

    return response.data;
  } catch (error) {
    console.error("Error obteniendo datos Manifiestos desde API.js", error);
    throw error;
  }
};

export const getOrdenesMedidas = async (data) => {
  try {
    const response = await axios.post(`${BASE_API_URL}manifiestos/getOrdenesMedidas`,data);
    return response.data;
  } catch (error) {
    console.error("Error obteniendo datos Ordenes desde API.js", error);
    throw error;
  }
};

export const getManifiestosProceso = async (data) => {
  try {
    const response = await axios.post(
      `${BASE_API_URL}manifiestos/getManifiestosProceso`,

      data
    );
    return response.data;
  } catch (error) {
    console.error("Error obteniendo datos Manifiestos", error);
    throw error;
  }
};

export const getManifiestosPorRango = async (data) => {
  try {
    const response = await axios.post(
      `${BASE_API_URL}manifiestosConsultas/getManifiestosPorRango`,

      data
    );
    return response.data;
  } catch (error) {
    console.error("Error obteniendo datos Manifiestos", error);
    throw error;
  }
};

export const editarBultos = async (data) => {
  try {
    const response = await axios.post(
      `${BASE_API_URL}manifiestos/updateBultosOrden`,

      data
    );
    return response.data;
  } catch (error) {
    console.error("Error obteniendo datos PT desde API.js", error);
    throw error;
  }
};

/* */

//Manifiestos Por Cliente getOrdenIdReferencia
export const getOrdenIdReferencia = async (data) => {
  try {
    const response = await axios.post(
      `${BASE_API_URL}manifiestos/getOrdenIdReferencia`,

      data
    );
    return response.data;
  } catch (error) {
    console.error("Error obteniendo datos PT desde API.js", error);
    throw error;
  }
};

export const getClientes = async () => {
  try {
    const response = await axios.get(`${BASE_API_URL}manifiestos/getClienteRef`);
    return response.data;
  } catch (error) {
    console.error("Error obteniendo Clientes desde API.js", error);
    throw error;
  }
};

export const downloadManifestPdfManifiestoEntrada = async (chofer, manifestId, useCod_Barra) => {
  const url = `${BASE_API_URL}medicion/creacionManifiestoPDF/${manifestId}?useCod_Barra=${useCod_Barra}`;
  try {
    const response = await axios.get(url, {
      responseType: "blob",
    });

    const pdfBlobUrl = URL.createObjectURL(response.data);

    const downloadLink = document.createElement("a");
    downloadLink.href = pdfBlobUrl;
    downloadLink.download = `MANIFIESTO_RUTA_${chofer}_${manifestId}.pdf`;

    document.body.appendChild(downloadLink);
    downloadLink.click();

    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(pdfBlobUrl);
  } catch (error) {
    console.error("Error al descargar el PDF:", error);
    throw error;
  }
};

export const downloadManifestPdfBarCode = async (chofer, manifestId, useBarcode) => {
  const url = `${BASE_API_URL}medicion/creacionManifiestoPDFBarCode/${manifestId}?useBarcode=${useBarcode}`;
  try {
    const response = await axios.get(url, {
      responseType: "blob",
    });

    const pdfBlobUrl = URL.createObjectURL(response.data);

    const downloadLink = document.createElement("a");
    downloadLink.href = pdfBlobUrl;
    downloadLink.download = `MANIFIESTO_RUTA${chofer}_${manifestId}.pdf`;

    document.body.appendChild(downloadLink);
    downloadLink.click();

    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(pdfBlobUrl);
  } catch (error) {
    console.error("Error al descargar el PDF:", error);
    throw error;
  }
};

export const getOrdenesManifiestosRutaRetornoCliente = async (data) => {
  try {
    const response = await axios.post(
      `${BASE_API_URL}manifiestos/getOrdenesManifiestosRutaRetornoCliente`,

      data
    );
    return response.data;
  } catch (error) {
    console.error("Error obteniendo datos Ordenes desde API.js", error);
    throw error;
  }
};

export const getManifiesto = async (data) => {
  try {
    const response = await axios.post(
      `${BASE_API_URL}manifiestos/getManifiestoGestor`,

      data
    );
    return response.data;
  } catch (error) {
    console.error("Error obteniendo datos Ordenes desde API.js", error);
    throw error;
  }
};

export const updateChoferManifiestoRuta = async (data) => {
  try {
    const response = await axios.put(
      `${BASE_API_URL}manifiestos/updateChoferManifiestoRuta`,

      data
    );
    return response.data;
  } catch (error) {
    console.error("Error obteniendo datos Ordenes desde API.js", error);
    throw error;
  }
};

export const deletePicking = async (data) => {
  try {
    const response = await axios.delete(`${BASE_API_URL}manifiestos/deletePicking`, {
      data: data, // Asegúrate de pasar los datos aquí
    });
    return response.data;
  } catch (error) {
    console.error("Error de Back", error);
    throw error;
  }
};

export const deleteManifiesto = async (data) => {
  try {
    const response = await axios.delete(`${BASE_API_URL}manifiestos/deleteManifiesto`, {
      data: data, // Asegúrate de pasar los datos aquí
    });
    return response.data;
  } catch (error) {
    console.error("Error de Back", error);
    throw error;
  }
};

export const getPendientesRetornoClientes = async (COD_CLIENTE) => {
  try {
    const response = await axios.post(`${BASE_API_URL}manifiestosConsultas/getPendientesRetornoClientes`, {
      COD_CLIENTE,
    });

    return response.data;
  } catch (error) {
    console.error("Error obteniendo datos Manifiestos desde API.js", error);
    throw error;
  }
};

export const downloadManifestoRetornoClientes = async (chofer, manifestId, useCod_Barra) => {
  const url = `${BASE_API_URL}medicion/creacionManifiestoPDF/${manifestId}?useCod_Barra=${useCod_Barra}`;
  try {
    const response = await axios.get(url, {
      responseType: "blob",
    });

    const pdfBlobUrl = URL.createObjectURL(response.data);

    const downloadLink = document.createElement("a");
    downloadLink.href = pdfBlobUrl;
    downloadLink.download = `MANIFIESTO_RETORNO_CLLIENTE_${chofer}_${manifestId}.pdf`;

    document.body.appendChild(downloadLink);
    downloadLink.click();

    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(pdfBlobUrl);
  } catch (error) {
    console.error("Error al descargar el PDF:", error);
    throw error;
  }
};

export const getAgentesAll = async () => {
  try {
    const response = await axios.get(`${BASE_API_URL}manifiestosConsultas/getAgentes`);
    return response.data;
  } catch (error) {
    console.error("Error obteniendo datos Agentes desde API.js", error);
    throw error;
  }
};

export const getUpdateOrdenAgente = async (datos) => {
  try {
    // Enviar los datos envueltos en la propiedad `datos`
    const response = await axios.post(`${BASE_API_URL}manifiestosConsultas/getUpdateOrdenAgente`, { datos });

    return response.data;
  } catch (error) {
    console.error("Error obteniendo datos Manifiestos desde API.js:", error.message || error);
    throw error;
  }
};