import React from "react";
import { Container, Typography } from "@mui/material";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList } from "recharts";

const AgentChartSecondHalf = ({ data, totalAgents }) => {
  return (
    <Container maxWidth="lg" style={{ height: "80vh", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
      <Typography variant="h6" gutterBottom>
        Desempeño de Agentes (Segunda Mitad) - Total Agentes: {totalAgents}
      </Typography>
      <ResponsiveContainer width="100%" height="80%">
        <BarChart data={data} barGap={10} barCategoryGap="10%">
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" interval={0} angle={-45} textAnchor="end" height={130} />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="En Transito" fill="#DA251C">
            <LabelList dataKey="En Transito" position="top" />
          </Bar>
          <Bar dataKey="En Tránsito con evento" fill="#041562">
            <LabelList dataKey="En Tránsito con evento" position="top" />
          </Bar>
          <Bar dataKey="Entregadas" fill="#4fa662">
            <LabelList dataKey="Entregadas" position="top" />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Container>
  );
};

export default AgentChartSecondHalf;
