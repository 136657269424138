export const camposChoferes = [
  { nombre: "NOMBRE", etiqueta: "Nombre" },
  { nombre: "RUT", etiqueta: "Rut" },
  { nombre: "PASSWORD", etiqueta: "Password" },
  { nombre: "PASSWORD_HASH", etiqueta: "Password hash" },
  { nombre: "COD_CHOFER", etiqueta: "Codigo de chofer" },
  { nombre: "CIUDAD", etiqueta: "Ciudad" },
  { nombre: "AGENTE_ASIGNADO", etiqueta: "Agente asignado" },
  { nombre: "TELEFONO", etiqueta: "Teléfono" },
  { nombre: "MAIL", etiqueta: "Correo" },
  { nombre: "ESTADO", etiqueta: "Estado" },
  { nombre: "TRANSPORTISTA", etiqueta: "Transportista" },
  { nombre: "PERMITE_APP", etiqueta: "Permite resto de app?" },
  { nombre: "PERMITE_RETIROS", etiqueta: "Permite retiros?" },
  { nombre: "PERMITE_IMPRESORA", etiqueta: "Permite impresora?" },
  { nombre: "PERMITE_ENTREGAS", etiqueta: "Permite entregas?" },
  { nombre: "PERMITE_INSPECCION", etiqueta: "Permite inspeccion?" },
  { nombre: "PERMITE_RETORNOS", etiqueta: "Permite retornos?" },
  { nombre: "TOKEN_SESION", etiqueta: "Token aplicativo" },
  { nombre: "FH_SYS", etiqueta: "Fecha aplicativo" },
];

export const camposUsuarios = [
  { nombre: "name", etiqueta: "Nombre usuario" },
  { nombre: "email", etiqueta: "Correo" },
  { nombre: "tipo_usuario", etiqueta: "Tipo de usuario" },
  { nombre: "cod_cliente", etiqueta: "Cliente" },
  { nombre: "password_palabras", etiqueta: "Password" },
  { nombre: "password", etiqueta: "Password hash" },
  { nombre: "token", etiqueta: "Token" },
  { nombre: "canal", etiqueta: "Canal" },
  { nombre: "iata_sucursal", etiqueta: "Iata sucursal" },
  { nombre: "iata_agentes", etiqueta: "Iata agentes" },
  { nombre: "ESTADO", etiqueta: "Estado" },
  { nombre: "permite_rebaja_manual", etiqueta: "Permite rebaja manual?" },
  { nombre: "permite_rebaja_masiva", etiqueta: "Permite rebaja masiva?" },
  {
    nombre: "permite_modificacion_orden",
    etiqueta: "Permite modificacion orden?",
  },
];

