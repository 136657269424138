import React, { useState, useEffect } from "react";
import { Container, LinearProgress, Box, Typography } from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import SeguimientoEjecutivas from "./SeguimientoEjecutiva";
import { obtenerGraficosEjecutivaControl } from "../../services/reportabilidadEspecialService";

const ControlSupervisora = () => {
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [datos, setDatos] = useState([]);

  useEffect(() => {
    const cargarDatos = async () => {
      if (!isDataLoaded) {
        let interval = setInterval(() => {
          setLoadingProgress((oldProgress) => {
            if (oldProgress >= 80) {
              clearInterval(interval);
              return oldProgress;
            }
            const diff = Math.random() * 10;
            return Math.min(oldProgress + diff, 80);
          });
        }, 500);
      }

      try {
        const data = await obtenerGraficosEjecutivaControl();
        setDatos(data);
        console.log(data);
        if (!isDataLoaded) {
          setLoadingProgress(100);
          setIsDataLoaded(true);
        }
      } catch (error) {
        console.error("Error al cargar los datos:", error);
        setLoadingProgress(0);
      }
    };

    cargarDatos();
    const intervalId = setInterval(cargarDatos, 60000); // 60000 ms = 1 minuto
    return () => clearInterval(intervalId);
  }, [isDataLoaded]);

  return (
    <Container
      maxWidth="lg"
      sx={{
        height: { xs: "100vh", sm: "80vh", md: "75vh" },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={{ width: "100%", height: "100%" }}>
        {!isDataLoaded && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <LinearProgress
              variant="determinate"
              value={loadingProgress}
              sx={{ width: "50%", height: 10, borderRadius: 5 }}
            />
            <Typography variant="body2" sx={{ mt: 1 }}>
              Cargando... {loadingProgress.toFixed(0)}%
            </Typography>
          </Box>
        )}
        {isDataLoaded && (
          <div>
            <SeguimientoEjecutivas datos={datos} />
          </div>
        )}
      </Box>
    </Container>
  );
};

export default ControlSupervisora;
