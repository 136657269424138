import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { TextField } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function ModalComponent({ open, handleClose, handleUpdate }) {
  const [bultos, setBultos] = useState("");

  const handleChange = (event) => {
    //setBultos(event.target.value);
    const text = event.target.value
    // Verifica si el valor ingresado contiene solo números
    if (/^\d*$/.test(text) || text === "") {
        setBultos(text);
  };
}

  const handleUpdateClick = () => {
    handleUpdate(bultos); // Llama a la función handleUpdate del componente principal y pasa el valor de bultos
    handleClose(); 
    setBultos("");// Cierra la modal después de actualizar
  };

  const handleCloseModal = () => {
    handleClose(); // Cierra la modal
    setBultos(""); // Limpia el campo de texto cuando se cierra la modal sin actualizar
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Ingrese Bultos
        </Typography>
        <TextField
          size="small"
          id="outlined-size-small"
          label="Bultos"
          value={bultos}
          onChange={handleChange}
          sx={{ width: "100%", marginTop: "10px" }}
        />

        <Button
          onClick={handleUpdateClick}
          variant="contained"
          size="small"
          sx={{
            width: "70%",
            height: "100%",
            backgroundColor: "#041562",
            marginTop: "10px",
          }}
        >
          ACTUALIZAR
        </Button>
      </Box>
    </Modal>
  );
}
