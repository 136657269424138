import axios from "axios";
/* Usa la variable de entorno para la URL base */
const BASE_API_URL = process.env.REACT_APP_BACKEND_URL;

/* obtenemos agentes */
export const obtenerEstados = async () => {
  try {
    const response = await axios.get(`${BASE_API_URL}clientes/obtenerEstados`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/* obtenemos agentes */
export const obtenerAgentes = async () => {
  try {
    const response = await axios.get(`${BASE_API_URL}clientes/obtenerAgentes`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/* actualizar clientes */
export const actualizarCliente = async (id, clienteData) => {
  try {
    const response = await axios.put(`${BASE_API_URL}clientes/actualizarCliente/${id}`, clienteData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/* crear clientes */
export const crearCliente = async (clienteData) => {
  try {
    const response = await axios.post(`${BASE_API_URL}clientes/crearCliente`, clienteData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/* eliminar clientes */
export const eliminarCliente = async (id) => {
  try {
    const response = await axios.delete(`${BASE_API_URL}clientes/eliminarCliente/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
