import React, { useState, useMemo } from "react";
import { Box, TextField } from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";
import "react-toastify/dist/ReactToastify.css";
const TableRendicionesPeri = ({ columns, rows, loading }) => {
  const [searchText, setSearchText] = useState("");

  const filteredRows = useMemo(() => {
    const searchLowercase = searchText.toLowerCase();
    return rows.filter((row) => {
      const nombre = row.NOMBRE ? row.NOMBRE.toLowerCase().includes(searchLowercase) : false;
      return nombre;
    });
  }, [searchText, rows]);

  return (
    <Box sx={{ height: 400, width: "100%" }}>
      <Box sx={{ display: "flex", gap: 1, alignItems: "center", mb: 2 }}>
        <TextField
          size="small"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          variant="outlined"
          placeholder="Buscar por nombre..."
          sx={{ flexGrow: 1 }}
        />
      </Box>
      <DataGrid
        rows={filteredRows}
        columns={columns.map((column) => ({
          ...column,
        }))}
        pageSize={5}
        loading={loading}
        checkboxSelection={false}
        autoPageSize={true}
        disableRowSelectionOnClick
        getRowId={(row) => row.ID}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        sx={{
          ".MuiDataGrid-virtualScroller": {
            overflowX: "auto",
          },
        }}
      />
    </Box>
  );
};

export default TableRendicionesPeri;
