import React from "react";
import { Grid } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import "react-toastify/dist/ReactToastify.css";
import Table from "react-bootstrap/Table";

const ManifiestoParaOrden = ({ manifiestos }) => {
  return (
    <Grid item xs={12} sm={5} md={4} lg={12}>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Manifiestos</th>
            <th>Tipo de manifiestos</th>
            <th>Nombre chofer</th>
            <th>Fecha de cierre</th>
            <th>Descargar</th>
          </tr>
        </thead>
        <tbody>
          {manifiestos.map((manifiesto, index) => (
            <tr key={index}>
              <td>{manifiesto.ID}</td>
              <td>{manifiesto.TIPO_MANIFIESTO}</td>
              <td>{manifiesto.NOMBRE_CHOFER_ENTRADA}</td>
              <td>{new Date(manifiesto.FH_CIERRE).toLocaleString()}</td>
              <td>
                <a href={`https://backend.cargoexteam.cl/medicion/creacionManifiestoPDF/${manifiesto.ID}`}>
                  <FontAwesomeIcon icon={faFilePdf} color="red" />
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Grid>
  );
};

export default ManifiestoParaOrden;
