import React, { useState, useMemo } from "react";
import { Box, TextField, Button } from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";
import * as XLSX from 'xlsx';
import "react-toastify/dist/ReactToastify.css";

const TableListadoAgentes = ({ columns, rows, loading }) => {
  const [searchText, setSearchText] = useState("");

  const filteredRows = useMemo(() => {
    const searchLowercase = searchText.toLowerCase();
    return rows.filter((row) => {
      const nombre = row.NOMBRE_AGENTE ? row.NOMBRE_AGENTE.toLowerCase().includes(searchLowercase) : false;
      return nombre;
    });
  }, [searchText, rows]);

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Agentes");
    XLSX.writeFile(workbook, "ListadoAgentes.xlsx");
  };

  return (
    <Box sx={{ height: 500, width: 1 }}>
      <Box sx={{ display: "flex", gap: 1, alignItems: "center", mb: 2 }}>
        <TextField
          size="small"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          variant="outlined"
          placeholder="Buscar por nombre..."
          sx={{ flexGrow: 1 }}
        />
        <Button
          variant="contained"
          onClick={downloadExcel}
          sx={{ maxHeight: 40, backgroundColor: "#041562", color: "white" }}
        >
          Descargar Excel
        </Button>
      </Box>
      <DataGrid
        rows={filteredRows}
        columns={columns.map((column) => ({
          ...column,
        }))}
        pageSize={5}
        loading={loading}
        checkboxSelection={false}
        autoPageSize={true}
        disableRowSelectionOnClick
        getRowId={(row) => row.ID}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        sx={{
          ".MuiDataGrid-virtualScroller": {
            overflowX: "auto",
          },
        }}
      />
    </Box>
  );
};

export default TableListadoAgentes;
