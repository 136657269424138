import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Error404 from "../../components/assets/Error404";
import CircularProgress from "@mui/material/CircularProgress"; // Asegúrate de importar CircularProgress

const ConsultaMasivo = () => {
  const userInfo = useSelector((state) => state.user.userInfo);
  const idUsuario = userInfo ? userInfo.id : null;
  const token = userInfo ? userInfo.token : null;
  const [iframeLoaded, setIframeLoaded] = useState(null);
  const [loading, setLoading] = useState(true);
  const [retryCount, setRetryCount] = useState(0);

  const iframeUrl = `https://app.cargoex.cl/sistemamigracion/public/consultamasiva?idUsuario=${idUsuario}&token=${token}`;
  console.log(token);

  useEffect(() => {
    if (!idUsuario || !token) {
      setIframeLoaded(false);
      setLoading(false);
    }
  }, [idUsuario, token]);

  useEffect(() => {
    if (iframeLoaded === false && retryCount < 3) {
      setTimeout(() => {
        setLoading(true);
        setRetryCount(retryCount + 1);
        setIframeLoaded(null);
      }, 5000);
    }
  }, [iframeLoaded, retryCount]);

  const handleLoad = () => {
    setIframeLoaded(true);
    setLoading(false);
    setRetryCount(0);
  };

  const handleError = () => {
    setIframeLoaded(false);
    setLoading(false);
  };

  return (
    <div style={{ height: "100%", width: "100%", position: "relative" }}>
      {loading && (
        <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
          <CircularProgress />
        </div>
      )}
      {!loading && iframeLoaded === false ? (
        <Error404 />
      ) : (
        <iframe
          src={iframeUrl}
          title="Reporte Agentes"
          width="100%"
          height="100%"
          style={{ border: "none" }}
          onLoad={handleLoad}
          onError={handleError}
        />
      )}
    </div>
  );
};

export default ConsultaMasivo;
