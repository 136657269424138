import React from "react";
import { DataGrid, esES } from "@mui/x-data-grid";
import { Box } from "@mui/material";

const ReusableTableRendiciones = ({ columns, rows, loading }) => {
  return (
    <Box sx={{ height: 400, width: "100%", mt: 1 }}>
      <DataGrid
        rows={rows}
        columns={columns.map((column) => ({
          ...column,
        }))}
        pageSize={5}
        loading={loading}
        checkboxSelection={false}
        autoPageSize={true}
        disableColumnMenu={true}
        disableColumnSelector
        disableRowSelectionOnClick
        disableColumnFilter
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        getRowId={(row) => row.ID || row.OD_PAPEL}
        sx={{
          ".MuiDataGrid-virtualScroller": {
            overflowX: "auto",
          },
        }}
      />
    </Box>
  );
};

export default ReusableTableRendiciones;
