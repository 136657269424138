import React, { useState, memo } from "react";
import { Grid, Button, TextField, Container, Paper, CircularProgress, Box } from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import EdicionOrdenes from "../../components/FacturacionComponentes/componentesOrdenes/EdicionOrdenes";
import CreateContent from "../../components/FacturacionComponentes/componentesOrdenes/CreateContent";
import {
  buscarOrdenes,
  obtenerTodo,
  modificarFechasAPI,
  eliminarOrdenAPI,
  recuperarOrden,
  eliminarOrdenMasivo,
} from "../../services/ordenesServices";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import { Modal, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import * as XLSX from "xlsx";

const GestionOrdenes = () => {
  const userInfo = useSelector((state) => state.user.userInfo);
  const id_usuario = userInfo ? userInfo.id : null;
  const [searchValue, setSearchValue] = useState("");
  const [isLoadingEditar, setIsLoadingEditar] = useState(false);
  const [ordenData, setOrdenData] = useState(null);
  const [showEditor, setShowEditor] = useState(false);
  const [isModalCrearOpen, setIsModalCrearOpen] = useState(false);
  const [isLoadingCrear, setIsLoadingCrear] = useState(false);
  const [isLoadingRecuperar, setIsLoadingRecuperar] = useState(false);
  const [isLoadingFechas, setIsLoadingFechas] = useState(false);
  const [isLoadingEliminar, setIsLoadingEliminar] = useState(false);
  const [dataAll, setDataAll] = useState({});
  const [openModalIndividual, setOpenModalIndividual] = useState(false);
  const [openModalMasiva, setOpenModalMasiva] = useState(false);
  const [odParaEliminar, setOdParaEliminar] = useState("");
  const [odsParaEliminar, setOdsParaEliminar] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openModalModificarFecha, setOpenModalModificarFecha] = useState(false);
  const [odsYFechasParaModificar, setOdsYFechasParaModificar] = useState([]);
  const [openModalRecuperarOrden, setOpenModalRecuperarOrden] = useState(false);
  const [odParaRecuperar, setOdParaRecuperar] = useState([]);

  /* buscamos la orden y traemos sus datos */
  const BuscarParaEditar = async () => {
    try {
      setIsLoadingEditar(true);
      const response = await buscarOrdenes({ searchValue });
      setOrdenData(response);
      setShowEditor(true);
      setIsModalCrearOpen(false);
      toast.success("Orden encontrada exitosamente.");
    } catch (error) {
      console.error("Error al obtener datos", error);
      toast.error("Error obteniendo datos. Por favor, inténtalo de nuevo.");
    } finally {
      setIsLoadingEditar(false);
    }
  };

  /* cierra el editor de orden */
  const handleCloseEditor = () => {
    setShowEditor(false);
  };

  /* si doy enter busca en editar */
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      BuscarParaEditar();
      event.preventDefault();
    }
  };

  const handleOpenModalRecuperarOrden = () => {
    setOpenModalRecuperarOrden(true);
  };

  const handleCloseModalRecuperarORden = () => {
    setOpenModalRecuperarOrden(false);
  };

  const handleOpenModalModificarFecha = () => {
    setOpenModalModificarFecha(true);
  };

  const handleCloseModalModificarFecha = () => {
    setOpenModalModificarFecha(false);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleFileUploadModificarFecha = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1, raw: false });
      const headers = jsonData[0];
      const odIndex = headers.indexOf("OD");
      const fechaIndex = headers.indexOf("FECHA");
      if (odIndex === -1 || fechaIndex === -1) {
        toast.error("Las columnas 'OD' o 'FECHA' no se encontraron.");
        return;
      }
      const odsYFechasParaModificar = jsonData
        .slice(1)
        .map((row) => {
          const od = row[odIndex];
          let fecha = row[fechaIndex];
          if (fecha !== undefined) {
            fecha = fecha.toString();
            fecha = fecha.replace(/\//g, "-");
          }
          return { od, fecha };
        })
        .filter((item) => item.od && item.fecha);
      setOdsYFechasParaModificar(odsYFechasParaModificar);
    };
    reader.readAsArrayBuffer(file);
  };

  const handleModificarFechasMasivamente = async () => {
    handleCloseModalModificarFecha();
    if (odsYFechasParaModificar.length === 0) {
      toast.error("No hay datos para modificar.");
      return;
    }
    const result = await Swal.fire({
      title: "¿Estás seguro?",
      text: "Estás a punto de modificar las fechas de varias órdenes.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, modificar",
      cancelButtonText: "Cancelar",
    });
    if (result.isConfirmed) {
      setIsLoadingFechas(true);
      try {
        const response = await modificarFechasAPI(odsYFechasParaModificar, id_usuario);
        if (response.message === "Proceso de actualización completado.") {
          toast.success("Fechas modificadas con éxito.");
          setOpenModalModificarFecha(false);
          setOdsYFechasParaModificar([]);
        } else {
          toast.error("Error al modificar las fechas.");
        }
      } catch (error) {
        console.error("Error al modificar fechas:", error);
        toast.error("Error al modificar fechas. Por favor, inténtalo de nuevo.");
      } finally {
        setIsLoadingFechas(false);
      }
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      const odColumnIndex = jsonData[0].indexOf("OD");
      if (odColumnIndex !== -1) {
        const ods = jsonData
          .slice(1)
          .map((row) => row[odColumnIndex])
          .filter((od) => !isNaN(od) && od.toString().trim() !== "");
        setOdsParaEliminar(ods);
      } else {
        console.error("La columna OD no se encontró en el archivo.");
        toast.error("La columna OD no se encontró en el archivo");
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const handleOpenModalIndividual = () => {
    setOpenModalIndividual(true);
    setOpenDialog(false);
  };

  const handleCloseModalIndividual = () => {
    setOpenModalIndividual(false);
  };

  const handleOpenModalMasiva = () => {
    setOpenModalMasiva(true);
    setOpenDialog(false);
  };

  const handleCloseModalMasiva = () => {
    setOpenModalMasiva(false);
  };

  const handleRecuperarOrden = async () => {
    handleCloseModalRecuperarORden();

    setTimeout(async () => {
      const result = await Swal.fire({
        title: "¿Estás seguro?",
        text: "¿Deseas recuperar esta orden?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, recuperar",
        cancelButtonText: "No, cancelar",
      });
      if (result.isConfirmed) {
        try {
          setIsLoadingRecuperar(true);
          const response = await recuperarOrden(odParaRecuperar, id_usuario);
          if (response.message === "No se encontró ninguna orden") {
            toast.info(response.message);
          } else {
            toast.success(response.message);
          }
          setOdParaRecuperar("");
        } catch (error) {
          console.error("Error completo al recuperar la orden:", error);
          toast.error("Orden no encontrada. Contacte con informática.");
        } finally {
          setIsLoadingRecuperar(false);
        }
      }
    }, 300);
  };

  const handleEliminarMasivamente = async () => {
    handleCloseModalMasiva();
    setTimeout(async () => {
      const result = await Swal.fire({
        title: "¿Estás seguro?",
        text: "¿Deseas eliminar estas órdenes de manera masiva?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "No, cancelar",
      });
      if (result.isConfirmed) {
        setIsLoadingEliminar(true);
        try {
          const response = await eliminarOrdenMasivo(odsParaEliminar, id_usuario);
          if (response.totalEliminadas > 0) {
            toast.success(`${response.message} Total de órdenes eliminadas: ${response.totalEliminadas}`);
          } else {
            toast.info("No se eliminó ninguna orden.");
          }
        } catch (error) {
          console.error("Error al eliminar la orden:", error);
          toast.error("Error al eliminar la orden. Por favor, inténtalo de nuevo.");
        } finally {
          setIsLoadingEliminar(false);
        }
      }
    }, 300);
  };

  const handleEliminarOrdenIndividual = async () => {
    handleCloseModalIndividual();
    setTimeout(async () => {
      const result = await Swal.fire({
        title: "¿Estás seguro?",
        text: "¿Deseas eliminar esta orden?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "No, cancelar",
      });
      if (result.isConfirmed) {
        try {
          setIsLoadingEliminar(true);
          const response = await eliminarOrdenAPI(odParaEliminar, id_usuario);
          if (response.message === "No se encontró ninguna orden") {
            toast.info(response.message);
          } else {
            toast.success(response.message);
          }
          setOdParaEliminar("");
        } catch (error) {
          console.error("Error completo al eliminar la orden:", error);
          toast.error("Orden no encontrada. Contacte con informática.");
        } finally {
          setIsLoadingEliminar(false);
        }
      }
    }, 300);
  };

  /* obtener la data completa */
  const fetchAllData = async () => {
    try {
      const data = await obtenerTodo();
      setDataAll(data);
    } catch (error) {
      console.error("Error fetching dataAll:", error);
      toast.error("Error obteniendo datos. Por favor, inténtalo de nuevo.");
    }
  };

  /* abrir modal para crear ordenes */
  const handleOpenModalCrear = async () => {
    setIsLoadingCrear(true);
    try {
      await fetchAllData();
      setIsModalCrearOpen(true);
      /* setShowEditor(false); */
    } catch (error) {
      console.error("Error obteniendo datos para crear:", error);
      toast.error("Error obteniendo datos. Por favor, inténtalo de nuevo.");
    } finally {
      setIsLoadingCrear(false);
    }
  };

  return (
    <>
      <Container maxWidth="lg">
        <Paper sx={{ p: 1, mb: 1, maxWidth: "95.8%", ml: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={5} md={4} lg={3}>
              <TextField
                label="Buscar por OD/OD PAPEL"
                variant="outlined"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                onKeyDown={handleKeyDown}
                fullWidth
                margin="dense"
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={5} md={4} lg={1.8}>

              <Button
                fullWidth
                variant="outlined"
                color="primary"
                onClick={BuscarParaEditar}
                style={{ backgroundColor: "#041562", color: "#FFFFFF", marginTop: 10 }}
                disabled={isLoadingEditar}
              >
                {isLoadingEditar ? <CircularProgress size={24} color="inherit" /> : "Editar"}
              </Button>
            </Grid>
            <Grid item xs={12} sm={5} md={4} lg={1.8}>
              <Button
                fullWidth
                margin="dense"
                variant="outlined"
                color="primary"
                onClick={handleOpenModalCrear}
                style={{
                  backgroundColor: "#041562",
                  color: "#FFFFFF",
                  marginTop: 10,
                }}
              >
                {isLoadingCrear ? <CircularProgress sx={{ color: "#FFFFFF" }} size={24} /> : "Crear"}
              </Button>
            </Grid>

            <Grid item xs={12} sm={5} md={4} lg={1.8}>
              <Button
                fullWidth
                margin="dense"
                variant="outlined"
                color="primary"
                onClick={handleOpenModalRecuperarOrden}
                style={{
                  backgroundColor: "#041562",
                  color: "#FFFFFF",
                  marginTop: 10,
                }}
              >
                {isLoadingRecuperar ? <CircularProgress sx={{ color: "#FFFFFF" }} size={24} /> : "Recuperar"}
              </Button>
            </Grid>

            <Grid item xs={12} sm={5} md={4} lg={1.8}>
              <Button
                fullWidth
                margin="dense"
                variant="outlined"
                color="primary"
                onClick={handleOpenModalModificarFecha}
                style={{
                  backgroundColor: "#041562",
                  color: "#FFFFFF",
                  marginTop: 10,
                }}
              >
                {isLoadingFechas ? <CircularProgress sx={{ color: "#FFFFFF" }} size={24} /> : "Fechas"}
              </Button>
            </Grid>

            <Grid item xs={12} sm={5} md={4} lg={1.8}>
              <Button
                fullWidth
                margin="dense"
                variant="outlined"
                color="primary"
                onClick={handleOpenDialog}
                style={{
                  backgroundColor: "#DA251C",
                  color: "#FFFFFF",
                  marginTop: 10,
                }}
              >
                {isLoadingEliminar ? <CircularProgress sx={{ color: "#FFFFFF" }} size={24} /> : "Eliminar"}
              </Button>
            </Grid>
          </Grid>
          {showEditor && ordenData && (
            <EdicionOrdenes key={ordenData.ID} ordenData={ordenData} onClose={handleCloseEditor} />
          )}
          {isModalCrearOpen && <CreateContent dataAll={dataAll} />}
        </Paper>
      </Container>
      <div>
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>{"Eliminar Orden"}</DialogTitle>
          <DialogContent>
            <DialogContentText>¿Deseas realizar una eliminación individual o masiva?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Cancelar</Button>
            <Button onClick={handleOpenModalIndividual}>Individual</Button>
            <Button onClick={handleOpenModalMasiva}>Masiva</Button>
          </DialogActions>
        </Dialog>
        {/* Modal de Eliminación Individual */}
        <Modal open={openModalIndividual} onClose={handleCloseModalIndividual}>
          <Box
            sx={{
              borderRadius: "10px",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 2,
            }}
          >
            <TextField
              fullWidth
              label="OD a eliminar"
              value={odParaEliminar}
              onChange={(e) => setOdParaEliminar(e.target.value)}
            />
            <Button style={{ backgroundColor: "#DA251C" }} variant="contained" onClick={handleEliminarOrdenIndividual}>
              Eliminar
            </Button>
          </Box>
        </Modal>
        {/* recuperar */}
        <Modal open={openModalRecuperarOrden} onClose={handleCloseModalRecuperarORden}>
          <Box
            sx={{
              borderRadius: "10px",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 2,
            }}
          >
            <TextField
              fullWidth
              label="OD a recuperar"
              value={odParaRecuperar}
              onChange={(e) => setOdParaRecuperar(e.target.value)}
            />
            <Button style={{ backgroundColor: "#041562" }} variant="contained" onClick={handleRecuperarOrden}>
              Recuperar orden
            </Button>
          </Box>
        </Modal>
        {/* modal eliminar masivo */}
        <Modal open={openModalMasiva} onClose={handleCloseModalMasiva}>
          <Box
            sx={{
              borderRadius: "10px",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 2,
            }}
          >
            <input type="file" onChange={handleFileUpload} />
            <Button style={{ backgroundColor: "#DA251C" }} variant="contained" onClick={handleEliminarMasivamente}>
              Eliminar Masivamente
            </Button>
            <div className="centered-link">
              <a
                href={`${process.env.REACT_APP_BACKEND_URL}certificaciones/downloadModeloEliminarOrdenesPage`}
                download="Modelo_Eliminar.xlsx"
                style={{
                  marginTop: "10px",
                  color: "#041562",
                  textAlign: "center",
                  display: "inline-block",
                  width: "100%",
                }}
              >
                Descargar Modelo de eliminar
              </a>
            </div>
          </Box>
        </Modal>
      </div>
      <div>
        {/* modal modificar fechas */}
        <Modal open={openModalModificarFecha} onClose={handleCloseModalModificarFecha}>
          <Box
            sx={{
              borderRadius: "10px",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 2,
            }}
          >
            <input type="file" onChange={handleFileUploadModificarFecha} />
            <Button
              style={{ backgroundColor: "#041562" }}
              variant="contained"
              onClick={handleModificarFechasMasivamente}
            >
              Modificar Fechas
            </Button>
            <div className="centered-link">
              <a
                href={`${process.env.REACT_APP_BACKEND_URL}certificaciones/downloadModeloFechasOrdenesPage`}
                download="Modelo_Fechas.xlsx"
                style={{
                  marginTop: "10px",
                  color: "#041562",
                  textAlign: "center",
                  display: "inline-block",
                  width: "100%",
                }}
              >
                Descargar Modelo de Fechas
              </a>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default memo(GestionOrdenes);
