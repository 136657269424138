import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export function toastCabecera() {
  toast.error("Error en cabecera. Por favor, especificar cabecera valida.");
}

export function toastDatosEnBlanco(COLUMNA, FILA) {
  toast.error(
    `Error en archivo. En la columna ${COLUMNA}, la fila ${FILA} esta vacía`);
};

export function sinCliente() {
  toast.error("Faltan datos. Por favor, ingrese un cliente.");
}

export function sinFecha() {
  toast.error("Faltan datos. Por favor, ingrese una fecha.");
}

export function fechaActual() {
  toast.error(
    "Error de datos. Por favor, fecha no puede ser igual a la actual.");
}

export function alertaComunasOrigenToast(comunas) {
  toast.error(
    `Las Siguientes Comunas de Origen Estan Mal Escritas o No Esxisten:\n${comunas.join(
      "\n- "
    )}\n.Favor de revisar el archivo.`
  );
}

export function alertaComunasDestinoToast(comunas) {
  toast.error(
    `Las Siguientes Comunas de Destino Estan Mal Escritas o No Existen:\n${comunas.join(
      "\n- "
    )}\n.Favor de revisar el archivo.`
  );
}

export function ordenCreada() {
  toast.success("Retiros creadas con éxito. Retiros creados exitosamente.");
}

export function excelCargado() {
  toast.success("Excel cargado");
}

export function ordenNoCreada() {
  toast.error(
    "Error de creacion. Verificar datos para la creacion de retiros");
}

export function excelVacio() {
  toast.error(
    "Error, Excel esta vacio");
}

export function fechaMenor() {
  toast.error(
    "Error de datos. Por favor, fecha no puede ser menor a la actual.");
}

export function alertaOrdenes(ORDENES) {
  toast.error(
    `Estas Ordenes ya existen:\n${ORDENES.join(
      "\n- "
    )}\n.Favor de revisar el archivo.`
  );
}
