import React, { useState, memo, useRef } from "react";
import { Grid, Button, Card, CardContent, Paper, TextField } from "@mui/material";
import Tabla from "../../components/OperacionesComponentes/componentesCubicos/tabla";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import { updateParametros } from "../../services/CubicosServices";
import {
  toastDatosEnBlanco,
  ordenNoModificada,
  ordenModificada,
} from "../../components/OperacionesComponentes/componentesCubicos/Alertas";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  ValidadorArchivo,
  limiteDeFilas,
  LinearProgressOverlay,
} from "../../components/OperacionesComponentes/componentesCubicos/Funciones";
import { getOrdenesMedidas } from "../../services/ManifiestosServices";
import "../../components/assets/retiros.css";
import { useSelector } from "react-redux";

const CubicosOperaciones = () => {
  /* accedemos al id de usuario */
  const userInfo = useSelector((state) => state.user.userInfo);
  // eslint-disable-next-line no-unused-vars
  const id_usuario = userInfo ? userInfo.id : null;
  const [fecha, setFecha] = useState("");
  const [key, setKey] = useState(Date.now());
  const fileInputRef = useRef(null);
  const [data, setData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [progress, setProgress] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [jsonDataArray, setJsonDataArray] = useState([]);
  const [isbtnEnviar, setBtnEnviar] = useState(true);

  // La cabecera predefinida, excel debe terner los mismo datos.
  const predefinedHeaders = ["CLIENTE", "OD_PAPEL", "BULTOS", "PESO", "ALTO", "LARGO", "ANCHO"];

  // Las columnas obligatorias segun cabecera
  const obligatoriasHeaders = ["OD_PAPEL", "BULTOS", "PESO", "ALTO", "LARGO", "ANCHO"];

  //Funcion validar que la cabecera del excel sea la misma del codigo
  const validateHeaders = (parsedData, predefinedHeaders) => {
    const fileHeaders = parsedData.data[0];
    //-------------------------------------------------------------------
    console.log("Encabezados del archivo CSV:", fileHeaders);
    console.log("Encabezados predefinidos:", predefinedHeaders);
    //-------------------------------------------------------------------
    for (let i = 0; i < predefinedHeaders.length; i++) {
      if (fileHeaders[i] !== predefinedHeaders[i]) {
        return false;
      }
    }
    return true;
  };
  //Carga de Excel y sus validaciones
  const cargaExcelTabla = async (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsBinaryString(file);

    await new Promise((resolve) => {
      reader.onload = async (event) => {
        const workbook = XLSX.read(event.target.result, { type: "binary" });
        const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
        const csvData = XLSX.utils.sheet_to_csv(firstSheet);

        Papa.parse(csvData, {
          complete: async (parsedData) => {
            // Validamos la cabecera
            const headersMatch = validateHeaders(parsedData, predefinedHeaders);

            // Si la validación de cabecera es correcta continúa
            if (ValidadorArchivo(headersMatch)) {
              Papa.parse(csvData, {
                complete: (parsedData) => {
                  const newHeaders = predefinedHeaders;
                  //Donde se ve el excel
                  setHeaders(newHeaders);

                  // Filtra los objetos que no tienen propiedades vacías
                  const dataSinObjetosVacios = parsedData.data
                    .slice(0)
                    .map((row) => {
                      let obj = {};
                      newHeaders.forEach((header, index) => {
                        obj[header] = row[header];
                      });

                      return obj;
                    })
                    .filter((obj) => {
                      for (const key in obj) {
                        if (obj[key] !== "") {
                          return true; // El objeto tiene al menos una propiedad no vacía
                        }
                      }
                      return false; // Todas las propiedades son vacías
                    });

                  // Usa dataSinObjetosVacios en lugar de parsedData.data en setData
                  setData(dataSinObjetosVacios);

                  //------------------------------------------------

                  //Cargamos los datos al array
                  parsedData.data.slice(0).forEach((row, index) => {
                    const rowData = {};
                    rowData["OD_PAPEL"] = row["OD_PAPEL"];
                    rowData["BULTOS"] = row["BULTOS"];

                    //Datos donde se modifica la coma por el punto
                    rowData["PESO"] = row["PESO"] ? row["PESO"].toString().replace(",", ".") : null;
                    rowData["LARGO"] = row["LARGO"] ? row["LARGO"].toString().replace(",", ".") : null;
                    rowData["ALTO"] = row["ALTO"] ? row["ALTO"].toString().replace(",", ".") : null;
                    rowData["ANCHO"] = row["ANCHO"] ? row["ANCHO"].toString().replace(",", ".") : null;

                    rowData["USUARIO"] = id_usuario;

                    //-----------------------

                    // Agregamos rowData al array jsonDataArray
                    if (limiteDeFilas(row)) {
                      jsonDataArray.push(JSON.stringify(rowData));
                    }
                  });

                  //Realizar la validación de campos vacíos del excel
                  for (let rowIndex = 0; rowIndex < parsedData.data.length; rowIndex++) {
                    const row = parsedData.data[rowIndex];
                    // Valida hasta la fila con datos del excel
                    if (limiteDeFilas(row)) {
                      const columnasFaltantes = obligatoriasHeaders.filter((columna) => !row[columna]);
                      if (columnasFaltantes.length > 0) {
                        toastDatosEnBlanco(columnasFaltantes.join(", "), rowIndex + 2);
                        // Limpia el input file
                        e.target.value = "";
                        // Borra los datos de la tabla
                        setData([]);
                        setJsonDataArray([]);
                        return;
                      } else {
                        resolve();
                      }
                    }
                  }
                },
                header: true,
              });
              setBtnEnviar(false);
              setProgress(100);
              setIsVisible(false);
              setProgress(0);
              setCompleted(false);
            } else {
              e.target.value = "";
              setKey(Date.now());
              fileInputRef.current.value = "";
              setData([]);
              setJsonDataArray([]);
            }
          },
        });
      };
    });
  };

  //Limpiamos Array
  const LimpiaArray = (e) => {
    setJsonDataArray([]);
  };

  const resetState = () => {
    setData([]);
    setJsonDataArray([]);
    setIsVisible(false);
    setProgress(0);
    setCompleted(false);
    fileInputRef.current.value = "";
  };

  //Actualizacion de datos
  const subirDatos = async () => {
    const totalOrders = jsonDataArray.length;
    let updateOrdersCount = 0;

    const updateOrders = [];

    for (const jsonData of jsonDataArray) {
      try {
        setIsVisible(true);

        const response = await updateParametros(jsonData);

        // Actualiza el progreso
        updateOrdersCount++;
        const newProgress = (updateOrdersCount / totalOrders) * 100;
        setProgress(newProgress);

        if (response && response.success) {
          updateOrders.push(response);
          const newProgress = (updateOrdersCount / totalOrders) * 100;
          setProgress(newProgress);
          if (newProgress === 100) {
            setCompleted(true);
          }
        }
      } catch (error) {
        toast.error("Error al modificar ordenes. Por favor, inténtalo de nuevo.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsVisible(false); // Establece isVisible en false en caso de error
        setProgress(0);
        setCompleted(false);
      }
    }
    setProgress(100);

    //Si se Modifican ordenes da la alerta
    if (updateOrdersCount > 0) {
      ordenModificada();
      setIsVisible(false);
      setProgress(0);
      setCompleted(false);

      //Dejamos pasar 2 segundos despues de la creacion de la orden y limpiamos todos los datos
      setTimeout(() => {
        setKey(Date.now());
        fileInputRef.current.value = "";
        setData([]);
        setJsonDataArray([]);
        resetState();
      }, 2000);
    } else {
      ordenNoModificada();
    }
  };

  const exportToExcel = (data) => {
    const formattedData = data.map((item) => ({
      CLIENTE: item.CLIENTE,
      OD_PAPEL: item.OD_PAPEL,
      BULTOS: item.BULTOS,
      PESO: item.PESO,
      ALTO: item.ALTO,
      LARGO: item.LARGO,
      ANCHO: item.ANCHO,
      CENTRO_COSTO: item.CENTRO_COSTO,
      NOTA: item.NOTA,
    }));

    const worksheet = XLSX.utils.json_to_sheet(formattedData);

    const workbook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"],
    };

    // Escribir y guardar el archivo de Excel directamente
    XLSX.writeFile(workbook, "Planilla Cubicos.xlsx");
  };

  const descargarExcel = async () => {
    const datos = {
      FECHA: fecha,
    };
    const data = await getOrdenesMedidas(datos);
    if(data.length>0)
    {
      exportToExcel(data);
    }
    else{
      toast.error("Datos no encontrados para fecha seleccionada.");
    }
   
    
  };

  const handleChangeFecha = async (event, newValue) => {
    try {
      setFecha(event.target.value);
    } catch (error) {}
  };

  return (
    <div>
      <Paper style={{ padding: "10px" }}>
        <Form>
          <Grid container spacing={2} alignItems="flex-end">
          <Grid item xs={12} sm={4} md={4} lg={4}>
          <TextField
                fullWidth
                type="date"
                size="small"
                label="Fecha"
                value={fecha}
                onChange={handleChangeFecha}
                InputLabelProps={{
                  shrink: true,
                }}
              />
          </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Button
                style={{ backgroundColor: "#041562" }}
                className="boton2"
                size="small"
                variant="contained"
                color="primary"
                onClick={descargarExcel}
              >
                Descargar Detalle Retornos
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="flex-end" marginTop={"15px"}>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Form.Group controlId="formFile">
                <Form.Label className="tu-archivo-de-estilos1">Seleccione Archivo Excel</Form.Label>
                <Form.Control
                  type="file"
                  ref={fileInputRef}
                  key={key}
                  onChange={cargaExcelTabla}
                  onClick={LimpiaArray}
                  size="sm"
                />
              </Form.Group>
            </Grid>

            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Button
                variant="contained"
                size="large"
                sx={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#041562",
                }}
                onClick={subirDatos}
                disabled={isbtnEnviar}
              >
                Enviar
              </Button>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Button
                variant="contained"
                size="large"
                sx={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#DA251C",
                }}
                onClick={resetState}
              >
                Cancelar
              </Button>
            </Grid>
          </Grid>

          <div>
            <LinearProgressOverlay isVisible={isVisible} progress={progress} completed={completed} disabled={false} />
          </div>

          {data.length > 0 && (
            <Grid item xs={10} sm={6} md={6} lg={6} style={{ width: "100%", maxWidth: "100%" }}>
              <Card elevation={10} className="cardContentWithMargin">
                <CardContent
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    Width: "100%",
                    maxHeight: "75vh",
                    overflowX: "auto",
                    overflowY: "auto",
                  }}
                >
                  <Tabla
                    data={data}
                    headers={headers}
                    onCpdateellU={(rowIndex, header, newValue) => {
                      const newData = [...data];
                      newData[rowIndex][header] = newValue;
                      setData(newData);
                    }}
                  />
                </CardContent>
              </Card>
            </Grid>
          )}
        </Form>
      </Paper>
    </div>
  );
};

export default memo(CubicosOperaciones);
