import axios from "axios";
/* Usa la variable de entorno para la URL base */
const BASE_API_URL = process.env.REACT_APP_BACKEND_URL;

/* Función para cambiar contraseña */
export const cambiarContraseña = async (email, passwordPalabras) => {
  try {
    const response = await axios.post(`${BASE_API_URL}login/cambiarClave`, {
      email,
      passwordPalabras,
    });
    console.log(response);
    return response.data;
  } catch (error) {
    console.error("Error al restablecer la contraseña:", error.response);
    throw new Error(error.response.data.error || "Error desconocido al restablecer la contraseña");
  }
};

/* Función para verificar código de restablecimiento y cambiar contraseña */
export const verificarCodigo = async (email, codigo) => {
  try {
    const response = await axios.post(`${BASE_API_URL}login/verificarCodigo`, {
      email,
      codigo,
    });
    return response.data;
  } catch (error) {
    console.error("Error al verificar el código:", error.response);
    throw new Error(error.response?.data?.error || "Error desconocido al verificar el código");
  }
};

/* Función para solicitar el restablecimiento de contraseña */
export const solicitarRestablecimiento = async (email) => {
  try {
    const response = await axios.post(`${BASE_API_URL}login/solicitarRestablecimiento`, { email });
    console.log("Respuesta de restablecimiento:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error al solicitar el restablecimiento de contraseña:", error.response || error);
    throw error;
  }
};
