import axios from "axios";
/* Usa la variable de entorno para la URL base */
const BASE_API_URL = process.env.REACT_APP_BACKEND_URL;

/* obtenemos clientes */
export const obtenerCiudades = async () => {
  try {
    const response = await axios.get(`${BASE_API_URL}clientes/obtenerCiudades`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
