import React, { memo } from "react";
import { Container, Typography } from "@mui/material";
import { PieChart, Pie, Cell, Legend, Tooltip, ResponsiveContainer } from "recharts";

const GeneralChart = ({ datos }) => {
  const [chartData, setChartData] = React.useState([]);
  const [efectividadCalculada, setEfectividadCalculada] = React.useState(0);

  React.useEffect(() => {
    if (datos && datos.length > 0) {
      const statusCounts = { enTransito: 0, enTransitoConEvento: 0, entregadas: 0 };
      datos.forEach((dato) => {
        if (dato.TIENE_ENTREGA === "NO" && dato.TIENE_EVENTO === "NO") {
          statusCounts.enTransito++;
        } else if (dato.TIENE_ENTREGA === "NO" && dato.TIENE_EVENTO === "SI") {
          statusCounts.enTransitoConEvento++;
        } else if (dato.TIENE_ENTREGA === "SI") {
          statusCounts.entregadas++;
        }
      });

      const total = statusCounts.enTransito + statusCounts.enTransitoConEvento + statusCounts.entregadas;
      const efectividad = ((statusCounts.entregadas + statusCounts.enTransitoConEvento) / total) * 100;
      setEfectividadCalculada(efectividad.toFixed(2));

      setChartData([
        { name: "En Transito", value: (statusCounts.enTransito / total) * 100, color: "#DA251C" },
        { name: "En Tránsito con evento", value: (statusCounts.enTransitoConEvento / total) * 100, color: "#041562" },
        { name: "Entregadas", value: (statusCounts.entregadas / total) * 100, color: "#4fa662" },
      ]);
    }
  }, [datos]);

  return (
    <Container
      maxWidth="lg"
      style={{
        height: "80vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography variant="h6">
        Desempeño General - Efectividad: {efectividadCalculada}%
      </Typography>
      <ResponsiveContainer width="100%" height="85%">
        <PieChart>
          <Pie
            data={chartData}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="45%"
            outerRadius={200}
            fill="#8884d8"
            labelLine={false}
            label={({ name, value }) => `${name}: ${value.toFixed(2)}%`}
          >
            {chartData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
          <Tooltip formatter={(value) => `${value.toFixed(2)}%`} />
          <Legend />
        </PieChart>
      </ResponsiveContainer>
    </Container>
  );
};

export default memo(GeneralChart);
