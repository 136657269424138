import React, { memo, useState, useEffect, useRef, useCallback } from "react";
import { Paper, Grid, Button, TextField, Autocomplete } from "@mui/material";
import { useSelector } from "react-redux";
import TablaManifiestos from "../../components/OperacionesComponentes/componentesManifiestos/TablaManifiestos";
import DeleteIcon from "@mui/icons-material/Delete";
import { getAgentesAll, buscarDatosOrden, getUpdateOrdenAgente } from "../../services/ManifiestosServices";
import { toast } from "react-toastify";

const EdicionAgenteOrden = () => {
  const inputRef = useRef(null);
  const userInfo = useSelector((state) => state.user.userInfo);
  const id_user = userInfo ? userInfo.id : null;
  const [ordenesDataTabla, setOrdenesDataTabla] = useState([]);
  const [loadingEliminar, setLoadingEliminar] = useState(false);
  const [agente, setAgente] = useState(null);
  const [agentesOption, setAgentesOption] = useState([]);
  useEffect(() => {
    const getAgentes = async () => {
      try {
        const dataAgentes = await getAgentesAll();
        // Extrae solo los nombres
        const nombresAgentes = dataAgentes.map((agente) => agente.name);
        setAgentesOption(nombresAgentes);
      } catch (error) {
        console.error("Error fetching agents:", error);
      }
    };
    getAgentes();
  }, [userInfo]);

  // Columnas para la tabla
  const columns = [
    { field: "OD", headerName: "OD", width: 100 },
    { field: "NOMBRE", headerName: "Cliente", width: 300 },
    { field: "BULTOS", headerName: "Bultos", width: 100 },
    { field: "TIPO_ORDEN", headerName: "Tipo Orden", width: 100 },
    { field: "NOMBRE_ORIGEN", headerName: "Comuna Origen", width: 200 },
    { field: "NOMBRE_DESTINO", headerName: "Comuna Destino", width: 200 },
    { field: "AGENTE_ASIGNADO", headerName: "Agente", width: 200 },
    {
      field: "delete",
      headerName: "Eliminar",
      width: 100,
      renderCell: (params) => (
        <DeleteIcon style={{ color: "#DA251C", cursor: "pointer" }} onClick={() => handleDelete(params.row.OD)} />
      ),
    },
  ];

  // Manejador para eliminar una orden
  const handleDelete = (orden) => {
    setLoadingEliminar(true);
    setOrdenesDataTabla((prevOrdenes) => prevOrdenes.filter((row) => row.OD !== orden));
    setLoadingEliminar(false);
  };

  const handleActualizar = async () => {
    if (!agente) {
      toast.error("Debe seleccionar un agente.");
      return;
    }

    if (ordenesDataTabla.length === 0) {
      toast.error("No hay órdenes para actualizar.");
      return;
    }

    const odList = ordenesDataTabla.map((orden) => orden.OD);

    try {
      // Enviar los datos al backend
      const resultado = await getUpdateOrdenAgente({
        AGENTE: agente,
        ORDENES: odList,
        USUARIO: id_user,
      });
      if (resultado.affectedRows > 0) {
        toast.success("Órdenes actualizadas con éxito.");
        setOrdenesDataTabla([]);
        setAgente(null);
      } else {
        toast.error("Órdenes no actualizadas.");
      }
    } catch (error) {
      toast.error("Error al actualizar las órdenes.");
    }
  };

  // Manejador para cuando se ingresa una OD
  const handleIngresarOrden = useCallback(
    async (event) => {
      event.preventDefault();
      const ordenValue = inputRef.current.value.trim();

      try {
        if (!ordenValue) {
          toast.error("Orden vacía!!!");
          return;
        }
        const dataOrden = await buscarDatosOrden(ordenValue);

        const ordenExistente = ordenesDataTabla.some((orden) => orden.ID === dataOrden[0].ID);

        if (ordenExistente) {
          toast.error("Orden ya ingresada!!!");
          inputRef.current.value = "";
          return;
        }

        if (!dataOrden || dataOrden.length === 0) {
          toast.error("Orden no existe!!!");
          inputRef.current.value = "";
          return;
        } else {
          ingresarOrdenTabla(dataOrden);
        }
      } catch (error) {
        console.error("Error al buscar los datos de la orden:", error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ordenesDataTabla]
  );

  const ingresarOrdenTabla = async (dataOrden) => {
    try {
      if (dataOrden && dataOrden.length > 0) {
        // Añadir la nueva orden a la tabla si no existe ya
        setOrdenesDataTabla([...ordenesDataTabla, dataOrden[0]]);
        inputRef.current.value = "";
      }
    } catch (error) {
      console.error("Error al ingresar la orden en la tabla:", error);
    }
  };

  // Manejador para seleccionar agente
  const handleChangeAgentes = (event, newValue) => {
    setAgente(newValue);
  };

  return (
    <div>
      <Paper style={{ padding: "10px" }}>
        <form>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <input
                ref={inputRef}
                type="text"
                placeholder="Ingrese OD"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    handleIngresarOrden(e);
                  }
                }}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Button
                variant="contained"
                size="medium"
                sx={{ width: "100%", height: "100%", backgroundColor: "#041562" }}
                onClick={handleIngresarOrden}
              >
                INGRESAR
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Autocomplete
                disableClearable
                value={agente}
                disablePortal
                id="size-small-outlined-multi"
                size="small"
                options={agentesOption}
                onChange={handleChangeAgentes}
                sx={{ width: "100%" }}
                PaperComponent={({ children }) => (
                  <Paper sx={{ width: "100%", textAlign: "left", "& li": { textAlign: "left" } }}>{children}</Paper>
                )}
                renderInput={(params) => <TextField {...params} label="AGENTE" />}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Button
                variant="contained"
                size="medium"
                sx={{ width: "100%", height: "100%", backgroundColor: "#041562" }}
                onClick={handleActualizar}
              >
                ACTUALIZAR AGENTE
              </Button>
            </Grid>
          </Grid>
          <Grid>
            <Grid style={{ marginTop: "10px" }}>
              <TablaManifiestos rows={ordenesDataTabla} columns={columns} loading={loadingEliminar} />
            </Grid>
          </Grid>
        </form>
      </Paper>
    </div>
  );
};

export default memo(EdicionAgenteOrden);
