import React, { useState } from "react";
import { Modal, Card, CardContent, Typography, TextField, Button, CardActions } from "@mui/material";
import { toast } from "react-toastify";
import { cambiarContraseña } from "../services/RestablecimientoServices";
import { IconButton } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const ChangePasswordModal = ({ open, handleClose, userInfo }) => {
  const [nuevaContraseña, setNuevaContraseña] = useState("");
  const [confirmarContraseña, setConfirmarContraseña] = useState("");
  const [showPassword, setShowPassword] = React.useState(false);

  const limpiarCampos = () => {
    setNuevaContraseña("");
    setConfirmarContraseña("");
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleRestablecerContraseña = async () => {
    const { email } = userInfo;
    if (nuevaContraseña !== confirmarContraseña) {
      toast.error("Las contraseñas no coinciden.");
      return;
    }
    try {
      const respuesta = await cambiarContraseña(email, nuevaContraseña);
      if (respuesta.success) {
        console.log("Contraseña restablecida con éxito");
        toast.success("Contraseña restablecida con éxito");
      } else {
        toast.error(respuesta.message || "Error al cambiar la contraseña.");
      }
    } catch (error) {
      console.error("Error al cambiar la contraseña:", error);
      toast.error("Error al cambiar la contraseña.");
    }
    limpiarCampos();
    handleClose();
  };

  // Limpiar los campos cuando se cierra el modal
  const handleCloseAndClear = () => {
    limpiarCampos();
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseAndClear}
      aria-labelledby="change-password-modal-title"
      aria-describedby="change-password-modal-description"
    >
      <Card
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 2,
          borderRadius: 2,
        }}
      >
        <CardContent>
          <Typography variant="h6" align="center">
            Cambiar Contraseña
          </Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            name="newPassword"
            label="Nueva Contraseña"
            type={showPassword ? "text" : "password"}
            id="newPassword"
            autoComplete="current-password"
            value={nuevaContraseña}
            onChange={(e) => setNuevaContraseña(e.target.value)}
            InputProps={{
              endAdornment: (
                <IconButton aria-label="toggle password visibility" onClick={togglePasswordVisibility} edge="end">
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              ),
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Confirmar Nueva Contraseña"
            type="password"
            id="password"
            autoComplete="new-password"
            value={confirmarContraseña}
            onChange={(e) => setConfirmarContraseña(e.target.value)}
          />
        </CardContent>
        <CardActions sx={{ justifyContent: "center" }}>
          <Button color="primary" variant="contained" onClick={handleRestablecerContraseña}>
            Cambiar
          </Button>
          <Button color="error" variant="outlined" onClick={handleCloseAndClear}>
            Cancelar
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

export default ChangePasswordModal;
