import React from "react";
import { Autocomplete as MUIAutocomplete, TextField, CircularProgress } from "@mui/material";

const CustomAutocomplete = ({
  label,
  options,
  getOptionLabel,
  value,
  onChange,
  onInputChange,
  isLoading,
  size = "small",
  variant = "outlined",
  fullWidth = true,
  freeSolo = false,
}) => {
  return (
    <MUIAutocomplete
      freeSolo={freeSolo}
      options={options}
      getOptionLabel={getOptionLabel}
      value={value}
      onChange={onChange}
      onInputChange={onInputChange}
      isOptionEqualToValue={(option, value) => option.ID === value.ID}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant={variant}
          fullWidth={fullWidth}
          size={size}
          margin="dense"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default CustomAutocomplete;
