import React, { useState } from "react";
import { Table, TableBody, TableContainer, TableHead, TableRow, Paper, Typography } from "@mui/material";
import { Box } from "@mui/material";
import { TablePagination } from "@mui/material";
import { StyledTableRow, StyledTableCell, StyledTableCell2 } from "./Styles";

const TablaComparativa = ({ ordenesDataTablaM, pendientes}) => {
  const [containerHeight] = useState(550);
  const [page, setPage] = useState(0);
  const rowsPerPage = 10;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const paginatedData = ordenesDataTablaM.slice(startIndex, endIndex);

  return (
    <Paper style={{ width: "100%" }}>
      <Box style={{ height: containerHeight, width: "100%" }}>
        <Box
          border={2}
          padding={0}
          borderRadius={1}
          boxShadow={1}
          sx={{
            color: "#DA251C",
            marginBottom: "5px",
            height: "40px",
          }}
        >
          <Typography
            variant="body1"
            //rows={3}
            sx={{
              fontFamily: "Arial, sans-serif",
              color: "#DA251C",
              fontWeight: "bold",
              fontSize: "1,4rem",
              marginBottom: "15px",
              textAlign: "center",
            }}
          >
            PENDIENTES: {pendientes}
          </Typography>
        </Box>

        <TableContainer sx={{ maxHeight: containerHeight - 46 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <StyledTableCell2>OD</StyledTableCell2>
                <StyledTableCell2>CLIENTE</StyledTableCell2>
                <StyledTableCell2>BULTOS</StyledTableCell2>
                <StyledTableCell2>MANIF.</StyledTableCell2>
                <StyledTableCell2>FECHA</StyledTableCell2>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedData.map((orden) => (
                <StyledTableRow key={orden.OD}>
                  <StyledTableCell>{orden.OD}</StyledTableCell>
                  <StyledTableCell
                    style={{
                      maxWidth: 150,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {orden.NOMBRE}
                  </StyledTableCell>
                  <StyledTableCell>{orden.BULTOS}</StyledTableCell>
                  <StyledTableCell>{orden.ID_MANIFIESTO}</StyledTableCell>
                  <StyledTableCell>
                    {new Date(orden.FH_CIERRE).toLocaleDateString("es-ES", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          component="div"
          count={ordenesDataTablaM.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          labelRowsPerPage="Página"
          rowsPerPageOptions={[10]}
        />
      </Box>
    </Paper>
  );
};

export default TablaComparativa;

