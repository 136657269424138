import {
    toastCabecera,
  } from "../componentesMasivos/Alertas";
  import Backdrop from "@mui/material/Backdrop";
  import { RedTransparentBackground } from "../componentesMasivos/Progress";
  
  //Limite de filas para ver en excel y tomar datos
  export const limiteDeFilas = (row) => {
    for (const column of Object.values(row)) {
      if (column) {
        return true;
      }
    }
    return false;
  };
  
  //Validamos cabecera archivo y si no corresponde enviamos alerta.
  export const ValidadorArchivo = (headersMatch) => {
  
    const condition5 = headersMatch;
  
    if (!condition5) {
      return toastCabecera();
    } else {
      return true;
    }
  };
  
  //Linea de %
  export const LinearProgressOverlay = ({ isVisible, progress, completed }) => {
    return (
      <div>
        <Backdrop
          open={isVisible}
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: "#fff" }}
        >
          <RedTransparentBackground progress={progress} completed={completed} />
        </Backdrop>
      </div>
    );
  };

  //Funcion transformar fecha
  /*export const convertDateToInputFormat = (dateString) => {
    if (!dateString) return "";
  
    const [datePart, timePart] = dateString.trim().split(" ").filter(Boolean);
  
    const [day, month, yearPart] = datePart.split("/");
  
    // Formato Año
    const year = yearPart.length === 2 ? `20${yearPart}` : yearPart;
  
    const [hour, minute] = timePart.split(":").slice(0, 3); 
  
    const formattedDate = `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
    return `${formattedDate} ${hour.padStart(2, "0")}:${minute.padStart(2, "0")}`;
  };*/
  
  export const convertDateToInputFormat = (dateString) => {
    if (!dateString) return "";
  
    const [datePart, timePart] = dateString.trim().split(" ").filter(Boolean);
  
    // Si datePart está en blanco, devolver una cadena vacía
    if (!datePart) return "";
  
    const [day, month, yearPart] = datePart.split("/");
  
    // Formato Año
    const year = yearPart.length === 2 ? `20${yearPart}` : yearPart;
  
    const [hour, minute] = timePart.split(":").slice(0, 3);
  
    // Validar si todos los componentes de la fecha están presentes
    if (!day || !month || !year) return "";
  
    // Formatear la fecha en dd/mm/yyyy
    const formattedDate = `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
    return `${formattedDate} ${hour.padStart(2, "0")}:${minute.padStart(2, "0")}`;
  };
  
  
  
  