import React, { useState, useEffect } from "react";
import { Paper, Grid, Autocomplete, TextField, Button, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { obtenerClientesRetiro } from "../../../services/RetirosService";
import { toast } from "react-toastify";
import { getOrdenesManifiestosRutaRetornoCliente } from "../../../services/ManifiestosServices";
import { insertManifiesto } from "../../../services/ManifiestosServices";
import { insertPickingManifiestoRutaComunas } from "../../../services/ManifiestoComunaServices";
import { buscarChoferes } from "../../../services/ManifiestoComunaServices";
import Swal from "sweetalert2";
import { downloadManifestPdfBarCode } from "../../../services/ManifiestosServices";
import { downloadManifestoRetornoClientes } from "../../../services/ManifiestosServices";

const ManifiestoMasivoRetornoCliente = () => {
  const userInfo = useSelector((state) => state.user.userInfo);

  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [codigoCliente, setCodigoCliente] = useState("");
  const [choferes, setChoferes] = useState([]);
  const [selectedChofer, setSelectedChofer] = useState(null);
  const [codigoChofer, setCodigoChofer] = useState("");
  const [loading, setLoading] = React.useState(false);
  const id_user = userInfo ? userInfo.id : null;
  const [proceso, setProceso] = useState("");

  useEffect(() => {
    const cargaClientes = async () => {
      try {
        setProceso("RETORNOCLIENTES");
        const dataAll = await obtenerClientesRetiro();

        // Extraer los nombres de los clientes y crear opciones
        const clientOptions = dataAll.map((cliente) => ({
          value: cliente.ID,
          label: cliente.NOMBRE,
        }));
        setOptions(clientOptions);
      } catch (error) {
        console.error("Error fetching dataAll:", error);
        toast.error("Error obteniendo datos. Por favor, inténtalo de nuevo.");
      }
    };
    cargaClientes();
  }, [userInfo]);

  useEffect(() => {
    const fetchChoferes = async () => {
      try {
        let agente; // Declarar agente fuera del bloque if-else para que sea accesible en toda la función
        if (userInfo.iata_agentes === "SCL") {
          agente = "BODEGA SANTIAGO";
        } else {
          agente = userInfo.name;
        }
        const choferesData = await buscarChoferes(agente);
        setChoferes(choferesData);
      } catch (error) {
        console.error("Failed to fetch choferes", error);
      }
    };
    fetchChoferes();
  }, [id_user, userInfo, userInfo.name]);

  const getFecha = () => {
    try {
      const fechaActual = new Date();
      // Convertir a fecha y hora local de Santiago de Chile
      const options = { timeZone: "America/Santiago" };
      const fecha = fechaActual.toLocaleDateString("es-CL", options);
      // Separar los elementos de la fecha
      const [dia, mes, año] = fecha.split("-");
      // Crear la nueva fecha con el año primero
      const nuevaFecha = `${año}-${mes}-${dia}`;
      const hora = fechaActual.toLocaleTimeString("es-CL", options);
      // Concatenar fecha y hora sin coma entre ellos
      const fechaHoraFormateada = `${nuevaFecha} ${hora}`;
      return fechaHoraFormateada;
    } catch (error) {
      console.error("Error al obtener la fecha:", error);
      return null; // O cualquier otro manejo de errores que desees
    }
  };

  const handleInputCliente = async (event, newValue) => {
    try {
      setCodigoCliente(newValue.value);
      setSelectedOption(newValue);
    } catch (error) {}
  };

  const crearManifiesto = async () => {
    setLoading(true);
    if (!codigoCliente) {
      toast.error("Debe seleccionar un cliente antes de continuar!!!");
      setLoading(false);
      return;
    } else if (!codigoChofer) {
      toast.error("Debe seleccionar una chofer antes de continuar!!!");
      setLoading(false);
      return;
    }

    const data = {
      COD_CLIENTE: codigoCliente,
    };
    const dataManifiesto = await getOrdenesManifiestosRutaRetornoCliente(data);
    //Sumamos BULTOS del array dataManifiesto.
    const totalBultos = dataManifiesto.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.BULTOS;
    }, 0);

    if (dataManifiesto.length > 0) {
      const ods = dataManifiesto.map((orden) => orden.OD);

      try {
        const fecha = getFecha();

        const data = {
          FH_SYS: fecha,
          FH_CREACION: fecha,
          FH_CIERRE: fecha,
          COD_USUARIO: id_user,
          PROCESO: proceso,
          ID_CLIENTE: codigoCliente,
          COD_CHOFER: codigoChofer,
          ESTADO: "CERRADO",
          COD_OPERADOR_ENTRADA: codigoChofer,
          COD_CHOFER_ENTRADA: codigoChofer,
          NOMBRE_CHOFER_ENTRADA: userInfo.name,
          SELLOS: "",
          CANTIDAD_VALIJAS: 0,
          TOTAL_BULTOS: totalBultos,
          TIPO_RUTA: "TERRESTRE",
          TIPO_MANIFIESTO: proceso,
          ORIGEN: userInfo.iata_agentes,
          DESTINO: userInfo.iata_agentes,
          NOTA: "",
          FH_MAIL: "",
          RUTA: "SANTIAGO",
        };
        Swal.fire({
          title: "Seguro de Crear Manifiesto?",
          text: `¡Está a punto de crear un nuevo Manifiesto con ${dataManifiesto.length} ordenes!`,
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, Crear!",
        }).then(async (result) => {
          if (result.isConfirmed) {
            const numeroManifiesto = await insertManifiesto(data);

            for (const od of ods) {
              const datosParaEnviar = {
                OD: od,
                ID_MANIFIESTO: numeroManifiesto,
              };

              try {
                await insertPickingManifiestoRutaComunas(datosParaEnviar);
              } catch (error) {
                console.log("Fallo el envio");
              }
            }

            Swal.fire({
              title: "Manifiesto Creado",
              text: "N°: " + numeroManifiesto,
              icon: "success",
            });
            try {
              if (codigoCliente === 463) {
                await downloadManifestPdfBarCode(selectedChofer.label, numeroManifiesto, true);
              } else if (codigoCliente === 632)
              {
                await downloadManifestoRetornoClientes(selectedChofer.label, numeroManifiesto, true);
              }
              else{
                await downloadManifestoRetornoClientes(selectedChofer.label, numeroManifiesto, false);
              }
                
            } catch (error) {
              toast.error("Error al descargar PDF!!!");
              return;
            }
            resetState();
          }
          setLoading(false);
        });
      } catch (error) {
        console.error("Error al crear el manifiesto:", error);
      }
    } else {
      console.log("No se encontraron órdenes.");
      Swal.fire({
        title: "Manifiesto No Creado",
        text: "Cliente no tienen ordenes",
        icon: "error",
      });
      setLoading(false);
    }
  };

  const handleChangeChofer = async (newValue) => {
    setCodigoChofer(newValue.COD_CHOFER);
  };

  const resetState = () => {
    setSelectedOption(null);
    setSelectedChofer(null);
    setCodigoChofer("");
    setCodigoCliente("");
    setLoading(false);
  };

  return (
    <div>
      <Paper style={{ padding: "10px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <Autocomplete
              disablePortal
              size="small"
              id="combo-box-demo1"
              onChange={handleInputCliente}
              options={options}
              value={selectedOption}
              renderInput={(params) => <TextField {...params} label="CLIENTE" />}
            />
          </Grid>
        </Grid>
      </Paper>{" "}
      <div style={{ padding: "10px" }}>
        <Paper style={{ padding: "10px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <Autocomplete
                disableClearable
                id="combo-box-chofer"
                options={choferes}
                getOptionLabel={(option) => `${option.NOMBRE} (ID: ${option.COD_CHOFER})`}
                isOptionEqualToValue={(option, value) => option.COD_CHOFER === value.COD_CHOFER}
                value={selectedChofer}
                onChange={(event, newValue) => {
                  setSelectedChofer(newValue);
                  handleChangeChofer(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Seleccione un Chofer" variant="outlined" fullWidth size="small" />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <Button
                style={{ backgroundColor: "#041562", color: "#FFFFFF" }}
                size="medium"
                variant="contained"
                color="primary"
                onClick={crearManifiesto}
              >
                {loading ? <CircularProgress sx={{ color: "#FFFFFF" }} size={24} /> : "Crear Manifiesto"}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </div>
    </div>
  );
};
export default ManifiestoMasivoRetornoCliente;
