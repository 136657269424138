import React, { useState } from "react";
import { TextField, Container, Grid, Card, IconButton, CardContent, InputAdornment, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { EtiquetaObtener } from "../../services/EtiquetaServices";
import { toast } from "react-toastify";

const ImpresionEtiqueta = () => {
  const [ordenId, setOrdenId] = useState("");
  const [ordenDetails, setOrdenDetails] = useState(null);

  const handleSearch = async () => {
    try {
      const details = await EtiquetaObtener(ordenId);
      setOrdenDetails(details);
      console.log(details);
    } catch (error) {
      toast.error("Error al obtener los detalles de la orden");
      console.error(error);
    }
  };

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6">Buscar Orden</Typography>
              <TextField
                value={ordenId}
                onChange={(e) => setOrdenId(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleSearch}>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </CardContent>
          </Card>
        </Grid>
        {ordenDetails && (
          <Grid item xs={12}>
            <Card>
              <CardContent>
                {/* Mostrar detalles de la orden aquí */}
                <Typography variant="body1">Detalles: {JSON.stringify(ordenDetails)}</Typography>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default ImpresionEtiqueta;
