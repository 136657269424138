import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Modal,
  Box,
} from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { obtenerEstados } from "../../../services/EventosManualServices";
import DeleteIcon from "@mui/icons-material/Delete";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import CircularProgress from "@mui/material/CircularProgress";

const EditModalEventosNuevo = ({ show, onClose, data, onSave }) => {
  const [nombre, setNombre] = useState(data.NOMBRE_RECIBE);
  const [nota, setNota] = useState(data.NOTA);
  const [rut, setRut] = useState(data.RUT);
  const [estado, setEstado] = useState(data.COD_ESTADO);
  const [fhGestion, setFhGestion] = useState(data.FH_GESTION);
  const [estados, setEstados] = useState([]);
  const [foto1, setFoto1] = useState(data.foto1);
  const [foto2, setFoto2] = useState(data.foto2);
  const [foto3, setFoto3] = useState(data.foto3);
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState("");

  const handleOpenImageModal = (image) => {
    setCurrentImage(image);
    setImageModalOpen(true);
  };

  const handleCloseImageModal = () => {
    setImageModalOpen(false);
  };
  useEffect(() => {
    const fetchEstados = async () => {
      try {
        const estadosFetched = await obtenerEstados();
        setEstados(estadosFetched);
        const isValidEstado = estadosFetched.some((estado) => estado.code === data.COD_ESTADO);
        if (isValidEstado) {
          setEstado(data.COD_ESTADO);
        } else {
          setEstado(estadosFetched[0]?.code);
        }
      } catch (error) {
        toast.error("Error al cargar los estados.");
        console.error("Error fetching estados:", error);
      }
    };
    fetchEstados();
  }, [data.COD_ESTADO]);

  const handleImageChange = (event, setImage) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const image = new Image();
        image.src = reader.result;
        image.onload = () => {
          const canvas = document.createElement("canvas");
          const maxW = 800; // Máximo ancho de la imagen
          const maxH = 600; // Máximo alto de la imagen
          let width = image.width;
          let height = image.height;
  
          // Ajustar el tamaño si es necesario
          if (width > height) {
            if (width > maxW) {
              height *= maxW / width;
              width = maxW;
            }
          } else {
            if (height > maxH) {
              width *= maxH / height;
              height = maxH;
            }
          }
  
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(image, 0, 0, width, height);
          const dataUrl = canvas.toDataURL('image/jpeg', 0.85);
  
          setImage(dataUrl.replace(/^data:image\/(png|jpg|jpeg);base64,/, ''));
        };
      };
      reader.readAsDataURL(file);
    }
  };  

  const handleRemoveImage = (setFoto) => {
    setFoto("FALSE");
  };

  const handleSave = () => {
    const updatedData = {
      ...data,
      NOMBRE_RECIBE: nombre,
      RUT: rut,
      COD_ESTADO: estado,
      FH_GESTION: fhGestion,
      NOTA: nota,
      foto1,
      foto2,
      foto3,
    };
    onSave(updatedData);
  };
  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "FH_GESTION") {
      // Extraer las partes de la fecha del formato datetime-local
      const [date, time] = value.split("T");
      const [year, month, day] = date.split("-");
      const [hour, minute] = time.split(":");

      // Reformato a DD/MM/YYYY HH:mm
      const newValue = `${day}/${month}/${year} ${hour}:${minute}`;
      setFhGestion(newValue);
    } else {
      // Asumiendo que hay otros campos que podrían usar esta misma función para manejar cambios
      // Ajusta de acuerdo a tu implementación
    }
  };

  const convertDateToInputFormat = (dateString) => {
    if (!dateString) return "";

    try {
      // Asumiendo que la fecha viene en formato "DD/MM/YYYY HH:mm:ss"
      const [datePart, timePart] = dateString.split(" ");
      const [day, month, year] = datePart.split("/");
      const [hour, minute, second = "00"] = timePart.split(":");

      // Reordenar a formato "YYYY-MM-DDTHH:mm:ss" que es el aceptado por datetime-local
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}T${hour}:${minute}:${second}`;
    } catch (error) {
      console.error("Error al convertir la fecha:", error);
      return ""; // Devolver cadena vacía si hay algún error en la conversión
    }
  };

  const renderImageInput = (foto, setFoto) => (
    <div style={{ display: "inline-block", margin: "10px", verticalAlign: "middle" }}>
      {foto && foto !== "FALSE" ? (
        <div>
          <img
            src={foto.startsWith("data:image") ? foto : `data:image/jpeg;base64,${foto}`}
            alt="Foto"
            style={{ width: "100px", height: "100px", objectFit: "cover", cursor: "pointer" }}
            onClick={() =>
              handleOpenImageModal(foto.startsWith("data:image") ? foto : `data:image/jpeg;base64,${foto}`)
            }
          />
          <IconButton onClick={() => handleRemoveImage(setFoto)}>
            <DeleteIcon style={{ color: "#DA1212", cursor: "pointer" }} />
          </IconButton>
        </div>
      ) : (
        <div style={{ display: "flex", alignItems: "center" }}>
          {foto === "FALSE" && (
            <Button
              variant="contained"
              component="label"
              style={{ backgroundColor: "#041562", width: "100px", height: "100px" }}
            >
              <AddAPhotoIcon style={{ color: "#FFFFFF" }} />
              <input type="file" hidden onChange={(e) => handleImageChange(e, setFoto)} />
            </Button>
          )}
        </div>
      )}
    </div>
  );

  return (
    <Dialog open={show} onClose={onClose}>
      <DialogTitle>Editar Evento</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField fullWidth label="Nombre" value={nombre} onChange={(e) => setNombre(e.target.value)} />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth label="RUT" value={rut} onChange={(e) => setRut(e.target.value)} />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="FH_GESTION"
              type="datetime-local"
              value={convertDateToInputFormat(fhGestion)}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 1,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth label="Nota" value={nota} onChange={(e) => setNota(e.target.value)} />
          </Grid>
          <Grid item xs={12}>
            {estados.length > 0 ? (
              <FormControl fullWidth>
                <InputLabel>Estado</InputLabel>
                <Select value={estado} onChange={(e) => setEstado(e.target.value)} label="Estado">
                  {estados.map((estado) => (
                    <MenuItem key={estado.code} value={estado.code}>
                      {estado.description}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <CircularProgress />
            )}
          </Grid>
          <Grid item xs={12} style={{ display: "flex", justifyContent: "space-around" }}>
            {renderImageInput(foto1, setFoto1)}
            {renderImageInput(foto2, setFoto2)}
            {renderImageInput(foto3, setFoto3)}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button onClick={handleSave}>Guardar</Button>
      </DialogActions>
      {/* Modal para mostrar la imagen en tamaño completo */}
      <Modal open={imageModalOpen} onClose={handleCloseImageModal}>
        <Box
          sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", outline: "none" }}
        >
          <img src={currentImage} alt="Ampliada" style={{ maxHeight: "90vh", maxWidth: "90vw" }} />
        </Box>
      </Modal>
    </Dialog>
  );
};

export default EditModalEventosNuevo;
