import React from 'react';
import logo from '../../components/assets/img/logoCargoex.png';

const Error404 = () => {
  return (
    <div style={{ 
      height: '100vh', 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      flexDirection: 'column', 
      fontFamily: "'Madimi One', sans-serif"
    }}>
      <h1 style={{ 
        fontSize: '5rem', 
        margin: '0', 
        fontFamily: "'Madimi One', sans-serif" 
      }}>404</h1>
      <p style={{ 
        fontSize: '1.5rem', 
        margin: '0', 
        fontFamily: "'Madimi One', sans-serif" 
      }}>Módulo no encontrado</p>
      <p style={{ 
        fontSize: '1rem', 
        fontFamily: "'Madimi One', sans-serif" 
      }}>Por favor, contacte con el equipo de informática. TI@cargoex.cl.</p>

      <img src={logo} alt="Logo" style={{ width: '300px', marginTop: '10px' }} />
    </div>
  );
};

export default Error404;

