
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export function toastCabecera() {
  toast.error("Error en cabecera. Por favor, especificar cabecera valida." );
}

export function toastDatosEnBlanco(COLUMNA, FILA) {
  toast.error(
    `Error en archivo. En la columna ${COLUMNA}, la fila ${FILA} esta vacía`);
}






export function toastCreacionPromesa(totalOrders,setProgress) {
  const createRetiros = (createdOrdersCount) => {
    return new Promise((resolve) => {
      const newProgress = (createdOrdersCount / totalOrders) * 100;
      setProgress(newProgress);

      // Resuelve la Promise cuando se alcanza el 100%
      if (createdOrdersCount === totalOrders) {
        resolve();
      }
    });
  };

  toast.promise(createRetiros, {
    position: toast.POSITION.BOTTOM_RIGHT,
    pending: "Creando Retiros",
    success: "Retiros Creados 👌",
    error: "Error en la creación 🤯",
  });
}

export function toastCreacionPromesa2(ter) {
  const resolveAfter3Sec = new Promise((pro) => setTimeout(pro, ter));

  toast.promise(resolveAfter3Sec, {
    position: toast.POSITION.BOTTOM_RIGHT,
    pending: "Creando Retiros",
    success: "Retiros Creados 👌",
    error: "Error en la creacion 🤯",
  });
} 

export function ordenCreada() {
  toast.success("Retiros creadas con éxito. Retiros creados exitosamente.", {
    position: toast.POSITION.BOTTOM_RIGHT,
  });
}

export function excelCargado() {
  toast.success("Excel cargado", {
    position: toast.POSITION.BOTTOM_RIGHT,
  });
}

export function ordenNoModificada() {
  toast.error(
    "Error de edición. Verificar datos para la edición de retiros");
}

export function ordenModificada() {
  toast.success(
    "Modificacion exitosa. Datos modificados segun excel-");
}

//-------------------------------------------------------------

export function fechaMenor() {
  toast.error(
    "Error de datos. Por favor, fecha no puede ser menor a la actual." );
}
//________________________________________-
