import React, { useState, useEffect } from "react";
import { Container, LinearProgress, Box, Typography } from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { obtenerGraficosGeneral } from "../../services/reportabilidadEspecialService";
import AgentChartFirstHalf from "./AgentChartFirstHalf";
import AgentChartSecondHalf from "./AgentChartSecondHalf";

const ReportabilidadAgentes = () => {
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [firstHalfAgents, setFirstHalfAgents] = useState([]);
  const [secondHalfAgents, setSecondHalfAgents] = useState([]);
  const [totalAgents, setTotalAgents] = useState(0);

  useEffect(() => {
    const cargarDatos = async () => {
      if (!isDataLoaded) {
        let interval = setInterval(() => {
          setLoadingProgress((oldProgress) => {
            if (oldProgress >= 80) {
              clearInterval(interval);
              return oldProgress;
            }
            const diff = Math.random() * 10;
            return Math.min(oldProgress + diff, 80);
          });
        }, 500);
      }

      try {
        const data = await obtenerGraficosGeneral();
        if (!isDataLoaded) {
          setLoadingProgress(100);
          setIsDataLoaded(true);
          /* datos para agentes */
          const agentData = {};
          data.forEach((dato) => {
            const agente = dato.AGENTE_ASIGNADO;
            if (!agentData[agente]) {
              agentData[agente] = { pendientes: 0, enTransito: 0, cerrados: 0, total: 0 };
            }
            // Aplicar la nueva lógica de clasificación
            if (dato.TIENE_ENTREGA === "SI") {
              agentData[agente].cerrados++;
            } else if (dato.TIENE_ENTREGA === "NO" && dato.TIENE_EVENTO === "SI") {
              agentData[agente].enTransito++;
            } else if (dato.TIENE_ENTREGA === "NO" && dato.TIENE_EVENTO === "NO") {
              agentData[agente].pendientes++;
            }
            agentData[agente].total++;
          });

          const agentsList = Object.keys(agentData).map((agent) => ({
            name: agent,
            Pendientes: agentData[agent].pendientes,
            "En Tránsito": agentData[agent].enTransito,
            Cerrados: agentData[agent].cerrados,
          }));

          setTotalAgents(agentsList.length);
          setFirstHalfAgents(agentsList.slice(0, Math.ceil(agentsList.length / 2)));
          setSecondHalfAgents(agentsList.slice(Math.ceil(agentsList.length / 2)));
        }
      } catch (error) {
        console.error("Error al cargar los datos:", error);
        setLoadingProgress(0);
      }
    };

    cargarDatos();
    const intervalId = setInterval(cargarDatos, 60000); // 60000 ms = 1 minuto
    return () => clearInterval(intervalId);
  }, [isDataLoaded]);

  return (
    <Container
      maxWidth="lg"
      style={{
        height: "80vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={{ width: "100%", height: "100%" }}>
        {!isDataLoaded && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <LinearProgress
              variant="determinate"
              value={loadingProgress}
              sx={{ width: "50%", height: 10, borderRadius: 5 }}
            />
            <Typography variant="body2" sx={{ mt: 1 }}>
              Cargando... {loadingProgress.toFixed(0)}%
            </Typography>
          </Box>
        )}
        {isDataLoaded && (
          <Carousel
            showArrows={true}
            emulateTouch={false}
            useKeyboardArrows={true}
            infiniteLoop={true}
            autoPlay={true}
            interval={30000}
            showStatus={false}
            showThumbs={false}
          >
            <div>
              <AgentChartFirstHalf data={firstHalfAgents} totalAgents={totalAgents} />
            </div>
            <div>
              <AgentChartSecondHalf data={secondHalfAgents} totalAgents={totalAgents} />
            </div>
          </Carousel>
        )}
      </Box>
    </Container>
  );
};

export default ReportabilidadAgentes;
