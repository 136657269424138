import React, { memo, useState } from "react";
import ListadoAgentes from "./ListadoAgentes";
import ListadoClientes from "./ListadoClientes";
import ListadoCiudades from "./ListadoCiudades";
import ListadoChoferes from "./ListadoChoferes";
import ListadoEstados from "./ListadoEstados";
import { Box, Button, ButtonGroup, Typography } from "@mui/material";
import { useSelector } from "react-redux";

const Listados = () => {
  const userInfo = useSelector((state) => state.user.userInfo);
  const tipo_usuario = userInfo ? userInfo.tipo_usuario : null;
  const [selectedModule, setSelectedModule] = useState("");

  const renderModule = () => {
    switch (selectedModule) {
      case "agentes":
        return <ListadoAgentes />;
      case "clientes":
        return <ListadoClientes />;
      case "ciudades":
        return <ListadoCiudades />;
      case "choferes":
        return <ListadoChoferes />;
        case "estados":
          return <ListadoEstados />;
      default:
        return <Typography variant="h6">Seleccione un módulo para mostrar</Typography>;
    }
  };

  return (
    <Box p={3}>
      <ButtonGroup variant="outlined" color="primary" aria-label="outlined primary button group">
        <Button
          onClick={() => setSelectedModule("agentes")}
          sx={{
            backgroundColor: selectedModule === "agentes" ? "#041562" : undefined,
            color: selectedModule === "agentes" ? "#fff" : undefined,
            "&:hover": {
              backgroundColor: selectedModule === "agentes" ? "#DA251C" : undefined,
            },
          }}
        >
          Agentes
        </Button>
        {tipo_usuario !== 'OPERACIONES' && (
          <Button
            onClick={() => setSelectedModule("clientes")}
            sx={{
              backgroundColor: selectedModule === "clientes" ? "#041562" : undefined,
              color: selectedModule === "clientes" ? "#fff" : undefined,
              "&:hover": {
                backgroundColor: selectedModule === "clientes" ? "#DA251C" : undefined,
              },
            }}
          >
            Clientes
          </Button>
        )}
        <Button
          onClick={() => setSelectedModule("ciudades")}
          sx={{
            backgroundColor: selectedModule === "ciudades" ? "#041562" : undefined,
            color: selectedModule === "ciudades" ? "#fff" : undefined,
            "&:hover": {
              backgroundColor: selectedModule === "ciudades" ? "#DA251C" : undefined,
            },
          }}
        >
          Ciudades
        </Button>
        <Button
          onClick={() => setSelectedModule("choferes")}
          sx={{
            backgroundColor: selectedModule === "choferes" ? "#041562" : undefined,
            color: selectedModule === "choferes" ? "#fff" : undefined,
            "&:hover": {
              backgroundColor: selectedModule === "choferes" ? "#DA251C" : undefined,
            },
          }}
        >
          Choferes
        </Button>
        <Button
          onClick={() => setSelectedModule("estados")}
          sx={{
            backgroundColor: selectedModule === "estados" ? "#041562" : undefined,
            color: selectedModule === "estados" ? "#fff" : undefined,
            "&:hover": {
              backgroundColor: selectedModule === "estados" ? "#DA251C" : undefined,
            },
          }}
        >
          Estados
        </Button>
      </ButtonGroup>
      <Box mt={3}>{renderModule()}</Box>
    </Box>
  );
};

export default memo(Listados);
