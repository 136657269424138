import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function RedirectToInitialPage() {
  const navigate = useNavigate();
  const tipoUsuario = useSelector((state) => state.user.userInfo.tipo_usuario);

  useEffect(() => {
    const path = window.location.pathname;
    const basePath = path.split("/")[1];

    switch (tipoUsuario) {
      case "AGENTE":
        if (basePath !== "inicio-agente") navigate("/inicio-agente");
        break;
      case "SAC":
        if (basePath !== "inicio-sac") navigate("/inicio-sac");
        break;
      case "SUPERVISORA":
        if (basePath !== "inicio-supervisora") navigate("/inicio-supervisora");
        break;
      case "FACTURACION":
        if (basePath !== "inicio-facturacion") navigate("/inicio-facturacion");
        break;
      case "OPERACIONES":
        if (basePath !== "inicio-operaciones") navigate("/inicio-operaciones");
        break;
      case "SUPERVISOR":
        if (basePath !== "inicio-operaciones") navigate("/inicio-operaciones");
        break;
      case "CLIENTE":
        if (basePath !== "inicio-clientes") navigate("/inicio-clientes");
        break;
      case "ADMIN":
        if (basePath !== "inicio-administracion") navigate("/inicio-administracion");
        break;
      case "GRAFICOS":
        if (basePath !== "inicio-graficos") navigate("/inicio-graficos");
        break;
      default:
        if (basePath !== "login") navigate("/login");
    }
  }, [tipoUsuario, navigate]);

  return null;
}
export default RedirectToInitialPage;
