import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getOrdenesManifiestosRuta } from "../../../services/ManifiestoRutaPorOdServices";
import { CircularProgress, Button, Paper, Checkbox, Grid, Autocomplete, TextField, IconButton } from "@mui/material";
//import { buscarChoferes } from "../../../services/ManifiestoComunaServices";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { toast } from "react-toastify";
import {
  insertPickingTemp,
  deletePickingTemp,
  insertManifiesto,
  insertPicking,
  downloadManifestPdfManifiestoRuta,
  buscarDatosPickingTempManifiestoRuta,
  buscarChoferesManifiestoRuta,
} from "../../../services/ManifiestosServices";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";

const ManifiestoRutaPorOd = () => {
  const userInfo = useSelector((state) => state.user.userInfo);
  const id_user = userInfo ? userInfo.id : null;
  const [ordenes, setOrdenes] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = React.useState(false);
  const [choferes, setChoferes] = useState([]);
  const [codigoChofer, setCodigoChofer] = useState("");
  const [selectedChofer, setSelectedChofer] = useState(null);
  const [loadinCargandoOrdenes, setLoadingCargandoOrdenes] = useState(false);
  const [selectedItems, setSelectedItems] = useState(new Set());
  const [dataTabla, setDataTabla] = useState([]);
  const [dataTablaCarga, setDataTablaCarga] = useState([]);
  const [totalBultos, setTotalBultos] = useState(0);
  const [selectedPeoneta, setSelectedPeoneta] = useState(null);
  const [idPeoneta, setIdPeoneta] = useState("");
  const [peonetas, setPeonetas] = useState([]);

  const getFecha = () => {
    try {
      const fechaActual = new Date();
      // Convertir a fecha y hora local de Santiago de Chile
      const options = { timeZone: "America/Santiago" };
      const fecha = fechaActual.toLocaleDateString("es-CL", options);
      // Separar los elementos de la fecha
      const [dia, mes, año] = fecha.split("-");
      // Crear la nueva fecha con el año primero
      const nuevaFecha = `${año}-${mes}-${dia}`;
      const hora = fechaActual.toLocaleTimeString("es-CL", options);
      // Concatenar fecha y hora sin coma entre ellos
      const fechaHoraFormateada = `${nuevaFecha} ${hora}`;
      return fechaHoraFormateada;
    } catch (error) {
      console.error("Error al obtener la fecha:", error);
      return null; // O cualquier otro manejo de errores que desees
    }
  };

  const formatearFecha = (fecha) => {
    const date = new Date(fecha);
    const dia = date.getDate().toString().padStart(2, "0");
    const mes = (date.getMonth() + 1).toString().padStart(2, "0"); // Los meses van de 0 a 11
    const año = date.getFullYear();
    return `${dia}-${mes}-${año}`;
  };

  useEffect(() => {
    //console.log(userInfo);
    //getFecha();
    const fetchOrdenes = async () => {
      try {
        let agente; // Declarar agente fuera del bloque if-else para que sea accesible en toda la función
        if (userInfo.iata_agentes === "SCL") {
          agente = "BODEGA SANTIAGO";
        } else {
          agente = userInfo.name;
        }

        const datos = {
          AGENTE_ASIGNADO: agente,
          COMUNA: userInfo.iata_agentes,
        };
        const OrdenesData = await getOrdenesManifiestosRuta(datos);
        setDataTabla(OrdenesData);
        setOrdenes(OrdenesData);
        setLoadingData(false);
      } catch (error) {
        console.error("Failed to fetch ordenes", error);
        setLoadingData(false);
      }
    };
    if (id_user) {
      fetchOrdenes();
    }
  }, [id_user, userInfo]);

  useEffect(() => {
    const fetchChoferes = async () => {
      try {
        // let agente; // Declarar agente fuera del bloque if-else para que sea accesible en toda la función
        /*   if (userInfo.iata_agentes === "SCL") {
          agente = "BODEGA SANTIAGO";
        } else {
          agente = userInfo.name;
        } */
        const choferesData = await buscarChoferesManifiestoRuta(id_user);
        setChoferes(choferesData);
        const choferesOptions = choferesData.map((choferes) => ({
          value: choferes.COD_CHOFER,
          label: choferes.NOMBRE,
        }));
        setPeonetas(choferesOptions);
      } catch (error) {
        console.error("Failed to fetch choferes", error);
      }
    };

    fetchChoferes();
  }, [id_user, userInfo.iata_agentes, userInfo.name]);

  const handleChangeChofer = async (newValue) => {
    setCodigoChofer(newValue ? newValue.COD_CHOFER : "");

    const datos = {
      COD_USUARIO: id_user,
      COD_CHOFER: newValue.COD_CHOFER,
      PROCESO: "SALIDARUTAOD",
    };
    const dataPickingTemp = await buscarDatosPickingTempManifiestoRuta(datos);

    let totalBultosTemp = 0;
    if (dataPickingTemp && dataPickingTemp.results) {
      dataPickingTemp.results.forEach((orden) => {
        totalBultosTemp += orden.BULTOS;
      });
      setDataTablaCarga(dataPickingTemp.results.length);
    }

    setTotalBultos(totalBultosTemp);

    if (dataPickingTemp && dataPickingTemp.results) {
      setDataTablaCarga(dataPickingTemp.results);
    } else {
      setDataTablaCarga([]);
    }
  };

  const handleChangePeoneta = (event, newValue) => {
    if (newValue === null) {
      setSelectedPeoneta(null);
      setIdPeoneta("");
    } else {
      setSelectedPeoneta(newValue);
      setIdPeoneta(newValue.value);
    }
  };

  const handleDeleteButtonClick = async (orden) => {
    try {
      /* const response =  */ await deletePickingTemp({
        OD: orden,
        COD_USUARIO: id_user,
        PROCESO: "SALIDARUTAOD",
      });
      setDataTablaCarga((prevData) => prevData.filter((item) => item.OD !== orden));

      let agente; // Declarar agente fuera del bloque if-else para que sea accesible en toda la función
      if (userInfo.iata_agentes === "SCL") {
        agente = "BODEGA SANTIAGO";
      } else {
        agente = userInfo.name;
      }

      const datos = {
        AGENTE_ASIGNADO: agente,
        COMUNA: userInfo.iata_agentes,
      };
      const OrdenesData = await getOrdenesManifiestosRuta(datos);
      setDataTabla(OrdenesData);
      setOrdenes(OrdenesData);
    } catch (error) {}
  };

  const crearManifiesto = async () => {
    if (codigoChofer === idPeoneta) {
      toast.error("Chofer y peoneta no deben ser los mismos");
      return;
    }
    try {
      const fecha = getFecha();

      const data = {
        FH_SYS: fecha,
        FH_CREACION: fecha,
        FH_CIERRE: fecha,
        COD_USUARIO: id_user,
        PROCESO: "SALIDARUTA",
        ID_CLIENTE: 0,
        COD_CHOFER: codigoChofer,
        ESTADO: "CERRADO",
        COD_OPERADOR_ENTRADA: codigoChofer,
        COD_CHOFER_ENTRADA: codigoChofer,
        NOMBRE_CHOFER_ENTRADA: userInfo.name,
        SELLOS: "",
        CANTIDAD_VALIJAS: 0,
        TOTAL_BULTOS: totalBultos,
        TIPO_RUTA: "TERRESTRE",
        TIPO_MANIFIESTO: "SALIDARUTA",
        ORIGEN: userInfo.iata_agentes,
        DESTINO: userInfo.iata_agentes,
        NOTA: "",
        FH_MAIL: "",
        RUTA: userInfo.nombre_ciudad_agentes,
        COD_PEONETA: idPeoneta,
      };

      const response = await insertManifiesto(data);

      const datosParaEnviar = {
        COD_USUARIO: id_user,
        TIPO_MANIFIESTO: "SALIDARUTAOD",
        DESTINO: userInfo.iata_agentes,
        ID_MANIFIESTO: response,
      };

      try {
        await insertPicking(datosParaEnviar);
      } catch (error) {
        console.log("Fallo el envio");
      }
      return response;
    } catch (error) {
      console.error("Error al crear el manifiesto:", error);
    }
  };

  const handleCrearManifiesto = async () => {
    setLoading(true);
    if (!codigoChofer) {
      toast.error("Debe seleccionar un chofer antes de continuar!!!");
      setLoading(false);
      return;
    } else if (dataTablaCarga.length === 0) {
      toast.error("Manifiestos debe contener una orden!!!");
      setLoading(false);
      return;
    } else {
      Swal.fire({
        title: "Seguro de Crear Manifiesto?",
        text: "Esta a punto de crear un nuevo Manifiesto!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, Crear!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          //setLoading(false);
          const numeroManifiesto = await crearManifiesto();
          Swal.fire({
            title: "Manifiesto Creado",
            text: "N°: " + numeroManifiesto,
            icon: "success",
          });
          try {
            await downloadManifestPdfManifiestoRuta(selectedChofer.NOMBRE, numeroManifiesto);
          } catch (error) {
            toast.error("Error al descargar PDF!!!");
            return;
          }
          resetState();
        }
        setLoading(false);
      });
    }
  };

  const cargarOrdenesSeleccionadas = async () => {
    if (!codigoChofer) {
      toast.error("Seleccione un chofer antes de continuar");
      return;
    }
    setLoadingCargandoOrdenes(true);
    setLoadingData(true);

    const fecha = getFecha();

    try {
      // Obtener los datos de las filas seleccionadas
      const selectedData = ordenes.filter((item) => selectedItems.has(item.OD));
      // Mostrar por consola los datos seleccionados

      // Hacer el insert por cada elemento seleccionado
      for (const item of selectedData) {
        await insertPickingTemp({
          FH_SYS: fecha,
          OD: item.OD,
          COD_USUARIO: id_user,
          COD_CHOFER: codigoChofer,
          COMUNA: userInfo.iata_agentes,
          PROCESO: "SALIDARUTAOD",
        });
        setTotalBultos((prevTotal) => prevTotal + item.BULTOS);
      }

      // Agregar los OD de los datos seleccionados al estado actual
      setDataTablaCarga((prevData) => [...prevData, ...selectedData]);

      let agente; // Declarar agente fuera del bloque if-else para que sea accesible en toda la función
      if (userInfo.iata_agentes === "SCL") {
        agente = "BODEGA SANTIAGO";
      } else {
        agente = userInfo.name;
      }

      //Obtenemos las ordenes de la tabla 1 sin las ordenes seleccionadas anteriormente
      const datos = {
        AGENTE_ASIGNADO: agente,
        COMUNA: userInfo.iata_agentes,
      };
      const OrdenesData = await getOrdenesManifiestosRuta(datos);
      setDataTabla(OrdenesData);
      setOrdenes(OrdenesData);
      setLoadingData(false);
    } catch (error) {
      console.error("Error al insertar los datos:", error);
      // Manejo del error, si es necesario
    } finally {
      setLoadingCargandoOrdenes(false);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelectedItems = new Set(dataTabla.map((item) => item.OD));
      setSelectedItems(newSelectedItems);
      return;
    }
    setSelectedItems(new Set());
  };

  const handleCheck = (id) => {
    setSelectedItems((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(id)) {
        newSet.delete(id);
      } else {
        newSet.add(id);
      }
      return newSet;
    });
  };

  const resetState = () => {
    setSelectedChofer(null);
    setSelectedPeoneta(null);
    setLoading(false);
    setDataTablaCarga([]);
    setCodigoChofer("");
    setTotalBultos(0);
  };

  return (
    <div>
      <Paper style={{ padding: "10px" }}>
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Autocomplete
                disableClearable
                id="combo-box-chofer"
                options={choferes}
                getOptionLabel={(option) => `${option.NOMBRE} (ID: ${option.COD_CHOFER})`}
                isOptionEqualToValue={(option, value) => option.COD_CHOFER === value.COD_CHOFER}
                value={selectedChofer}
                onChange={(event, newValue) => {
                  setSelectedChofer(newValue);
                  handleChangeChofer(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Seleccione un Chofer"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    size="small"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Autocomplete
                //disableClearable
                value={selectedPeoneta}
                disablePortal
                id="size-small-outlined-multi"
                size="small"
                options={peonetas}
                onChange={handleChangePeoneta}
                sx={{ width: "100%" }}
                PaperComponent={({ children }) => (
                  <Paper
                    sx={{
                      width: "100%",
                      textAlign: "left",
                      "& li": { textAlign: "left" },
                    }}
                  >
                    {children}
                  </Paper>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Seleccione un peoneta"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    size="small"
                  />
                )}
                isOptionEqualToValue={(option, value) => option.value === value.value}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Button
                sx={{
                  width: "100%",
                  backgroundColor: "#041562",
                  color: "#FFFFFF",
                  "&:hover": {
                    backgroundColor: "#031042",
                  },
                  mt: 1,
                }}
                onClick={handleCrearManifiesto}
                variant="contained"
                color="primary"
                size="large"
              >
                {loading ? <CircularProgress sx={{ color: "#FFFFFF" }} size={24} /> : "Crear Manifiesto"}
              </Button>
            </Grid>

            <Grid item xs={12} sm={8} md={8} lg={8} style={{ marginTop: "10px", marginBottom: "10px" }}>
              {loadingData ? ( // Mostrar CircularProgress mientras se cargan los datos
                <CircularProgress
                  size={50}
                  sx={{ position: "relative", top: "5%", left: "50%", marginTop: 2, marginLeft: -12 }}
                />
              ) : (
                <TableContainer component={Paper} sx={{ maxHeight: 500, overflow: "auto", position: "relative" }}>
                  <Table stickyHeader sx={{ minWidth: 500 }} size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell padding="checkbox">
                          <Checkbox
                            indeterminate={selectedItems.size > 0 && selectedItems.size < dataTabla.length}
                            checked={dataTabla.length > 0 && selectedItems.size === dataTabla.length}
                            onChange={handleSelectAllClick}
                            edge="end"
                          />
                        </TableCell>
                        <TableCell sx={{ width: "10%" }}>OD</TableCell>
                        <TableCell align="right" sx={{ width: "30%" }}>
                          CLIENTE
                        </TableCell>
                        <TableCell align="right" sx={{ width: "30%" }}>
                          ORIGEN
                        </TableCell>
                        <TableCell align="right" sx={{ width: "30%" }}>
                          DIRECCION
                        </TableCell>
                        <TableCell align="right" sx={{ width: "15%" }}>
                          FECHA
                        </TableCell>
                        <TableCell align="right" sx={{ width: "15%" }}>
                          COMUNA
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {dataTabla.map((item) => (
                        <TableRow key={item.OD} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                          <TableCell padding="checkbox">
                            <Checkbox
                              edge="end"
                              onChange={() => handleCheck(item.OD)}
                              checked={selectedItems.has(item.OD)}
                            />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {item.OD}
                          </TableCell>
                          <TableCell align="right">{item.NOMBRE_CLIENTE}</TableCell>
                          <TableCell align="right">{item.NOMBRE_GESTION}</TableCell>
                          <TableCell align="right">{item.DIRECCION_GESTION}</TableCell>
                          <TableCell align="right">{formatearFecha(item.FH_DIGITACION)}</TableCell>
                          <TableCell align="right">{item.NOMBRE_COMUNA}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4} style={{ marginTop: "10px", marginBottom: "10px" }}>
              {loadingData ? ( // Mostrar CircularProgress mientras se cargan los datos
                <CircularProgress
                  size={50}
                  sx={{ position: "relative", top: "5%", left: "50%", marginTop: 2, marginLeft: -12 }}
                />
              ) : (
                <TableContainer component={Paper} sx={{ maxHeight: 500, overflow: "auto", position: "relative" }}>
                  <Table stickyHeader sx={{ minWidth: 300 }} size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Eliminar</TableCell>
                        <TableCell>OD</TableCell>
                        <TableCell align="right">COMUNA</TableCell>
                        <TableCell align="right">DESTINO</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {dataTablaCarga.map((item) => (
                        <TableRow
                          key={item.OD}
                          sx={{ "&:last-child td, &:last-child th": { border: 0 }, maxHeight: 30 }}
                        >
                          <TableCell>
                            <IconButton style={{ color: "#DA251C" }} onClick={() => handleDeleteButtonClick(item.OD)}>
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {item.OD}
                          </TableCell>
                          <TableCell align="right">{item.NOMBRE_COMUNA}</TableCell>
                          <TableCell align="right">{item.NOMBRE_GESTION}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={8} md={8} lg={8} style={{ marginTop: "10px", marginBottom: "10px" }}>
              <Button
                sx={{
                  backgroundColor: "#041562",
                  color: "#FFFFFF",
                  "&:hover": {
                    backgroundColor: "#031042",
                  },
                  mt: 2,
                }}
                onClick={cargarOrdenesSeleccionadas}
                variant="contained"
                color="primary"
                fullWidth
              >
                {loadinCargandoOrdenes ? (
                  <CircularProgress sx={{ color: "#FFFFFF" }} size={24} />
                ) : (
                  "Cargar Ordenes Seleccionadas"
                )}
              </Button>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4} style={{ marginTop: "10px", marginBottom: "10px" }}></Grid>
          </Grid>
        </form>
      </Paper>
    </div>
  );
};

export default ManifiestoRutaPorOd;
