import axios from "axios";

const BASE_API_URL = process.env.REACT_APP_BACKEND_URL;

const validarToken = async (token) => {
  try {
    const respuesta = await axios.post(
      `${BASE_API_URL}login/validarToken`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (respuesta.status === 200) {
      return { exito: true, datos: respuesta.data };
    } else {
      return {
        exito: false,
        error: respuesta.data.error || "Error al validar el token.",
      };
    }
  } catch (error) {
    return { exito: false, error: error.message || "Error al conectarse al servidor." };
  }
};

export default validarToken;
