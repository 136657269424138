import React, { useState, memo, useEffect } from "react";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { Button, Container, Grid } from "@mui/material";
import { TextField, Paper } from "@mui/material";
import { obtenerClientesControlSac, ObtenerConsultaCedibles } from "../../services/ReportesServices";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";
import ReusableTableConsultasCedibles from "../../components/ReportesComponentes/componentesConsultasCedibles/ReusableTableConsultasCedibles";
import { es } from "date-fns/locale";

const ConsultaCedibles = () => {
  /* accedemos al id de usuario */
  const userInfo = useSelector((state) => state.user.userInfo);
  // eslint-disable-next-line no-unused-vars
  const id_usuario = userInfo ? userInfo.id : null;

  const [dataTabla, setDataTabla] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [fechaDesde, setFechaDesde] = useState(null);
  const [fechaHasta, setFechaHasta] = useState(null);
  const [isLoadingConsulta, setIsLoadingConsulta] = useState(false);

  const columns = [
    { field: "OD_PAPEL", headerName: "OD", width: 110 },
    { field: "GUIA", headerName: "Guia", width: 110 },
    {
      field: "FH_DIGITACION",
      headerName: "Fecha digitacion",
      width: 150,
      renderCell: (params) => formatDate(params.value),
    },
    { field: "CLIENTE", headerName: "Cliente", width: 250 },
    { field: "COMUNA_ORIGEN", headerName: "Origen", width: 110 },
    { field: "COMUNA", headerName: "Destino", width: 110 },
    { field: "COD_MANIFIESTO", headerName: "Codigo manifiesto", width: 170 },
    {
      field: "FH_MANIFIESTO",
      headerName: "Fecha de manifiesto",
      width: 110,
      renderCell: (params) => formatDate(params.value),
    },
  ];

  /* formatear fecha de tabla */
  const formatDate = (isoString) => {
    const date = new Date(isoString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const formatFechaToYYYYMMDD = (date) => {
    return date.toISOString().split("T")[0];
  };

  const ConsultaPeriodo = async () => {
    setIsLoadingConsulta(true);
    try {
      const formattedFechaDesde = fechaDesde ? formatFechaToYYYYMMDD(fechaDesde) : null;
      const formattedFechaHasta = fechaHasta ? formatFechaToYYYYMMDD(fechaHasta) : null;
      const filtros = {
        fechaDesde: formattedFechaDesde,
        fechaHasta: formattedFechaHasta,
        clienteId: selectedClient?.id,
      };
      const response = await ObtenerConsultaCedibles(filtros);
      if (response && Array.isArray(response)) {
        const datosProcesados = response.map((fila) => ({
          ...fila,
          PESO_VOLUMEN: Number(fila.PESO_VOLUMEN).toFixed(2),
          KG_VALORADO: Math.max(fila.PESO, Number(fila.PESO_VOLUMEN)),
        }));

        setDataTabla(datosProcesados);
      } else {
        toast.error("Formato de respuesta inesperado del servidor");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error al realizar la consulta");
    } finally {
      setIsLoadingConsulta(false);
    }
  };

  useEffect(() => {
    const fetchClientes = async () => {
      try {
        const response = await obtenerClientesControlSac();
        setClientes(response);
      } catch (error) {
        console.error("Error al obtener la lista de clientes:", error);
      }
    };
    fetchClientes();
  }, []);

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
        <Container maxWidth="lg">
          {/* Filters Section */}
          <Paper style={{ padding: "10px" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={2}>
                <DesktopDatePicker
                  label="Fecha inicio"
                  inputFormat="dd/MM/yyyy"
                  value={fechaDesde}
                  onChange={(newDate) => setFechaDesde(newDate)}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      margin: "dense",
                      variant: "outlined",
                      size: "small",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <DesktopDatePicker
                  label="Fecha Termino"
                  inputFormat="dd/MM/yyyy"
                  value={fechaHasta}
                  onChange={(newDate) => setFechaHasta(newDate)}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      margin: "dense",
                      variant: "outlined",
                      size: "small",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Autocomplete
                  id="combo-box-cliente"
                  options={clientes.map((cliente) => ({
                    id: cliente.ID,
                    nombre: cliente.NOMBRE + " (ID: " + cliente.ID + ")",
                  }))}
                  getOptionLabel={(option) => option.nombre}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  value={selectedClient}
                  onChange={(event, newValue) => {
                    setSelectedClient(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Nombre Cliente"
                      variant="outlined"
                      fullWidth
                      margin="dense"
                      size="small"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Button
                  sx={{
                    backgroundColor: "#041562",
                    color: "#FFFFFF",
                    "&:hover": {
                      backgroundColor: "#031042",
                    },
                    marginTop: 1,
                  }}
                  onClick={() => ConsultaPeriodo(true)}
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  {isLoadingConsulta ? <CircularProgress sx={{ color: "#FFFFFF" }} size={24} /> : "Buscar"}
                </Button>
              </Grid>
            </Grid>
          </Paper>
          {/* Table Section */}
          {dataTabla.length > 0 && (
            <ReusableTableConsultasCedibles
              columns={columns}
              rows={dataTabla}
              loading={isLoadingConsulta}
              searchField="OD"
            />
          )}
        </Container>
      </LocalizationProvider>
    </>
  );
};

export default memo(ConsultaCedibles);

