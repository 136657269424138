import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { obtenerComunas } from "../../../services/EventosManualServices";
import Autocomplete from "@mui/material/Autocomplete";

const EditModalClientes = ({ open, onClose, cliente, onSave }) => {
  const [formData, setFormData] = useState(cliente);
  const [comunas, setComunas] = useState([]);
  const [comunaSeleccionada, setComunaSeleccionada] = useState(null);

  useEffect(() => {
    setFormData(cliente);
    if (cliente && comunas.length > 0) {
      const matchedComuna = comunas.find(c => c.IATA === cliente.IATA);
      setComunaSeleccionada(matchedComuna);
    }
  }, [cliente, comunas]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    const cargarComunas = async () => {
      try {
        const comunasObtenidas = await obtenerComunas();
        setComunas(comunasObtenidas);
      } catch (error) {
        console.error("Error al obtener comunas:", error);
      }
    };
    cargarComunas();
  }, []);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Editar Cliente</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Nombre"
          type="text"
          fullWidth
          variant="outlined"
          name="NOMBRE"
          value={formData.NOMBRE || ""}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          label="Rut"
          type="text"
          fullWidth
          variant="outlined"
          name="RUT"
          value={formData.RUT || ""}
          onChange={handleChange}
        />
        <Autocomplete
          id="combo-box-comuna"
          options={comunas}
          getOptionLabel={(option) => option.NOMBRE}
          isOptionEqualToValue={(option, value) => option.IATA === value.IATA}
          value={comunaSeleccionada}
          onChange={(event, newValue) => {
            setComunaSeleccionada(newValue);
            setFormData((prev) => ({
              ...prev,
              COMUNA: newValue ? newValue.NOMBRE : "",
              IATA: newValue ? newValue.IATA : "",
            }));
          }}
          renderInput={(params) => (
            <TextField {...params} label="Seleccione comuna y iata" variant="outlined" fullWidth margin="dense" />
          )}
        />
        <TextField
          margin="dense"
          label="Direccion"
          type="text"
          fullWidth
          variant="outlined"
          name="DIRECCION"
          value={formData.DIRECCION || ""}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          label="Email"
          type="text"
          fullWidth
          variant="outlined"
          name="CORREO"
          value={formData.CORREO || ""}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          label="Contacto comercial"
          type="text"
          fullWidth
          variant="outlined"
          name="CONTACTO_COMERCIAL"
          value={formData.CONTACTO_COMERCIAL || ""}
          onChange={handleChange}
        />
        <FormControl style={{ marginTop: "5px" }} fullWidth>
          <InputLabel>Estado</InputLabel>
          <Select label="Estado" name="ESTADO" value={formData.ESTADO || ""} onChange={handleChange} variant="outlined">
            <MenuItem value="ACTIVO">ACTIVO</MenuItem>
            <MenuItem value="INACTIVO">INACTIVO</MenuItem>
          </Select>
        </FormControl>
        <TextField
          margin="dense"
          label="Fecha de ingreso"
          type="date"
          fullWidth
          variant="outlined"
          name="FECHA_INGRESO"
          value={formData.FECHA_INGRESO || ""}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          margin="dense"
          label="Telefono"
          type="text"
          fullWidth
          variant="outlined"
          name="FONO"
          value={formData.FONO || ""}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          label="Giro"
          type="text"
          fullWidth
          variant="outlined"
          name="GIRO"
          value={formData.GIRO || ""}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button onClick={() => onSave(formData)}>Guardar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditModalClientes;
