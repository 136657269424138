import axios from "axios";
/* Usa la variable de entorno para la URL base */
const BASE_API_URL = process.env.REACT_APP_BACKEND_URL;
const API_KEY = "55IcsddHxiy2E3q653RpYtb";
const BASE_API_URL_CORREOS = "https://app.cargoex.cl/app/cargoex/app/";

/* obtenerListaCorreos */
export const fetchCorreos = async () => {
  try {
    const response = await axios.get(`${BASE_API_URL}mail/gestorMail`);
    return response.data;
  } catch (error) {
    console.error("Error al obtener correos:", error);
    throw error;
  }
};

export async function reenviarCorreo(correoInfo) {
  try {
    const response = await axios.post(
      `${BASE_API_URL}mail/reenviarCorreo`,
      {
        correo: correoInfo.destinatario,
        asunto: correoInfo.asunto,
        cuerpo: correoInfo.cuerpo,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data; // Devuelve los datos de la respuesta
  } catch (error) {
    // Es una buena práctica manejar los errores específicos de axios
    if (error.response) {
      // La solicitud se hizo y el servidor respondió con un estado de código
      // que cae fuera del rango de 2xx
      console.error(error.response.data);
      console.error(error.response.status);
      console.error(error.response.headers);
      throw new Error(error.response.data.message || "Error al reenviar el correo");
    } else if (error.request) {
      // La solicitud se hizo pero no se recibió respuesta
      console.error(error.request);
      throw new Error("No se recibió respuesta al reenviar el correo");
    } else {
      // Algo sucedió al configurar la solicitud que desencadenó un Error
      console.error("Error", error.message);
      throw new Error("Error al configurar la solicitud de reenvío de correo");
    }
  }
}

export async function InformarCorreo(ID) {
  const postEmail = "gestorMailEnviado";
  try {
    await axios.post(
      `${BASE_API_URL_CORREOS}${postEmail}`,
      { ID: ID },
      {
        headers: {
          "Content-Type": "application/json",
          "X-API-KEY": API_KEY,
        },
      }
    );
  } catch (error) {
    console.error("Error enviando correo:", error);
    throw error;
  }
}
