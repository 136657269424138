import { insertMailGestor } from "../../../services/ManifiestosServices";

async function Mail_Gestor_Salida({ proceso, correoAgente, numeroManifiesto }) {
  try {

    const cuerpo = `https://backend.cargoexteam.cl/medicion/creacionManifiestoPDF/${numeroManifiesto}`;
    const asunto = `MANIFIESTO SALIDA ${proceso} N° ${numeroManifiesto}`;
    const datos =await insertMailGestor({
      PROCESO: proceso,
      DESTINATARIO: correoAgente,
      ASUNTO: asunto,
      CUERPO: cuerpo
    });
    console.log("Datos para el Mail: ",datos);
  } catch (error) {
    console.error("Error:", error);
  }
}

export default Mail_Gestor_Salida;
