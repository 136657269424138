import axios from "axios";
/* Usa la variable de entorno para la URL base */
const BASE_API_URL = process.env.REACT_APP_BACKEND_URL;

const apiClient = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const generarPDFManifiesto = async (codManifiesto) => {
  try {
    const response = await axios.get(`${BASE_API_URL}rendiciones/generarPDFManifiesto/${codManifiesto}`, {
      responseType: "blob",
    });
    const url = window.URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `Manifiesto_${codManifiesto}.pdf`);
    document.body.appendChild(link);
    link.click();

    link.parentNode.removeChild(link);
    window.URL.revokeObjectURL(url);
    return { success: true, message: "PDF generado y descargado con éxito." };
  } catch (error) {
    console.error("Error al generar el PDF: ", error);
    throw error;
  }
};

export const generarEXCLManifiesto = async (codManifiesto) => {
  try {
    const response = await axios.get(`${BASE_API_URL}rendiciones/generarEXCLManifiesto/${codManifiesto}`, {
      responseType: "blob",
    });
    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `Manifiesto_${codManifiesto}.xlsx`);
    document.body.appendChild(link);
    link.click();

    link.parentNode.removeChild(link);
    window.URL.revokeObjectURL(url);
    return { success: true, message: "Excel generado y descargado con éxito." };
  } catch (error) {
    console.error("Error al generar el Excel:", error);
    throw error;
  }
};

export const obtenerRendicionesPorFecha = async (fecha, codCliente) => {
  try {
    const response = await apiClient.post("rendiciones/obtenerRendicionesPorFecha", { fecha, codCliente });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const obtenerRendicionesPorGuia = async (guia, cod_cliente, canal) => {
  try {
    const response = await apiClient.post("rendiciones/obtenerRendicionesPorGuia", { guia, cod_cliente, canal });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const obtenerRendicionesPorOrden = async (od, cod_cliente, canal) => {
  try {
    const response = await apiClient.post("rendiciones/obtenerRendicionesPorOrden", { od, cod_cliente, canal });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const obtenerRendicionesPorCanal = async (codCliente, canal) => {
  try {
    const response = await apiClient.post("rendiciones/obtenerRendicionesPorCanal", { codCliente, canal });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Modificar el servicio para enviar múltiples registros
export const obtenerGuiasParaRendir = async (guias, fecha) => {
  try {
      const response = await apiClient.post("rendiciones/insertarRendicionDetalle", guias.map(guia => ({
          od: guia.OD_PAPEL,
          fhManifesto: fecha,
          guia: guia.ID_REFERENCIA,
          codCliente: guia.COD_CLIENTE
      })));
      return response.data;
  } catch (error) {
      throw error;
  }
};

export const borrarGuiaPendiente = async (data) => {
  try {
    const response = await axios.post(`${BASE_API_URL}rendiciones/borrarGuiaPendiente`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error delete PickingTemp:", error);
    throw error;
  }
};

export const cerrarManifiesto = async (fecha, codCliente) => {
  try {
    const response = await apiClient.post("rendiciones/cerrarManifiesto", { fecha, codCliente });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const buscarCanalRendiciones = async ({ idCliente }) => {
  try {
    const response = await axios.post(`${BASE_API_URL}rendiciones/buscarCanalRendiciones`, {
      idCliente,
    });
    return response.data;
  } catch (error) {
    console.error("Error obteniendo datos consolidados", error);
    throw error;
  }
};
