import React, { memo, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { obtenerClientes, actualizarCliente, eliminarCliente } from "../../services/PortalClientesService";
import TableClientes from "../../components/AdministracionComponentes/componentesClientes/tableClientes";
import EditModalClientes from "../../components/AdministracionComponentes/componentesClientes/EditModalClientes";
import DeleteModalClientes from "../../components/AdministracionComponentes/componentesClientes/DeleteModalClientes";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PortalClientes = () => {
  /* accedemos al id de usuario */
  // eslint-disable-next-line no-unused-vars
  const userInfo = useSelector((state) => state.user.userInfo);
  const [clientes, setClientes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedClienteForEdit, setSelectedClienteForEdit] = useState(null);
  const [selectedClienteForDelete, setSelectedClienteForDelete] = useState(null);

  useEffect(() => {
    const cargarClientes = async () => {
      setLoading(true);
      try {
        const data = await obtenerClientes();
        setClientes(data);
      } catch (error) {
        console.error("Error al obtener los clientes:", error);
      } finally {
        setLoading(false);
      }
    };
    cargarClientes();
  }, []);

  const onEditClick = (cliente) => {
    setSelectedClienteForEdit(cliente);
    setEditModalOpen(true);
  };

  const onDeleteClick = (cliente) => {
    setSelectedClienteForDelete(cliente);
    setDeleteModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await eliminarCliente(selectedClienteForDelete.ID);
      const filteredClientes = clientes.filter((cliente) => cliente.ID !== selectedClienteForDelete.ID);
      setClientes(filteredClientes);
      setDeleteModalOpen(false);
      toast.success("Cliente eliminado con éxito");
    } catch (error) {
      console.error("Error al eliminar el cliente:", error);
      toast.error("Error al eliminar el cliente");
    }
  };

  const handleSaveEdit = async (updatedCliente) => {
    try {
      await actualizarCliente(updatedCliente.ID, updatedCliente);
      const updatedClientes = clientes.map((cliente) => (cliente.ID === updatedCliente.ID ? updatedCliente : cliente));
      setClientes(updatedClientes);
      setEditModalOpen(false);
      toast.success("Cliente actualizado con éxito");
    } catch (error) {
      console.error("Error al actualizar el cliente:", error);
      toast.error("Error al actualizar el cliente");
    }
  };

  const columns = [
    {
      field: "acciones",
      headerName: "Acciones",
      sortable: false,
      renderCell: (params) => (
        <>
          <IconButton onClick={() => onEditClick(params.row)}>
            <EditIcon style={{ color: "#041562" }} />
          </IconButton>
          <IconButton onClick={() => onDeleteClick(params.row)}>
            <DeleteIcon style={{ color: "red" }} />
          </IconButton>
        </>
      ),
      width: 100,
    },
    { field: "ID", headerName: "ID", width: 60 },
    { field: "NOMBRE", headerName: "Nombre", width: 310 },
    { field: "RUT", headerName: "Rut", width: 130 },
    { field: "COMUNA", headerName: "Comuna", width: 120 },
    { field: "IATA", headerName: "Iata", width: 70 },
    { field: "DIRECCION", headerName: "Direccion", width: 300 },
    { field: "CORREO", headerName: "Email", width: 220 },
    { field: "CONTACTO_COMERCIAL", headerName: "Contacto comercial", width: 180 },
    { field: "ESTADO", headerName: "Estado", width: 100 },
    { field: "FECHA_INGRESO", headerName: "Fecha de ingreso", width: 150 },
    { field: "FONO", headerName: "Telefono", width: 150 },
    { field: "GIRO", headerName: "Giro", width: 300 },
    { field: "TOKEN_API", headerName: "Token", width: 250 },
  ];

  useEffect(() => {
    const cargarClientes = async () => {
      setLoading(true);
      try {
        const data = await obtenerClientes();
        setClientes(data);
      } catch (error) {
        console.error("Error al obtener los clientes:", error);
      } finally {
        setLoading(false);
      }
    };
    cargarClientes();
  }, []);

  return (
    <div>
      <TableClientes columns={columns} rows={clientes} loading={loading} />

      {selectedClienteForEdit && (
        <EditModalClientes
          open={editModalOpen}
          onClose={() => {
            setEditModalOpen(false);
            setSelectedClienteForEdit(null);
          }}
          cliente={selectedClienteForEdit}
          onSave={handleSaveEdit}
        />
      )}

      {selectedClienteForDelete && (
        <DeleteModalClientes
          open={deleteModalOpen}
          onClose={() => {
            setDeleteModalOpen(false);
            setSelectedClienteForDelete(null);
          }}
          onConfirm={handleConfirmDelete}
        />
      )}
    </div>
  );
};

export default memo(PortalClientes);
