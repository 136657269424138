import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  TextField,
  Container,
  Grid,
  Card,
  IconButton,
  CardContent,
  InputAdornment,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  Paper,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { obtenerDetallesOrdenAgente } from "../../services/ReportesServices";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "@reactuiutils/horizontal-timeline/timeline.css";
import { GoogleMap, Marker } from "@react-google-maps/api";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { es } from "date-fns/locale";
import { formatInTimeZone } from "date-fns-tz";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { parse, parseISO, compareDesc } from "date-fns";
import ImageZoom from "react-image-zooom";

const ConsultaIndividualAgentes = () => {
  const userInfo = useSelector((state) => state.user.userInfo);
  const nombreAgente = userInfo ? userInfo.name : null;
  const [od, setOd] = useState("");
  const [odInfo, setOdInfo] = useState(null);
  const [certificaciones, setCertificaciones] = useState(null);
  const [activeCertification, setActiveCertification] = useState(null);
  const [selectedCertification, setSelectedCertification] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [timelineEvents, setTimelineEvents] = useState([]);
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [currentImage, setCurrentImage] = useState("");

  /* Función para obtener las imágenes */
  const getImages = (selectedCertification) => {
    const images = [];
    const base64Prefix = "data:image/jpeg;base64,";
    [selectedCertification.FOTO1, selectedCertification.FOTO2, selectedCertification.FOTO3].forEach((img) => {
      if (img && img !== "FALSE") {
        const formattedImage = img.startsWith(base64Prefix) ? img : base64Prefix + img;
        images.push(formattedImage);
      }
    });

    return images;
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", color: "red", right: "10px" }}
        onClick={onClick}
      ></div>
    );
  }

  const formatDateToSantiago = (dateString) => {
    const timeZone = "America/Santiago";
    const date = parseISO(dateString);
    return formatInTimeZone(date, timeZone, "dd-MM-yyyy, HH:mm:ss", { locale: es });
  };

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", color: "red", left: "10px", zIndex: 1 }}
        onClick={onClick}
      ></div>
    );
  }

  const getManifestoTitle = (key) => {
    let title;
    switch (key) {
      case "MANIF_INHOUSE":
        title = "MANIFIESTO SALIDA INHOUSE";
        break;
      case "MANIF_SALIDAAGENTE":
        title = "MANIFIESTO SALIDA AGENTE";
        break;
      case "MANIF_SALIDARUTA":
        title = "MANIFIESTO RUTA";
        break;
      case "MANIF_RENDICIONRUTA":
        title = "MANIFIESTO RETORNO";
        break;
      case "MANIF_ENTRADAANDEN_DIGITADOS_BODEGA":
        title = "MANIFIESTO ENTRADA DG";
        break;
      case "MANIF_SALIDAANDEN_DIGITADOS_BODEGA":
        title = "MANIFIESTO SALIDA DG";
        break;
      case "MANIF_ENTRADAAGENTE_DIGITADOS_BODEGA":
        title = "MANIFIESTO ENTRADA AGENTE DG";
        break;
      default:
        title = "MANIFIESTO DESCONOCIDO";
        break;
    }
    return title;
  };

  const getSliderSettings = (imageCount) => {
    return {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      adaptiveHeight: true,
      draggable: false,
      swipe: false,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      arrows: imageCount > 1,
    };
  };

  const handleDetailsClick = (certificacion) => {
    setSelectedCertification(certificacion);
    setDialogOpen(true);
  };

  const handleCertificationClick = (certificacion) => {
    if (activeCertification && activeCertification.ID === certificacion.ID) {
      setActiveCertification(null);
    } else {
      setActiveCertification(certificacion);
    }
  };

  const isValidCoordinate = (coordinate) => {
    return coordinate && !isNaN(coordinate);
  };

  const renderGoogleMap = () => {
    if (
      selectedCertification &&
      isValidCoordinate(selectedCertification.LAT_TERRENO) &&
      isValidCoordinate(selectedCertification.LONG_TERRENO)
    ) {
      return (
        <GoogleMap
          mapContainerStyle={{ width: "100%", height: "270px" }}
          center={{
            lat: parseFloat(selectedCertification.LAT_TERRENO),
            lng: parseFloat(selectedCertification.LONG_TERRENO),
          }}
          zoom={15}
        >
          <Marker
            position={{
              lat: parseFloat(selectedCertification.LAT_TERRENO),
              lng: parseFloat(selectedCertification.LONG_TERRENO),
            }}
          />
        </GoogleMap>
      );
    } else {
      return (
        <div
          style={{
            width: "100%",
            height: "270px",
            backgroundColor: "#ccc",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="subtitle1">Ubicación no disponible</Typography>
        </div>
      );
    }
  };

  const handleOpenImageDialog = (img) => {
    setCurrentImage(img);
    setOpenImageDialog(true);
  };

  const handleCloseImageDialog = () => {
    setOpenImageDialog(false);
  };

  const handleTextFieldChange = (event) => {
    setOd(event.target.value);
  };

  const parseDateCertificaciones = (dateStr) => {
    return parse(dateStr, "dd/MM/yyyy HH:mm:ss", new Date());
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await obtenerDetallesOrdenAgente(od, nombreAgente);
      if (response) {
        toast.success("Datos cargados con éxito!");
        /* Manejo de certificaciones */
        if (response.certificaciones) {
          const certificacionesOrdenadas = response.certificaciones.sort((a, b) => {
            const dateA = parseDateCertificaciones(a.FH_GESTION);
            const dateB = parseDateCertificaciones(b.FH_GESTION);
            return compareDesc(dateA, dateB); // Ordena de más reciente a más antiguo
          });
          console.log(certificacionesOrdenadas);
          setCertificaciones(certificacionesOrdenadas);
        } else {
          setCertificaciones([]);
        }

        /* Manejo de datos de la orden */
        if (response.datosOrden && response.datosOrden.length > 0) {
          setOdInfo(response.datosOrden[0]);
        } else {
          setOdInfo(null);
        }

        /* Manejo de detalles de manifiestos */
        if (response.detallesManifiestos && response.detallesManifiestos.length > 0) {
          const timelineEvents = prepareTimelineEvents(response.detallesManifiestos[0]);
          setTimelineEvents(timelineEvents);
          if (timelineEvents.length > 0) {
            console.log("Detalles de manifiestos cargados con éxito!");
          } else {
            console.log("No se encontraron detalles de manifiestos.");
          }
        } else {
          setTimelineEvents([]);
          console.log("No se encontraron detalles de manifiestos.");
        }
      }
    } catch (error) {
      console.error("Error al obtener detalles de la orden:", error);
      setCertificaciones([]);
      setOdInfo(null);
      setTimelineEvents([]);
      toast.error("No se encontraron registros para el OD proporcionado o el agente no tiene acceso a esta orden.");
    }
  };

  const prepareTimelineEvents = (manifiestoData) => {
    const events = [];
    const manifiestoKeys = [
      "MANIF_INHOUSE",
      "MANIF_ENTRADAANDEN_DIGITADOS_BODEGA",
      "MANIF_SALIDAANDEN_DIGITADOS_BODEGA",
      "MANIF_ENTRADAAGENTE_DIGITADOS_BODEGA",
      "MANIF_SALIDARUTA",
      "MANIF_RENDICIONRUTA",
      "MANIF_SALIDAAGENTE",
    ];
    manifiestoKeys.forEach((key) => {
      if (manifiestoData && manifiestoData[key]) {
        const [id, fecha] = manifiestoData[key].split(" / ");
        const title = getManifestoTitle(key);
        const subtitle = new Date(fecha).toLocaleString();
        events.push({
          title: title,
          subtitle: subtitle,
          id: id,
        });
      }
    });
    return events;
  };

  return (
    <Container maxWidth="lg" style={{ padding: "5px" }}>
      <Paper style={{ padding: "10px", marginBottom: "5px" }}>
        <form onSubmit={handleFormSubmit}>
          <Tooltip title="Ingrese una OD para la busqueda">
            <TextField
              label="Buscar por OD"
              value={od}
              onChange={handleTextFieldChange}
              fullWidth
              margin="dense"
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton type="submit">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Tooltip>
        </form>
      </Paper>
      {odInfo && (
        <Grid item xs={12}>
          <Card style={{ marginBottom: "5px", backgroundColor: "#f7f7f7" }}>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>Bultos:</strong> {odInfo.BULTOS}
                  </div>
                </Grid>
              </Grid>
              <div style={{ height: "1px", backgroundColor: "#DA251C", margin: "5px 0" }}></div>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>Fecha Digitación:</strong>
                    {odInfo ? formatDateToSantiago(odInfo.FH_DIGITACION) : "Cargando o no disponible"}
                  </div>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>Nombre Cliente:</strong> {odInfo.NOMBRE_CLIENTE}
                  </div>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>Nota:</strong> {odInfo.NOTA}
                  </div>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>Tipo de carga:</strong> {odInfo.TIPO_CARGA}
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>OD Papel:</strong> {odInfo.OD_PAPEL}
                  </div>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>Codigo de Barra:</strong> {odInfo.COD_BARRA || ""}
                  </div>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>Nombre:</strong> {odInfo.NOMBRE}
                  </div>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>Rut:</strong> {odInfo.RUT}
                  </div>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>Telefono:</strong> {odInfo.TELEFONO}
                  </div>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>Fecha de creacion:</strong>
                    {new Date(odInfo.FH_SYS).toLocaleString("es-CL", { timeZone: "America/Santiago" })}
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>Dirección:</strong> {odInfo.DIRECCION}
                  </div>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>Comuna Destino:</strong> {odInfo.NOMBRE_COMUNA_DESTINO}
                  </div>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>Comuna Origen:</strong> {odInfo.NOMBRE_COMUNA_ORIGEN}
                  </div>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>Centro de costo:</strong> {odInfo.CENTRO_COSTO}
                  </div>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>Guia:</strong> {odInfo.GUIA}
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      )}
      <Paper style={{ marginBottom: "5px" }}>
        <List style={{ display: "flex", flexDirection: "row", padding: 0, overflowX: "auto" }}>
          {timelineEvents.map((event, index) => (
            <ListItem key={index} style={{ width: "auto", flex: "none" }}>
              <ListItemText
                primary={event.title}
                secondary={event.subtitle}
                primaryTypographyProps={{ style: { fontSize: "0.7rem" } }}
                secondaryTypographyProps={{ style: { fontSize: "0.6rem" } }}
              />
            </ListItem>
          ))}
        </List>
      </Paper>
      {certificaciones && certificaciones.length > 0 && (
        <VerticalTimeline lineColor={"#f0f5fc"} style={{ borderLeft: "2px solid #ccc", fontSize: "10px" }}>
          {certificaciones.map((certificacion, index) => {
            const estadoColor = certificacion.ESTADO_DESCRIPCION === "ENTREGA EXITOSA" ? "#28A745" : "#DA251C";
            return (
              <VerticalTimelineElement
                contentStyle={{ background: "#f0f5fc", color: "black", padding: "10px" }}
                contentArrowStyle={{ borderRight: "7px solid  #f0f5fc" }}
                key={index}
                date={certificacion.FH_GESTION}
                iconStyle={{ background: "#041562", color: "#fff" }}
                icon={<LocalShippingIcon />}
                style={{ fontSize: "5px" }}
                onClick={() => handleCertificationClick(certificacion)}
              >
                <h3 className="vertical-timeline-element-title" style={{ fontSize: "16px", color: estadoColor }}>
                  {certificacion.ESTADO_DESCRIPCION}
                  <IconButton
                    style={{ marginLeft: "5px" }}
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDetailsClick(certificacion);
                    }}
                  >
                    <TouchAppIcon />
                  </IconButton>
                </h3>
              </VerticalTimelineElement>
            );
          })}
        </VerticalTimeline>
      )}

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} fullWidth maxWidth="md">
        <DialogTitle>Detalles de la Certificación</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              {selectedCertification && (
                <>
                  <Typography variant="body1">
                    <strong>Nombre receptor:</strong> {selectedCertification.NOMBRE}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Rut:</strong> {selectedCertification.RUT}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Fecha de Gestión:</strong> {selectedCertification.FH_GESTION}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Estado:</strong> {selectedCertification.ESTADO_DESCRIPCION}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Comuna Origen:</strong> {selectedCertification.COMUNA_ORIGEN}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Destino:</strong> {selectedCertification.DESTINO}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Chofer:</strong> {selectedCertification.NOMBRE_CHOFER}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Distancia en metros:</strong> {selectedCertification.DISTANCIA_METROS}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Nota:</strong> {selectedCertification.NOTA}
                  </Typography>
                </>
              )}
            </Grid>

            <Grid item xs={4}>
              {selectedCertification && (
                <Slider {...getSliderSettings(getImages(selectedCertification).length)}>
                  {getImages(selectedCertification).map((img, index) => (
                    <div
                      key={index}
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={img}
                        alt={`Foto ${index + 1}`}
                        style={{ width: "100%", height: "270px", cursor: "pointer" }}
                        onClick={() => handleOpenImageDialog(img)}
                      />
                      <span style={{ marginTop: "10px" }}>haz click a la imagen para ver detalle</span>
                    </div>
                  ))}
                </Slider>
              )}
            </Grid>

            <Grid item xs={4}>
              {renderGoogleMap()}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openImageDialog}
        onClose={handleCloseImageDialog}
        aria-labelledby="image-dialog-title"
        maxWidth="lg"
        fullWidth
      >
        <DialogContent
          style={{
            padding: 0,
            overflow: "hidden",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <ImageZoom
              src={currentImage}
              alt="Enlarged pic"
              zoom="300"
              width="100%"
              height="auto"
              style={{ cursor: "zoom-in" }}
            />
          </div>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default ConsultaIndividualAgentes;
