import axios from "axios";

const BASE_API_URL = process.env.REACT_APP_BACKEND_URL;

export const obtenerEjecutivasClientes = async () => {
    try {
        const response = await axios.get(`${BASE_API_URL}usuarios/obtenerClientesEjecutivas`);
        return response.data;
    } catch (error) {
        console.error("Error al obtener datos de ejecutivas y clientes", error);
        throw error;
    }
};

export const agregarClienteAEjecutiva = async (id_users, cod_cliente, fh_sys) => {
    try {
        const response = await axios.post(`${BASE_API_URL}usuarios/agregarClienteAEjecutiva`, {
            id_users,
            cod_cliente,
            fh_sys
        });
        return response.data;
    } catch (error) {
        console.error("Error al agregar cliente a ejecutiva", error);
        throw error;
    }
};

export const eliminarClienteDeEjecutiva = async (id_users, cod_cliente) => {
    try {
        const response = await axios.post(`${BASE_API_URL}usuarios/eliminarClienteDeEjecutiva`, {
            id_users,
            cod_cliente
        });
        return response.data;
    } catch (error) {
        console.error("Error al eliminar cliente de ejecutiva", error);
        throw error;
    }
};

/* obtener ejecutivas */
export const obtenerEjecutivasSac = async () => {
    try {
      const response = await axios.get(`${BASE_API_URL}usuarios/obtenerEjecutivasSac`);
      return response.data;
    } catch (error) {
      throw error;
    }
  };