import axios from "axios";
/* Usa la variable de entorno para la URL base */
const BASE_API_URL = process.env.REACT_APP_BACKEND_URL;

export const EtiquetaObtener = async (od) => {
  try {
    const response = await axios.post(`${BASE_API_URL}impresionEtiqueta/obtenerEtiqueta/${od}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};