import React from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { es } from "date-fns/locale";

const CustomDate = ({
  label,
  value,
  onChange,
  format = "dd/MM/yyyy",
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
      <DatePicker
        label={label}
        format={format}
        value={value}
        onChange={onChange}
        slotProps={{
            textField: {
              size: "small",
              variant: "outlined",
              fullWidth: true,
              margin: "dense",
            },
          }}
      />
    </LocalizationProvider>
  );
};

export default CustomDate;
