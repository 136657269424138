import axios from "axios";
/* Usa la variable de entorno para la URL base */
const BASE_API_URL = process.env.REACT_APP_BACKEND_URL;

/* buscar od y codigo de cliente */
export const buscarOrdenesClientes = async ({ searchValue, cod_cliente }) => {
  try {
    const response = await axios.post(`${BASE_API_URL}clientes/buscarOrdenesClientes`, {
      searchValue,
      cod_cliente,
    });
    return response.data;
  } catch (error) {
    console.error("Error obteniendo datos consolidados", error);
    throw error;
  }
};

export const editOrdenDataClientes = async (ID, editedData) => {
  try {
    const {
      OD,
      OD_PAPEL,
      FH_DIGITACION,
      BULTOS,
      COD_CLIENTE,
      USUARIO,
    } = editedData;

    const data = {
      OD,
      OD_PAPEL,
      FH_DIGITACION,
      BULTOS,
      COD_CLIENTE,
      USUARIO,
    };
    const response = await axios.put(`${BASE_API_URL}clientes/editOrdenesClientes/${ID}`, data);
    return response.data;
  } catch (error) {
    console.error("Error editando datos de la orden", error);
    throw error;
  }
};

