import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  IconButton,
  InputAdornment,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  actualizarChoferes,
  actualizarPasswordUsuario,
  obtenerClientes,
  obtenerAgentesNegocios,
} from "../../../services/PortalChoferesService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";

const EditModalChoferes = ({ open, onClose, choferes, onSave, campos }) => {
  /* accedemos al id de usuario */
  const userInfo = useSelector((state) => state.user.userInfo);
  // eslint-disable-next-line no-unused-vars
  const id_usuario = userInfo ? userInfo.id : null;
  const [formData, setFormData] = useState({});
  const [clientes, setClientes] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [agentesNegocios, setAgentesNegocios] = useState([]);

  const iataOptions = [
    { code: "ARI", name: "ARICA" },
    { code: "IQQ", name: "IQUIQUE" },
    { code: "ZAL", name: "VALDIVIA" },
    { code: "ZOS", name: "OSORNO" },
    { code: "SCL", name: "SANTIAGO" },
    { code: "GXQ", name: "COYHAIQUE" },
    { code: "CJC", name: "CALAMA" },
    { code: "CPO", name: "COPIAPO" },
    { code: "ZLC", name: "LA CALERA" },
    { code: "CCP", name: "CONCEPCION" },
    { code: "LSQ", name: "LOS ANGELES" },
    { code: "PUQ", name: "PUNTA ARENAS" },
    { code: "ANF", name: "ANTOFAGASTA" },
    { code: "LSC", name: "LA SERENA" },
    { code: "KNA", name: "VIÑA DEL MAR" },
    { code: "RCG", name: "RANCAGUA" },
    { code: "ZCA", name: "TALCA" },
    { code: "LNR", name: "LINARES" },
    { code: "ZCO", name: "TEMUCO" },
    { code: "PMC", name: "PUERTO MONTT" },
    { code: "QTA", name: "QUILLOTA" },
    { code: "VAP", name: "VALPARAISO" },
    { code: "YAI", name: "CHILLAN" },
    { code: "CCO", name: "CURICO" },
    { code: "WPA", name: "PUERTO AYSEN" },
    { code: "PCY", name: "PUERTO OCTAY" },
  ];

  useEffect(() => {
    setFormData({
      ...choferes,
      AGENTE_ASIGNADO: choferes?.AGENTE_ASIGNADO || "",
    });
  }, [choferes]);

  useEffect(() => {
    const cargarClientes = async () => {
      try {
        const listaClientes = await obtenerClientes();
        setClientes(listaClientes);
        setSelectedClient(listaClientes[0] || null);
      } catch (error) {
        console.error("Error al obtener los clientes:", error);
        toast.error("Error al cargar la lista de clientes.");
      }
    };
    cargarClientes();
  }, []);

  useEffect(() => {
    const cargarAgentesNegocios = async () => {
      try {
        const listaAgentes = await obtenerAgentesNegocios();
        setAgentesNegocios(listaAgentes);
      } catch (error) {
        console.error("Error al obtener los agentes de negocios:", error);
        toast.error("Error al cargar la lista de agentes de negocios.");
      }
    };
    cargarAgentesNegocios();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    const formDataConClienteId = selectedClient ? { ...formData, cod_cliente: selectedClient.ID } : { ...formData };
    const choferId = formDataConClienteId.ID;
    try {
      await actualizarChoferes(choferId, formDataConClienteId);
      onSave(formDataConClienteId);
    } catch (error) {
      console.error("Error al guardar los cambios", error);
    }
    onClose();
  };

  const handleEditPasswordClick = async () => {
    const campoPassword = "PASSWORD";
    const nuevaPassword = formData[campoPassword];
    if (!nuevaPassword || nuevaPassword.length < 6) {
      toast.error("La contraseña debe tener al menos 6 caracteres");
      return;
    }
    try {
      const response = await actualizarPasswordUsuario(nuevaPassword);
      const { hash } = response;
      setFormData((prevFormData) => ({
        ...prevFormData,
        PASSWORD_HASH: hash,
      }));
      toast.success("Hash actualizado con éxito");
    } catch (error) {
      console.error("Error al actualizar el Hash:", error);
      toast.error("Error al actualizar el Hash");
    }
  };

  const DeleteTokenFecha = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      TOKEN_SESION: "",
      FH_SYS: "",
    }));
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Editar choferes</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {campos.map((campo, index) => (
            <Grid item xs={12} key={index}>
              {campo.nombre === "AGENTE_ASIGNADO" ? (
                <Autocomplete
                  id="combo-box-agente"
                  options={agentesNegocios}
                  getOptionLabel={(option) => option.NOMBRE_AGENTE}
                  value={agentesNegocios.find((ag) => ag.NOMBRE_AGENTE === formData.AGENTE_ASIGNADO) || null}
                  onChange={(event, newValue) => {
                    setFormData({ ...formData, AGENTE_ASIGNADO: newValue ? newValue.NOMBRE_AGENTE : "" });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Agente Asignado" variant="outlined" fullWidth />
                  )}
                />
              ) : campo.nombre === "cod_cliente" ? (
                <Autocomplete
                  id="combo-box-cliente"
                  options={clientes}
                  getOptionLabel={(option) => `${option.NOMBRE} (COD: ${option.ID})`}
                  isOptionEqualToValue={(option, value) => option.ID === value.ID}
                  value={selectedClient}
                  onChange={(event, newValue) => {
                    setSelectedClient(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} label="Cliente" variant="outlined" fullWidth />}
                />
              ) : campo.nombre === "iata_sucursal" || campo.nombre === "iata_agentes" || campo.nombre === "CIUDAD" ? (
                <FormControl fullWidth>
                  <InputLabel>{campo.etiqueta}</InputLabel>
                  <Select
                    sx={{ fontSize: "0.875rem" }}
                    label={campo.etiqueta}
                    name={campo.nombre}
                    value={formData[campo.nombre] || ""}
                    onChange={handleChange}
                    variant="outlined"
                  >
                    {iataOptions.map((option) => (
                      <MenuItem sx={{ fontSize: "0.875rem" }} value={option.code} key={option.code}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : campo.nombre === "PASSWORD_HASH" ? (
                <TextField
                  fullWidth
                  label={campo.etiqueta}
                  name={campo.nombre}
                  value={formData[campo.nombre] || ""}
                  disabled
                  variant="outlined"
                />
              ) : campo.nombre === "PASSWORD" ? (
                <TextField
                  fullWidth
                  label={campo.etiqueta}
                  name={campo.nombre}
                  value={formData[campo.nombre] || ""}
                  onChange={handleChange}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="toggle password edit" onClick={handleEditPasswordClick}>
                          <EditIcon style={{ color: "#041562" }} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              ) : campo.nombre === "TOKEN_SESION" ? (
                <TextField
                  fullWidth
                  label={campo.etiqueta}
                  name={campo.nombre}
                  value={formData[campo.nombre] || ""}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="toggle delete token" onClick={DeleteTokenFecha}>
                          <DeleteForeverIcon style={{ color: "#DA251C" }} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              ) : campo.nombre === "FH_SYS" ? (
                <TextField
                  fullWidth
                  label={campo.etiqueta}
                  name={campo.nombre}
                  value={formData[campo.nombre] || ""}
                  disabled
                  variant="outlined"
                />
              ) : campo.nombre === "token" ? (
                <TextField
                  fullWidth
                  label={campo.etiqueta}
                  name={campo.nombre}
                  value={formData[campo.nombre] || ""}
                  disabled
                  variant="outlined"
                />
              ) : campo.nombre === "password" ? (
                <TextField
                  fullWidth
                  label={campo.etiqueta}
                  name={campo.nombre}
                  value={formData[campo.nombre] || ""}
                  disabled
                  variant="outlined"
                />
              ) : campo.nombre === "password_palabras" ? (
                <TextField
                  fullWidth
                  label={campo.etiqueta}
                  name={campo.nombre}
                  value={formData[campo.nombre] || ""}
                  onChange={handleChange}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="toggle password edit" onClick={handleEditPasswordClick}>
                          <EditIcon style={{ color: "#041562" }} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              ) : campo.nombre === "TRANSPORTISTA" ||
                campo.nombre === "PERMITE_RETIROS" ||
                campo.nombre === "PERMITE_IMPRESORA" ||
                campo.nombre === "PERMITE_ENTREGAS" ||
                campo.nombre === "PERMITE_INSPECCION" ||
                campo.nombre === "PERMITE_RETORNOS" ||
                campo.nombre === "PERMITE_APP" ||
                campo.nombre === "permite_rebaja_manual" ||
                campo.nombre === "permite_rebaja_masiva" ||
                campo.nombre === "permite_modificacion_orden" ? (
                <FormControl fullWidth>
                  <InputLabel>{campo.etiqueta}</InputLabel>
                  <Select
                    label={campo.etiqueta}
                    name={campo.nombre}
                    value={formData[campo.nombre] || ""}
                    onChange={handleChange}
                    variant="outlined"
                  >
                    <MenuItem value="SI">SI</MenuItem>
                    <MenuItem value="NO">NO</MenuItem>
                  </Select>
                </FormControl>
              ) : campo.nombre === "ESTADO" ? (
                <FormControl fullWidth>
                  <InputLabel>{campo.etiqueta}</InputLabel>
                  <Select
                    label={campo.etiqueta}
                    name={campo.nombre}
                    value={formData[campo.nombre] || ""}
                    onChange={handleChange}
                    variant="outlined"
                  >
                    <MenuItem value="ACTIVO">ACTIVO</MenuItem>
                    <MenuItem value="INACTIVO">INACTIVO</MenuItem>
                  </Select>
                </FormControl>
              ) : campo.nombre === "tipo_usuario" ? (
                <FormControl fullWidth>
                  <InputLabel>{campo.etiqueta}</InputLabel>
                  <Select
                    label={campo.etiqueta}
                    name={campo.nombre}
                    value={formData[campo.nombre] || ""}
                    onChange={handleChange}
                    variant="outlined"
                  >
                    <MenuItem value="ADMIN">ADMIN</MenuItem>
                    <MenuItem value="SAC">SAC</MenuItem>
                    <MenuItem value="SUPERVISORA">SUPERVISORA</MenuItem>
                    <MenuItem value="SUPERVISOR">SUPERVISOR OPERACIONES</MenuItem>
                    <MenuItem value="OPERACIONES">OPERACIONES</MenuItem>
                    <MenuItem value="FACTURACION">FACTURACION</MenuItem>
                    <MenuItem value="CLIENTE">CLIENTE</MenuItem>
                    <MenuItem value="AGENTE">AGENTE</MenuItem>
                  </Select>
                </FormControl>
              ) : (
                <TextField
                  fullWidth
                  label={campo.etiqueta}
                  name={campo.nombre}
                  value={formData[campo.nombre] || ""}
                  onChange={handleChange}
                  variant="outlined"
                />
              )}
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button onClick={handleSave}>Guardar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditModalChoferes;
