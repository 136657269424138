export const menuRules = {
  ADMIN: {
    inicio: true,
    /* CONSULTAS */
    ConsultaIndividual: true,
    ConsultaMasivo: true,
    ConsultaMasivaTotal: false,
    ConsultaPage: true,
    /* SAC */
    RendicionCedibles: true,
    RendicionCediblesViejo: true,
    Eventos: true,
    correccionOrden: true,
    RetirosAppStgo: true,
    RetirosAppInter: true,
    cedibles: true,
    controlCedibles: true,
    Listados: true,
    RetirosSantiago: true,
    RetirosInterRegion: true,
    ManifiestoRutaAgentes: true,
    EdicionAgenteOrden: true,
    /* FACTURACION */
    ordenes: true,
    portalMovimientos: true,
    DescargaNovofarmaRango: true,
    /* OPERACIONES */
    ListaCorreos: true,
    ManifiestoIngresoBodega: true,
    ManifiestosSalidaInhouse: true,
    ManifiestosRetorno: true,
    ManifiestoSalida: true,
    ListadosManifiestos: true,
    ManifiestoRetornoCliente: true,
    CubicosOperaciones: true,
    ManifiestoEntradaAgente: true,
    ManifiestoEntradaSantiago: true,
    ManifiestoRutaRef: true,
    ManifiestoRetornoClienteRef: true,
    ManifiestoSalidaRef: true,
    DescargaManifiestoProceso: true,
    ManifiestoEntradaAgenteRef: true,
    ManifiestosRetornoRef: true,
    ManifiestoEntradaSantiagoRef: true,
    ManifiestoIngresoBodegaRef: true,
    ManifiestosSalidaInhouseRef: true,
    PendientesEntradaSalida: true,
    PendientesEntradaSantiago: true,
    ListadoPendientes: true,
    ConsultaCodigoBarra: true,
    GestionMedidas: true,
    /* ADMINISTRACION */
    PortalUsuarios: true,
    PortalClientes: true,
    PortalChoferes: true,
    AsignacionClientesEjecutivas: true,
    ControlSupervisora: true,
    /* SAC */
    CediblesClientePage: true,
    RetirosClienteStgo: false,
    RetirosClienteInter: false,
    ManifiestosSalidaInhouseCliente: true,
    /* CONSULTAS */
    ConsultaIndividualClientes: true,
    ConsultaMasivoCliente: true,
    /* GRAFICOS */
    /* ReportabilidadAgentes: true, */
    ReportabilidadEjecutiva: true,
    ReportabilidadGeneral: true,
  },
  SAC: {
    inicio: true,
    /* CONSULTAS */
    ConsultaIndividual: true,
    ConsultaMasivo: true,
    ConsultaPage: true,
    Eventos: true,
    correccionOrden: true,
    RetirosAppStgo: true,
    RetirosAppInter: true,
    cedibles: true,
    controlCedibles: true,
    RendicionCedibles: true,
    RendicionCediblesViejo: true,
    Listados: true,
    RetirosSantiago: true,
    RetirosInterRegion: true,
    ManifiestoRutaAgentes: false,
    /* FACTURACION */
    ordenes: true,
    portalMovimientos: true,
    DescargaNovofarmaRango: true,
    /* OPERACIONES */
    DescargaManifiestoProceso: true,
    ListadoPendientes: true,
  },
  SUPERVISORA: {
    inicio: true,
    /* CONSULTAS */
    ConsultaIndividual: true,
    ConsultaMasivo: true,
    ConsultaPage: true,
    Eventos: true,
    correccionOrden: true,
    RetirosAppStgo: true,
    RetirosAppInter: true,
    cedibles: true,
    controlCedibles: true,
    RendicionCedibles: true,
    RendicionCediblesViejo: true,
    Listados: true,
    RetirosSantiago: true,
    RetirosInterRegion: true,
    ManifiestoRutaAgentes: false,
    EdicionAgenteOrden: true,
    /* FACTURACION */
    ordenes: true,
    portalMovimientos: true,
    /* OPERACIONES */
    DescargaManifiestoProceso: true,
    ListadoPendientes: true,
    /* ADMINISTRACION */
    AsignacionClientesEjecutivas: true,
    ControlSupervisora: true,
    PortalChoferes: true,
  },
  FACTURACION: {
    inicio: true,
    /* CONSULTAS */
    ConsultaIndividual: true,
    ConsultaMasivo: true,
    ConsultaPage: true,
    /* SAC */
    correccionOrden: true,
    Listados: true,
    /* FACTURACION */
    ordenes: true,
    portalMovimientos: true,
    DescargaNovofarmaRango: true,
    /* OPERACIONES */
    DescargaManifiestoProceso: true,
  },
  OPERACIONES: {
    inicio: true,
    /* OPERACIONES */
    ManifiestoEntradaAgente: true,
    ManifiestoIngresoBodega: true,
    ManifiestoSalida: true,
    ListadosManifiestos: true,
    ManifiestoRetornoCliente: true,
    //ListaCorreos: true,
    CubicosOperaciones: true,
    ManifiestosSalidaInhouse: true,
    ManifiestoEntradaSantiago: true,
    DescargaManifiestoProceso: true,
    ManifiestoRutaRef: true,
    ManifiestoRetornoClienteRef: true,
    ManifiestoSalidaRef: true,
    ManifiestoEntradaAgenteRef: true,
    ManifiestosRetornoRef: true,
    ManifiestoEntradaSantiagoRef: true,
    ManifiestoIngresoBodegaRef: true,
    ManifiestosSalidaInhouseRef: true,
    //PendientesEntradaSalida: true,
    //PendientesEntradaSantiago: true,
    //ListadoPendientes: true,
    ConsultaCodigoBarra: true,
    GestionMedidas: true,
    /* CONSULTAS */
    ConsultaIndividual: true,
    ConsultaMasivo: true,
    /* SAC */
    //Listados: true,
    //correccionOrden: true,
    /* FACTURACION */
    //ordenes: true,
  },
  SUPERVISOR: {
    inicio: true,
    /* OPERACIONES */
    ManifiestoEntradaAgente: true,
    ManifiestoIngresoBodega: true,
    ManifiestoSalida: true,
    ListadosManifiestos: true,
    ManifiestoRetornoCliente: true,
    ListaCorreos: true,
    CubicosOperaciones: true,
    ManifiestosSalidaInhouse: true,
    ManifiestoEntradaSantiago: true,
    DescargaManifiestoProceso: true,
    ManifiestoRutaRef: true,
    ManifiestoRetornoClienteRef: true,
    ManifiestoSalidaRef: true,
    ManifiestoEntradaAgenteRef: true,
    ManifiestosRetornoRef: true,
    ManifiestoEntradaSantiagoRef: true,
    ManifiestoIngresoBodegaRef: true,
    ManifiestosSalidaInhouseRef: true,
    PendientesEntradaSalida: true,
    PendientesEntradaSantiago: true,
    ListadoPendientes: true,
    ConsultaCodigoBarra: true,
    GestionMedidas: true,
    /* CONSULTAS */
    ConsultaIndividual: true,
    ConsultaMasivo: true,
    /* SAC */
    Listados: true,
    correccionOrden: true,
    EdicionAgenteOrden: true,
    /* FACTURACION */
    ordenes: true,
  },
  AGENTE: {
    inicio: true,
    /* OPERACIONES */
    ManifiestoEntradaAgente: true,
    ManifiestosRetorno: true,
    ListadosManifiestos: true,
    DescargaManifiestoProceso: true,
    ManifiestoRutaRef: true,
    ManifiestoEntradaAgenteRef: true,
    ManifiestosRetornoRef: true,
    /* CONSULTAS */
    ConsultaIndividualAgentes: true,
    ConsultaMasivoAgentes: true,
  },
  CLIENTE: {
    inicio: true,
    /* SAC */
    CediblesClientePage: true,
    ControlCediblesNovo: true,
    RetirosClienteStgo: false,
    RetirosClienteInter: false,
    ManifiestosSalidaInhouseCliente: true,
    DescargaManifiestoProcesoClientes: true,
    GestionOrdenesClientes: true,
    /* CONSULTAS */
    ConsultaIndividualClientes: true,
    ConsultaIndividualClientesCodigoBarra: true,
    ConsultaMasivoCliente: true,
  },
  GRAFICOS: {
    inicio: true,
    /* ReportabilidadAgentes: true, */
    ReportabilidadEjecutiva: true,
    ReportabilidadGeneral: true,
  },
  GERENCIA: {
    inicio: true,
  },
  /*   SEGURIDAD: {
      inicio: true,
       MapInteractivo: true, 
    }, */
};

export default menuRules;
