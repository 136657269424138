import React from 'react';
import { TextField } from '@mui/material';

const CustomTextField = ({ label, name, value, onChange, type = 'text', fullWidth = true, margin = 'dense', size = 'small', ...otherProps }) => {
  return (
    <TextField
      label={label}
      name={name}
      value={value}
      onChange={onChange}
      type={type}
      fullWidth={fullWidth}
      margin={margin}
      size={size}
      {...otherProps}
    />
  );
};

export default CustomTextField;
