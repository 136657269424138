import {
  insertMailGestor
} from "../../../services/ManifiestosServices";

export async function Mail_Gestor({ proceso, correoUser, numeroManifiesto, ciudadUser }) {
  try {

    const cuerpo = `https://backend.cargoexteam.cl/medicion/creacionManifiestoPDF/${numeroManifiesto}`;
    const asunto = `MANIFIESTO ${proceso} ${ciudadUser} N° ${numeroManifiesto}`;
    const datos =await insertMailGestor({
      PROCESO: proceso,
      DESTINATARIO: correoUser,
      ASUNTO: asunto,
      CUERPO: cuerpo
    });
    console.log("Datos para el Mail: ",datos);
  } catch (error) {
    console.error("Error:", error);
  }
}

export async function Mail_Gestor_Sup({ proceso, correoUserOperaciones, numeroManifiesto, ciudadUser }) {
  try {

    const cuerpo = `https://backend.cargoexteam.cl/medicion/creacionManifiestoPDF/${numeroManifiesto}`;
    const asunto = `MANIFIESTO ${proceso} ${ciudadUser} N° ${numeroManifiesto}`;
    const datos =await insertMailGestor({
      PROCESO: proceso,
      DESTINATARIO: correoUserOperaciones,
      ASUNTO: asunto,
      CUERPO: cuerpo
    });
    console.log("Datos para el Mail: ",datos);
  } catch (error) {
    console.error("Error:", error);
  }
}

