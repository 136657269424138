import React from "react";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { limiteDeFilas } from "./Funciones";

const Tabla = ({ data, headers }) => {
  return (
    <Table
      style={{
        maxWidth: "50px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      <TableHead>
        <TableRow>
          {headers.map((header, index) => (
            <TableCell key={index}>{header}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((row, rowIndex) => {
          // Filtra las filas que no tienen datos utilizando limiteDeFilas
          if (!limiteDeFilas(row)) {
            return null;
          }

          return (
            <TableRow key={rowIndex}>
              {headers.map((header, index) => (
                <TableCell key={index}>{row[header]}</TableCell>
              ))}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default Tabla;
