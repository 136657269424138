import React, { useState } from "react";
import { Grid, Container, Button } from "@mui/material";
import CustomTextField from "../../../utils/CustomTextField";
import CustomDate from "../../../utils/CustomDate";
import { editOrdenDataClientes } from "../../../services/ClientesOrdenesService";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import SaveIcon from "@mui/icons-material/Save";

const EdicionOrdenesClientes = ({ ordenData: initialOrdenData }) => {
  const userInfo = useSelector((state) => state.user.userInfo);
  const id_usuario = userInfo ? userInfo.id : null;
  const [editableOrdenData, setEditableOrdenData] = useState({ ...initialOrdenData });
  const [fechaDigitacion, setFechaDigitacion] = useState(new Date(editableOrdenData.FH_DIGITACION));

  const handleInputChange = (e, convertToUpperCase = false) => {
    const { name, value } = e.target;
    let updatedValue = value;

    if (convertToUpperCase) {
      updatedValue = value.toUpperCase();
    }
    setEditableOrdenData((prev) => ({
      ...prev,
      [name]: updatedValue,
    }));
  };

  const handleFechaChange = (newValue) => {
    setFechaDigitacion(newValue);
  };

  /* funcion para guardar la edicion */
  const handleSave = async () => {
    const editedData = {
      ...editableOrdenData,
      USUARIO: id_usuario,
      FH_DIGITACION: fechaDigitacion,
    };
    const ID = editableOrdenData.ID;
    try {
      await editOrdenDataClientes(ID, editedData);
      toast.success("Orden editada exitosamente.");
    } catch (error) {
      toast.error(`Error al editar la orden: ${error.message}`);
    }
  };

  return (
    <Container maxWidth="lg">
      <Grid container spacing={2}>
        {/* columna 1 */}
        <Grid item xs={12} sm={12} lg={3}>
          <CustomDate label="Fecha de Digitación" value={fechaDigitacion} onChange={handleFechaChange} />
          <CustomTextField
            label="Bultos"
            name="BULTOS"
            value={editableOrdenData.BULTOS || ""}
            onChange={handleInputChange}
          />
          <CustomTextField
            disabled
            label="OD"
            name="OD"
            value={editableOrdenData.OD || ""}
            onChange={handleInputChange}
          />
          <CustomTextField
            disabled
            label="OD Papel"
            name="OD_PAPEL"
            value={editableOrdenData.OD_PAPEL || ""}
            onChange={handleInputChange}
          />
          <CustomTextField
            disabled
            label="Nombre de Cliente"
            name="Comuna Destino"
            value={editableOrdenData.NOMBRE_CLIENTE || ""}
            onChange={handleInputChange}
          />
        </Grid>
        {/* columna 2 */}
        <Grid item xs={12} sm={12} lg={3}>
          <CustomTextField
            disabled
            label="ID Referencia"
            name="ID_REFERENCIA"
            value={editableOrdenData.ID_REFERENCIA || ""}
          />
          <CustomTextField
            disabled
            label="Comuna Origen"
            name="Comuna Origen"
            value={editableOrdenData.NOMBRE_CIUDAD_ORIGEN || ""}
            onChange={handleInputChange}
          />
          <CustomTextField
            disabled
            label="Comuna Destino"
            name="Comuna Destino"
            value={editableOrdenData.NOMBRE_CIUDAD_DESTINO || ""}
            onChange={handleInputChange}
          />
          <CustomTextField
            disabled
            label="Canal"
            name="Canal"
            value={editableOrdenData.CANAL || ""}
            onChange={handleInputChange}
          />
          <CustomTextField
            disabled
            label="Tipo de negocio"
            name="Tipo de negocio"
            value={editableOrdenData.TIPO_NEGOCIO || ""}
            onChange={handleInputChange}
          />
        </Grid>
        {/* columna 3 */}
        <Grid item xs={12} sm={12} lg={3}>
          <CustomTextField
            disabled
            label="Tipo de orden"
            name="Tipo de orden"
            value={editableOrdenData.TIPO_ORDEN || ""}
            onChange={handleInputChange}
          />
          <CustomTextField
            disabled
            label="Via"
            name="Via"
            value={editableOrdenData.VIA || ""}
            onChange={handleInputChange}
          />
          <CustomTextField
            disabled
            label="Tipo de carga"
            name="Tipo de carga"
            value={editableOrdenData.TIPO_CARGA || ""}
            onChange={handleInputChange}
          />
          <CustomTextField
            disabled
            label="NOTA"
            name="NOTA"
            onChange={handleInputChange}
            value={editableOrdenData.NOTA || ""}
          />
          <CustomTextField
            disabled
            label="Direccion"
            name="Direccion"
            value={editableOrdenData.DIRECCION || ""}
            onChange={handleInputChange}
          />
        </Grid>
        {/* columna 4 */}
        <Grid item xs={12} sm={12} lg={3}>
          <CustomTextField
            disabled
            label="Rut"
            name="RUT"
            value={editableOrdenData.RUT || ""}
            onChange={handleInputChange}
          />
          <CustomTextField
            disabled
            label="Nombre"
            name="NOMBRE"
            value={editableOrdenData.NOMBRE || ""}
            onChange={handleInputChange}
          />
          <CustomTextField
            disabled
            label="Correo"
            name="MAIL"
            value={editableOrdenData.MAIL || ""}
            onChange={handleInputChange}
          />
          <CustomTextField
            disabled
            label="Telefono"
            name="TELEFONO"
            value={editableOrdenData.TELEFONO || ""}
            onChange={handleInputChange}
          />
          <CustomTextField
            disabled
            label="Centro de costo"
            name="Centro de costo"
            value={editableOrdenData.CENTRO_COSTO || ""}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} sm={5} md={4} lg={3}>
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            onClick={handleSave}
            style={{ backgroundColor: "#041562", color: "#FFFFFF", marginTop: 10 }}
          >
            Guardar <SaveIcon style={{ marginLeft: "5px" }} />
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default EdicionOrdenesClientes;
