import React, { useState } from "react";
import { useSelector } from "react-redux";
import Error404 from "../../components/assets/Error404";

const RendicionCediblesViejo = () => {
  const userInfo = useSelector((state) => state.user.userInfo);
  const idUsuario = userInfo ? userInfo.id : null;
  const token = userInfo ? userInfo.token : null;
  const [iframeLoaded, setIframeLoaded] = useState(null);

  const iframeUrl = `https://app.cargoex.cl/sistemamigracion/public/rendicion_cedibles?idUsuario=${idUsuario}&token=${token}`;

  return (
    <div style={{ height: "100%", width: "100%" }}>
      {iframeLoaded === false ? (
        <Error404 />
      ) : (
        <iframe
          src={iframeUrl}
          title="Reporte Agentes"
          width="100%"
          height="100%"
          style={{ border: "none" }}
          onLoad={() => setIframeLoaded(true)}
          onError={() => setIframeLoaded(false)}
        />
      )}
    </div>
  );
};

export default RendicionCediblesViejo;