import React from "react";
import { Button, CircularProgress } from "@mui/material";

const CustomButton = ({
  children,
  onClick,
  isLoading = false,
  fullWidth = true,
  variant = "contained",
  color = "primary",
  sx = {},
  ...otherProps
}) => {
  return (
    <Button
      onClick={onClick}
      fullWidth={fullWidth}
      variant={variant}
      color={color}
      disabled={isLoading}
      sx={{
        marginTop: "4%",
        backgroundColor: "#041562",
        color: "#FFFFFF",
        "&:hover": {
          backgroundColor: "#031042",
        },
        ...sx,
      }}
      {...otherProps}
    >
      {isLoading ? <CircularProgress size={24} /> : children}
    </Button>
  );
};

export default CustomButton;
