/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  Modal,
  Backdrop,
  Fade,
  Button,
  TextField,
  IconButton,
} from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../assets/style-Modal.css";

function CustomModalCorreccion({ show, onClose, data, onSave }) {
  const initialState = {
    OD_PAPEL: "",
  };
  const [formData, setFormData] = useState(initialState);

  const handleChange = (event) => {
    const { name, value } = event.target;
    let newValue = value;
  
    if (name === "OD_PAPEL") {  // Cambiado de "ORDEN_CORRECTA" a "OD_PAPEL"
      newValue = value === "null" ? null : Number(value);
    }
  
    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };



  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      NOMBRE_RECIBE: data.NOMBRE_RECIBE || "",
      RUT: data.RUT || "",
      NOTA: data.NOTA || "",
      foto1: data.foto1 || "",
      foto2: data.foto2 || "",
      foto3: data.foto3 || "",
      COD_ESTADO: data.COD_ESTADO ? Number(data.COD_ESTADO) : "",
      OD: data.OD,
      OD_PAPEL: data.OD_PAPEL ||"",
      ID: data.ID,
      FH_GESTION: data.FH_GESTION,
    }));
  }, [data]);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      onSave(formData);
    }
  };

  return (
    <>
      <Modal
        open={show}
        onClose={onClose}
        closeAfterTransition
      /*   BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }} */
      >
        <Fade in={show}>
          <div
            style={{
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "30vw",
              backgroundColor: "white",
              padding: "16px 32px",
              outline: "none",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
              position: "relative",
            }}
          >
            {" "}
            <h1>Actualizar Orden</h1>


              {/* Columna Izquierda */}

                <TextField
                  name="ORDEN_CORRECTA"
                  label="ORDEN A MODIFICAR"
                  value={formData.OD_ACTUALIZAR}
                  onChange={handleChange}
                  onKeyPress={handleKeyPress}
                  style={{  width: "50%", }}
                  
                />
              
          
            <Button
              color="primary"
              variant="contained"
              onClick={() => onSave(formData)}
              style={{ backgroundColor: "#041562", marginTop: "16px" }}
            >
              Actualizar
            </Button>
          </div>
        </Fade>
      </Modal>

    </>
  );
}

export default CustomModalCorreccion;
