import React, { useState } from "react";
import { Box, Paper, Button, TextField, Grid, Checkbox, FormControlLabel } from "@mui/material";
import { toast } from "react-toastify";
import { generarManifiestoPDF, generarManifiestoPDF_UserBarCode } from "../../services/ManifiestosServices";

const DescargaManifiesto = () => {
  const [manifiestoId, setManifiestoId] = useState("");
  const [pdfData, setPdfData] = useState(null);

  const [isChecked, setIsChecked] = useState(false);
  const [disableChecked] = useState(false);

  const handleChange = (event) => {
    setManifiestoId(event.target.value);
  };

  const handleGenerarClick = async () => {
    if (!manifiestoId.trim()) {
      toast.error("Debe ingresar un número de manifiesto");
      return;
    }

    if (isChecked === true) {
      const pdfUrl = await generarManifiestoPDF_UserBarCode(manifiestoId, isChecked);
      setPdfData(pdfUrl);
      toast.success("PDF generado correctamente");
    } else {
      try {
        const pdfUrl = await generarManifiestoPDF(manifiestoId);
        setPdfData(pdfUrl);
        toast.success("PDF generado correctamente");
      } catch (error) {
        console.error("Hubo un error al obtener el PDF", error);
        toast.error("Error al generar el PDF");
      }
    }
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  React.useEffect(() => {
    return () => {
      if (pdfData) {
        URL.revokeObjectURL(pdfData);
      }
    };
  }, [pdfData]);

  return (
    <Paper style={{ padding: "10px", marginBottom: "10px" }}>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item xs={12} sm={5} md={5} lg={5}>
          <TextField
            label="Número de Manifiesto"
            variant="outlined"
            value={manifiestoId}
            type="search"
            size="small"
            onChange={handleChange}
            fullWidth
            sx={{ mb: 2 }}
          />
        </Grid>
        <Grid item xs={12} sm={2} md={2} lg={2}>
          <Button
            variant="contained"
            onClick={handleGenerarClick}
            sx={{
              backgroundColor: "#041562",
              color: "#FFFFFF",
              "&:hover": {
                backgroundColor: "#030e4f",
              },
              mb: 2,
            }}
          >
            Generar PDF
          </Button>
        </Grid>
        <Grid item xs={12} sm={5} md={5} lg={5}>
          <FormControlLabel
            control={<Checkbox checked={isChecked} onChange={handleCheckboxChange} />}
            label="GENERAR CON CODIGO BARRA E ID"
            disabled={disableChecked}
          />
        </Grid>
      </Grid>

      {pdfData && (
        <Box
          sx={{
            width: "100%",
            height: "500px",
            border: "1px solid #ccc",
            mt: 1,
          }}
        >
          <iframe src={pdfData} width="100%" height="100%" title="Manifiesto PDF">
            Tu navegador no soporta iframes.
          </iframe>
        </Box>
      )}
    </Paper>
  );
};

export default DescargaManifiesto;
