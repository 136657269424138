import React, { useState, useEffect } from "react";
import {
  Paper,
  List,
  Button,
  Grid,
  ListItem,
  ListItemText,
  IconButton,
  Autocomplete,
  TextField,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
//import { generarManifiestoPDF } from "../../services/ManifiestosServices";
import { getManifiestosProceso, getManifiesto } from "../../services/ManifiestosServices";
import DescriptionIcon from "@mui/icons-material/Description";
import { getCiudades } from "../../services/ManifiestosServices";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import DescargaManifiesto from "./DescargaManifiesto";
import * as XLSX from "xlsx";
import DescargaManifiestosRango from "../../components/OperacionesComponentes/componentesDescargaManifiestos/DescargaManifiestosRango";

const DescargaManifiestoProceso = () => {
  const userInfo = useSelector((state) => state.user.userInfo);
  const cod_cliente = userInfo ? userInfo.cod_cliente : null;
  const ciudadAgente = userInfo ? userInfo.nombre_ciudad_agentes : null;
  const iata_user = userInfo ? userInfo.iata_agentes : null;
  const tipo_usuario = userInfo ? userInfo.tipo_usuario : null;
  const [manifiestos, setManifiestos] = useState([]);
  const [ciudadesOption, setCiudadesOption] = useState([]);
  const [ciudad, setCiudad] = useState(tipo_usuario === "AGENTE" ? ciudadAgente : null);
  const [iataCiudad, setIataCiudad] = useState(null);
  const [proceso, setProceso] = useState(null);
  const [fecha, setFecha] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log(userInfo);
        const dataCiudades = await getCiudades();
        const ciudadesOption = dataCiudades.map((ciudad) => ({
          value: ciudad.IATA,
          label: ciudad.NOMBRE,
        }));
        setCiudadesOption(ciudadesOption);
      } catch (error) {
        console.error("Error al obtener las ciudades:", error);
      }
    };

    fetchData();
  }, [userInfo]);

  const procesoOption = [
    { label: "ENTRADA AGENTE", value: "ENTRADAAGENTE" },
    { label: "ENTRADA ANDEN", value: "ENTRADAANDEN" },
    { label: "INGRESO SANTIAGO", value: "INGRESOSANTIAGO" },
    { label: "SALIDA CLIENTE (INHOUSE)", value: "INHOUSE" },
    { label: "SALIDA AGENTE", value: "SALIDAAGENTE" },
    { label: "SALIDA ANDEN", value: "SALIDAANDEN" },
    { label: "SALIDA RUTA", value: "SALIDARUTA" },
    { label: "RETORNO CLIENTE", value: "RETORNOCLIENTES" },
  ];

  const opcionesFiltradas = procesoOption.filter((option) => {
    if (tipo_usuario === "AGENTE") {
      return ["ENTRADAAGENTE", "SALIDAAGENTE", "SALIDAANDEN", "SALIDARUTA"].includes(option.value);
    } else if (tipo_usuario === "CLIENTE") {
      return ["INHOUSE"].includes(option.value);
    }
    return true; // Muestra todas las opciones
  });

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      setManifiestos([]);

      const data = (() => {
        switch (proceso) {
          case "SALIDARUTA":
            return {
              PROCESO: proceso,
              FECHA: fecha,
              ORIGEN: tipo_usuario === "AGENTE" ? iata_user : iataCiudad,
              DESTINO: tipo_usuario === "AGENTE" ? iata_user : iataCiudad,
            };
          case "SALIDAAGENTE":
            return {
              PROCESO: proceso,
              FECHA: fecha,
              ORIGEN: tipo_usuario === "AGENTE" ? iata_user : iataCiudad,
              //DESTINO: iata_user,
            };
          case "INGRESOSANTIAGO":
            return {
              PROCESO: proceso,
              FECHA: fecha,
              ORIGEN: tipo_usuario === "AGENTE" ? iata_user : iataCiudad,
            };
          case "ENTRADAAGENTE":
            return {
              PROCESO: proceso,
              FECHA: fecha,
              ORIGEN: tipo_usuario === "AGENTE" ? iata_user : iataCiudad,
            };
          case "SALIDAANDEN":
            if (!iataCiudad && tipo_usuario !== "AGENTE") {
              return {
                PROCESO: proceso,
                FECHA: fecha,
                ORIGEN: "SCL",
              };
            } else {
              return {
                PROCESO: proceso,
                FECHA: fecha,
                ORIGEN: "SCL",
                DESTINO: tipo_usuario === "AGENTE" ? iata_user : iataCiudad,
              };
            }
          case "MANIFIESTOAPP":
          case "INHOUSE":
          case "ENTRADAANDEN":
            return {
              PROCESO: proceso,
              FECHA: fecha,
              ORIGEN: "SCL",
            };
          case "RETORNOCLIENTES":
            return {
              PROCESO: proceso,
              FECHA: fecha,
              ORIGEN: "SCL",
            };
          default:
            return {};
        }
      })();

      const response = await getManifiestosProceso(data);

      // Convertir cod_cliente a número para la comparación
      const codClienteNumber = parseInt(userInfo.cod_cliente, 10);

      // Filtrar manifiestos solo si el tipo de usuario es CLIENTE
      const manifiestosFiltrados =
        tipo_usuario === "CLIENTE"
          ? response.filter((manifiesto) => manifiesto.ID_CLIENTE === codClienteNumber)
          : response;

      if (manifiestosFiltrados.length > 0) {
        toast.success("Datos cargados con éxito!");
        setManifiestos(manifiestosFiltrados);
      } else {
        console.log("No se encontraron detalles de manifiestos.");
        toast.warning("No se encontraron detalles de manifiestos.");
      }
    } catch (error) {
      console.error("Error al obtener detalles de la orden:", error);
      toast.error("Error al obtener detalles de la orden.");
    }
  };

  const handleChangeProceso = async (event, newValue) => {
    try {
      if (newValue.value === "INHOUSE" || newValue.value === "ENTRADAANDEN") {
        setCiudad("SANTIAGO");
      } else {
        if (tipo_usuario !== "AGENTE") {
          setCiudad(null);
          setIataCiudad(null);
        }
      }
      setProceso(newValue.value);
      setManifiestos([]);
    } catch (error) {}
  };

  const handleChangeCiudades = async (event, newValue) => {
    try {
      if (newValue === null) {
        setCiudad("");
        setIataCiudad("");
      } else {
        setCiudad(newValue.label);
        setIataCiudad(newValue.value);
      }
    } catch (error) {}
  };
  const handleChangeFecha = async (event, newValue) => {
    try {
      setFecha(event.target.value);
      setManifiestos([]);
    } catch (error) {}
  };

  const downloadExcel = async (id) => {
    const datos = {
      ID_MANIFIESTO: id,
    };
    const ordenesDataTabla = await getManifiesto(datos);
    // Seleccionar solo las columnas necesarias y renombrar las cabeceras
    const filteredData = ordenesDataTabla.map((item) => ({
      OD: item.OD,
      Fecha: new Date(item.FH_SYS).toLocaleDateString("es-ES", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }),
      Observacion: item.NOTA,
      Destino: item.NOMBRE,
      Dirección: item.DIRECCION,
      "Comuna Destino": item.COMUNA_DESTINO,
      Via: item.VIA,
      Bultos: item.BULTOS,
      Kilos: item.PESO,
    }));

    // Crear una hoja de trabajo a partir de los datos filtrados
    const worksheet = XLSX.utils.json_to_sheet(filteredData);

    worksheet["!cols"] = [
      { wch: 10 },
      { wch: 15 },
      { wch: 40 },
      { wch: 40 },
      { wch: 40 },
      { wch: 25 },
      { wch: 15 },
      { wch: 10 },
      { wch: 10 },
    ];
    // Crear un nuevo libro de trabajo y añadir la hoja de trabajo
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "HOJA1");

    // Descargar el archivo Excel
    XLSX.writeFile(workbook, `MANIFIESTO_${id}.xlsx`);
  };

  return (
    <div>
      {tipo_usuario !== "CLIENTE" && tipo_usuario !== "AGENTE" && <DescargaManifiesto />}
      <form>
        <Paper style={{ padding: "10px" }}>
          <Typography variant="h6" align="center" gutterBottom>
            Busqueda Manifiestos por Proceso
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Autocomplete
                disableClearable
                //value={proceso}
                disablePortal
                id="size-small-outlined-multi"
                size="small"
                options={opcionesFiltradas}
                onChange={handleChangeProceso}
                sx={{ width: "100%" }}
                PaperComponent={({ children }) => (
                  <Paper
                    sx={{
                      width: "100%",
                      textAlign: "left",
                      "& li": { textAlign: "left" },
                    }}
                  >
                    {children}
                  </Paper>
                )}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                renderInput={(params) => <TextField {...params} label="PROCESO" />}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <TextField
                fullWidth
                type="date"
                size="small"
                label="Fecha"
                value={fecha}
                onChange={handleChangeFecha}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            {tipo_usuario !== "CLIENTE" ? (
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <Autocomplete
                  //disableClearable
                  value={ciudad}
                  //disablePortal
                  id="size-small-outlined-multi"
                  size="small"
                  options={ciudadesOption}
                  onChange={handleChangeCiudades}
                  disabled={tipo_usuario === "AGENTE" || proceso === "INHOUSE" || proceso === "ENTRADAANDEN" || proceso === "RETORNOCLIENTES"} // Disable based on user type and process
                  sx={{ width: "100%" }}
                  PaperComponent={({ children }) => (
                    <Paper
                      sx={{
                        width: "100%",
                        textAlign: "left",
                        "& li": { textAlign: "left" },
                      }}
                    >
                      {children}
                    </Paper>
                  )}
                  isOptionEqualToValue={(option, value) => option.label === value}
                  renderInput={(params) => <TextField {...params} label="CIUDAD" />}
                />
              </Grid>
            ) : (
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <Button
                  variant="contained"
                  size="medium"
                  sx={{
                    width: "100%",
                    height: "90%",
                    backgroundColor: "#041562",
                  }}
                  onClick={handleFormSubmit}
                >
                  Buscar
                </Button>
              </Grid>
            )}
          </Grid>
          {tipo_usuario !== "CLIENTE" && (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} margin={"10px"}>
                <Button
                  variant="contained"
                  size="medium"
                  sx={{
                    width: "100%",
                    height: "90%",
                    backgroundColor: "#041562",
                  }}
                  onClick={handleFormSubmit}
                >
                  Buscar
                </Button>
              </Grid>
            </Grid>
          )}
          <div style={{ maxHeight: "650px", overflowY: "auto" }}>
            <List style={{ display: "flex", flexDirection: "column", padding: 0 }}>
              {manifiestos.map((manifiesto) => (
                <ListItem key={manifiesto.ID}>
                  <div style={{ position: "relative", display: "inline-block" }}>
                    <IconButton
                      onClick={() =>
                        window.open(
                          `https://backend.cargoexteam.cl/medicion/creacionManifiestoPDF/${manifiesto.ID}`,
                          "_blank"
                        )
                      }
                    >
                      <DescriptionIcon style={{ color: "#DA251C" }} />
                    </IconButton>
                    <span
                      style={{
                        position: "absolute",
                        top: "0px",
                        right: "0px",
                        fontSize: "10px",
                        color: "#DA251C",
                      }}
                    >
                      PDF
                    </span>
                  </div>

                  {tipo_usuario !== "AGENTE" && proceso === "INHOUSE" && (
                    <div style={{ position: "relative", display: "inline-block", marginLeft: "8px" }}>
                      <IconButton onClick={() => downloadExcel(manifiesto.ID)}>
                        <DescriptionIcon style={{ color: "#3d834c" }} />
                      </IconButton>
                      <span
                        style={{
                          position: "absolute",
                          top: "0px",
                          right: "0px",
                          fontSize: "10px",
                          color: "#3d834c",
                        }}
                      >
                        EXCEL
                      </span>
                    </div>
                  )}

                  <ListItemText
                    primary={`Manifiesto: ${manifiesto.ID}  / Creación: ${format(
                      new Date(manifiesto.FH_CREACION),
                      "dd-MM-yyyy"
                    )}`}
                    secondary={
                      manifiesto.PROCESO === "SALIDARUTA"
                        ? `CHOFER: ${manifiesto.CHOFER}`
                        : manifiesto.PROCESO === "ENTRADAAGENTE"
                        ? `ORIGEN: ${manifiesto.NOMBRE_ORIGEN}`
                        : manifiesto.PROCESO === "SALIDAANDEN"
                        ? `DESTINO: ${manifiesto.NOMBRE_DESTINO}`
                        : manifiesto.PROCESO === "SALIDAAGENTE"
                        ? `ORIGEN: ${manifiesto.NOMBRE_ORIGEN}`
                        : manifiesto.PROCESO === "INHOUSE"
                        ? `CLIENTE: ${manifiesto.NOMBRE_CLIENTE}`
                        : manifiesto.PROCESO === "ENTRADAANDEN"
                        ? `USUARIO: ${manifiesto.NOMBRE_USUARIO}`
                        : manifiesto.PROCESO === "RETORNOCLIENTES"
                        ? `CLIENTE: ${manifiesto.NOMBRE_CLIENTE}`
                        : null
                    }
                    style={{ marginLeft: "10px" }}
                  />
                </ListItem>
              ))}
            </List>
          </div>
          {(cod_cliente === "549" || tipo_usuario === "ADMIN" || cod_cliente === "632") && <DescargaManifiestosRango />}
        </Paper>
      </form>
    </div>
  );
};
export default DescargaManifiestoProceso;
