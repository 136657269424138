import axios from "axios";
/* Usa la variable de entorno para la URL base */
const BASE_API_URL = process.env.REACT_APP_BACKEND_URL;

/* crear usuarios */
export const crearEventos = async (usuariosData) => {
  try {
    const response = await axios.post(`${BASE_API_URL}usuarios/crearUsuario`, usuariosData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const obtenerEventos = async ({ BUSCAR }) => {
  try {
    const response = await axios.post(`${BASE_API_URL}consolidado/getConsolidado`, { BUSCAR });
    return response.data;
  } catch (error) {
    console.error("Error obteniendo datos consolidados", error);
    throw error;
  }
};


export const getConsolidado = async ({ BUSCAR }) => {
  try {
    const response = await axios.post(`${BASE_API_URL}consolidado/getConsolidado`, { BUSCAR });
    return response.data;
  } catch (error) {
    console.error("Error obteniendo datos consolidados", error);
    throw error;
  }
};

export const deleteConsolidadoData = async (data) => {
  const { id, USUARIO, OD, OD_PAPEL, NOMBRE_RECIBE, RUT, FH_GESTION, NOMBRE_CHOFER, COD_ESTADO_DESCRIPCION, FOTO1, FOTO2, FOTO3 } = data;
  try {
    const response = await fetch(`${BASE_API_URL}consolidado/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        USUARIO: USUARIO,
        OD: OD,
        OD_PAPEL: OD_PAPEL,
        NOMBRE_RECIBE: NOMBRE_RECIBE,
        RUT: RUT,
        FH_GESTION: FH_GESTION,
        NOMBRE_CHOFER: NOMBRE_CHOFER,
        COD_ESTADO_DESCRIPCION: COD_ESTADO_DESCRIPCION,
        FOTO1: FOTO1,
        FOTO2: FOTO2,
        FOTO3: FOTO3,
      }),
    });

    if (!response.ok) {
      throw new Error("Error al eliminar el consolidado");
    }

    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
};

export const editConsolidadoData = async (dataToSend) => {
  console.log(dataToSend);
  try {
    const response = await axios.put(`${BASE_API_URL}consolidado/editConsolidado`, dataToSend);
    return response.data;
  } catch (error) {
    console.error('Error en la petición editConsolidadoData:', error);
    throw error;
  }
};

export const deleteConsolidadoDataCorreccion = async (data) => {
  const { id, USUARIO, OD, OD_PAPEL, NOMBRE_RECIBE, RUT, FH_GESTION,NOMBRE_CHOFER, COD_ESTADO_DESCRIPCION, FOTO1, FOTO2, FOTO3 } = data;
  try {
    const response = await fetch(`${BASE_API_URL}consolidado/deleteConsolidadoCorreccion/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        USUARIO: USUARIO,
        OD: OD,
        OD_PAPEL: OD_PAPEL,
        NOMBRE_RECIBE: NOMBRE_RECIBE,
        RUT: RUT,
        FH_GESTION: FH_GESTION,
        NOMBRE_CHOFER: NOMBRE_CHOFER,
        COD_ESTADO_DESCRIPCION: COD_ESTADO_DESCRIPCION,
        FOTO1: FOTO1,
        FOTO2: FOTO2,
        FOTO3: FOTO3,
      }),
    });

    if (!response.ok) {
      throw new Error("Error al eliminar el consolidado");
    }

    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
};