import React, { useState, memo } from "react";
import { Grid, Button, TextField, Container, Paper, CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import EdicionOrdenesClientes from "../../components/ClientesComponentes/componentesOrdenesClientes/EdicionOrdenesCliente";
import { buscarOrdenesClientes } from "../../services/ClientesOrdenesService";
import "react-toastify/dist/ReactToastify.css";

const GestionOrdenesClientes = () => {
  const userInfo = useSelector((state) => state.user.userInfo);
  const [searchValue, setSearchValue] = useState("");
  const [isLoadingEditar, setIsLoadingEditar] = useState(false);
  const [ordenData, setOrdenData] = useState(null);
  const [showEditor, setShowEditor] = useState(false);

  /* buscamos la orden y traemos sus datos */
  const BuscarParaEditar = async () => {
    try {
      setIsLoadingEditar(true);
      const cod_cliente = userInfo ? userInfo.cod_cliente : null;
      const response = await buscarOrdenesClientes({ searchValue, cod_cliente });
      setOrdenData(response);
      setShowEditor(true);
      toast.success("Orden encontrada exitosamente.");
    } catch (error) {
      console.error("Error al obtener datos", error);
      toast.error("Error obteniendo datos. Por favor, inténtalo de nuevo.");
    } finally {
      setIsLoadingEditar(false);
    }
  };
  
  /* si doy enter busca en editar */
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      BuscarParaEditar();
      event.preventDefault();
    }
  };

  return (
    <Container maxWidth="lg">
      <Paper sx={{ p: 1, mb: 1, width: "auto" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={5} md={4} lg={3}>
            <TextField
              label="Buscar Orden"
              variant="outlined"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              onKeyDown={handleKeyDown}
              fullWidth
              margin="dense"
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={5} md={4} lg={3}>
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              onClick={BuscarParaEditar}
              style={{ backgroundColor: "#041562", color: "#FFFFFF", marginTop: 10 }}
              disabled={isLoadingEditar}
            >
              {isLoadingEditar ? <CircularProgress size={24} color="inherit" /> : "Buscar Orden"}
            </Button>
          </Grid>
        </Grid>
      </Paper>
      {showEditor && ordenData && (
        <Paper sx={{ p: 1, mb: 1, width: "auto" }}>
          <EdicionOrdenesClientes key={ordenData.ID} ordenData={ordenData}/>
        </Paper>
      )}
    </Container>
  );
};

export default memo(GestionOrdenesClientes);
