import { insertMailGestor } from "../../../services/ManifiestosServices";

async function Mail_Gestor({ proceso, correoUser, numeroManifiesto }) {
  try {

    const cuerpo = `https://backend.cargoexteam.cl/medicion/creacionManifiestoPDF/${numeroManifiesto}`;
    const asunto = `MANIFIESTO ENTRADA AGENTE ${proceso} N° ${numeroManifiesto}`;
    const datos =await insertMailGestor({
      PROCESO: proceso,
      DESTINATARIO: correoUser,
      ASUNTO: asunto,
      CUERPO: cuerpo
    });
    console.log("Datos para el Mail: ",datos);
  } catch (error) {
    console.error("Error:", error);
  }
}

export default Mail_Gestor;
