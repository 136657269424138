import React, { useState, useRef, useCallback } from "react";
import { Paper, Grid, Button, Typography, TextField, Checkbox, FormControlLabel } from "@mui/material";
import { toast } from "react-toastify";
import { insertOrdenesMedidas, getOrdenBarraMedidas } from "../../../services/CubicosServices";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import GestionMedidasMasiva from "../../../components/OperacionesComponentes/componentesCubicos/GestionMedidasMasiva";

const GestionMedidas = () => {
  const inputRef = useRef(null);
  const userInfo = useSelector((state) => state.user.userInfo);
  const id_user = userInfo ? userInfo.id : null;
  const [orderData, setOrderData] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [existeMedidas, setExisteMedidas] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  // Estado para "Refrigerado"
  const [refrigeradoData, setRefrigeradoData] = useState({
    bultos: "",
    largo: "",
    ancho: "",
    alto: "",
    peso: "",
  });

  // Estado para "Ambiente"
  const [ambienteData, setAmbienteData] = useState({
    bultos: "",
    largo: "",
    ancho: "",
    alto: "",
    peso: "",
  });

  const ordenConsultada = useCallback(async (event) => {
    event.preventDefault();
    const ordenValue = inputRef.current.value.trim();
    const datos = {
      COD_CLIENTE: 632,
      COD_BARRA: ordenValue,
    };

    try {
      const response = await getOrdenBarraMedidas(datos);

      if (response.existeMedidas) {
        setOrderData(response.orden);
        setExisteMedidas(true);
        toast.warning("Ya existen datos registrados para esta OD.");
      } else {
        setOrderData(response.orden);
        setExisteMedidas(false);
      }

      inputRef.current.value = "";
    } catch (error) {
      toast.error("Número no corresponde a cliente.");
    }
  }, []);

  const handleInputChange = (section, field, value) => {
    const sanitizedValue = value.replace(",", ".");
    if (section === "refrigerado") {
      setRefrigeradoData((prevState) => ({ ...prevState, [field]: sanitizedValue }));
    } else if (section === "ambiente") {
      setAmbienteData((prevState) => ({ ...prevState, [field]: sanitizedValue }));
    }
  };

  const handleGuardarDatos = async () => {
    setIsSaving(true);

    // Validar que al menos uno de los conjuntos de datos esté completo
    // Validar que al menos uno de los conjuntos de datos esté completo
    const isRefrigeradoComplete =
      refrigeradoData.bultos &&
      refrigeradoData.largo &&
      refrigeradoData.ancho &&
      refrigeradoData.alto &&
      refrigeradoData.peso;
    const isAmbienteComplete =
      refrigeradoData.bultos && ambienteData.largo && ambienteData.ancho && ambienteData.alto && ambienteData.peso;
    /*      const isRefrigeradoComplete = refrigeradoData.bultos;
     const isAmbienteComplete = ambienteData.bultos; */

    if (!isRefrigeradoComplete && !isAmbienteComplete) {
      toast.error("Por favor, complete los campos de Refrigerado o Ambiente.");
      setIsSaving(false);
      return;
    }

    if (Number(refrigeradoData.bultos) + Number(ambienteData.bultos) < orderData.BULTOS) {
      toast.error("Total de bultos debe coincidir con el total original.");
      setIsSaving(false);
      return;
    }

    if (Number(refrigeradoData.bultos) + Number(ambienteData.bultos) > orderData.BULTOS) {
      toast.error("Total de bultos ingresados supera el total original.");
      setIsSaving(false);
      return;
    }

    const result = await Swal.fire({
      title: "¿Estás seguro?",
      text: "¿Deseas guardar los datos ingresados?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sí, guardar",
      cancelButtonText: "Cancelar",
    });

    if (!result.isConfirmed) {
      setIsSaving(false);
      return;
    }

    try {
      const promises = [];

      if (isRefrigeradoComplete) {
        const dataRefrigerado = {
          OD: orderData.OD,
          LARGO: refrigeradoData.largo,
          ALTO: refrigeradoData.alto,
          ANCHO: refrigeradoData.ancho,
          PESO: refrigeradoData.peso,
          CANTIDAD_FRIO: refrigeradoData.bultos,
          CANAL: "FRIO",
          USUARIO: id_user,
          ID_REFERENCIA: orderData.COD_BARRA,
          COD_CLIENTE: 632,
        };
        promises.push(insertOrdenesMedidas(dataRefrigerado));
      }

      if (isAmbienteComplete) {
        const dataAmbiente = {
          OD: orderData.OD,
          LARGO: ambienteData.largo,
          ALTO: ambienteData.alto,
          ANCHO: ambienteData.ancho,
          PESO: ambienteData.peso,
          CANTIDAD_FRIO: ambienteData.bultos,
          CANAL: "AMBIENTE",
          USUARIO: id_user,
          ID_REFERENCIA: orderData.COD_BARRA,
          COD_CLIENTE: 632,
        };
        promises.push(insertOrdenesMedidas(dataAmbiente));
      }

      await Promise.all(promises);

      toast.success("Datos guardados exitosamente.");
      setOrderData(null);
      setRefrigeradoData({
        bultos: "",
        largo: "",
        ancho: "",
        alto: "",
        peso: "",
      });
      setAmbienteData({
        bultos: "",
        largo: "",
        ancho: "",
        alto: "",
        peso: "",
      });
    } catch (error) {
      toast.error("Error al guardar los datos.");
    } finally {
      setIsSaving(false);
    }
  };

  const handleKeyDown = (e) => {
    const allowedKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab", ".", ","];

    if (!/[0-9]/.test(e.key) && !allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  return (
    <>
      {/* El resto del código para el formulario */}
      <Paper style={{ padding: "10px", marginBottom: "10px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3} md={3} lg={3}>
            <input
              ref={inputRef}
              type="text"
              placeholder="Ingrese Codigo Barra"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  ordenConsultada(e);
                }
              }}
              required
              disabled={isChecked} // Deshabilitar si no está marcado
            />
          </Grid>
          <Grid item xs={12} sm={2} md={2} lg={2}>
            <Button
              variant="contained"
              size="medium"
              sx={{
                width: "100%",
                height: "100%",
                backgroundColor: "#041562",
              }}
              onClick={ordenConsultada}
              disabled={isChecked} // Deshabilitar si no está marcado
            >
              Buscar
            </Button>
          </Grid>
          <Grid item xs={12} sm={2} md={2} lg={2}>
            <Button
              variant="contained"
              size="medium"
              sx={{
                width: "100%",
                height: "100%",
                backgroundColor: "#4CAF50",
              }}
              onClick={handleGuardarDatos}
              disabled={isChecked || !orderData || isSaving || existeMedidas} // Deshabilitar si no está marcado
            >
              Guardar
            </Button>
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={3}>
            <FormControlLabel
              control={<Checkbox checked={isChecked} onChange={handleCheckboxChange} />}
              label="INGRESO MASIVO EXCEL"
            />
          </Grid>
        </Grid>
      </Paper>

      {orderData && (
        <Paper style={{ padding: "10px", marginTop: "10px" }}>
          <Typography variant="h6">Detalles de la Orden</Typography>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <Typography>
                <strong>CLIENTE:</strong> {orderData.NOMBRE}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                <strong>CÓDIGO BARRA:</strong> {orderData.COD_BARRA}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                <strong>OD:</strong> {orderData.OD}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                <strong>BULTOS:</strong> {orderData.BULTOS}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                <strong>COMUNA:</strong> {orderData.COMUNA_DESTINO}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                <strong>CIUDAD JAULA:</strong> {orderData.CIUDAD_DESTINO}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      )}
      {orderData && (
        <>
          <Paper style={{ padding: "10px", marginTop: "10px" }}>
            <Typography sx={{ color: "blue", fontSize: "2rem" }}>REFRIGERADO: BULTOS, MEDIDAS Y PESO</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <TextField
                  label="Número de Bultos"
                  value={refrigeradoData.bultos}
                  onChange={(e) => handleInputChange("refrigerado", "bultos", e.target.value)}
                  onKeyDown={handleKeyDown}
                  fullWidth
                  size="medium"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2} lg={2}>
                <TextField
                  label="Largo"
                  value={refrigeradoData.largo}
                  onChange={(e) => handleInputChange("refrigerado", "largo", e.target.value)}
                  onKeyDown={handleKeyDown}
                  fullWidth
                  size="medium"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2} lg={2}>
                <TextField
                  label="Ancho"
                  value={refrigeradoData.ancho}
                  onChange={(e) => handleInputChange("refrigerado", "ancho", e.target.value)}
                  onKeyDown={handleKeyDown}
                  fullWidth
                  size="medium"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2} lg={2}>
                <TextField
                  label="Alto"
                  value={refrigeradoData.alto}
                  onChange={(e) => handleInputChange("refrigerado", "alto", e.target.value)}
                  onKeyDown={handleKeyDown}
                  fullWidth
                  size="medium"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <TextField
                  label="Peso"
                  value={refrigeradoData.peso}
                  onChange={(e) => handleInputChange("refrigerado", "peso", e.target.value)}
                  onKeyDown={handleKeyDown}
                  fullWidth
                  size="medium"
                />
              </Grid>
            </Grid>
          </Paper>

          <Paper style={{ padding: "10px", marginTop: "10px" }}>
            <Typography sx={{ color: "red", fontSize: "2rem" }}>AMBIENTE: BULTOS, MEDIDAS Y PESO</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <TextField
                  label="Número de Bultos"
                  value={ambienteData.bultos}
                  onChange={(e) => handleInputChange("ambiente", "bultos", e.target.value)}
                  onKeyDown={handleKeyDown}
                  fullWidth
                  size="medium"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2} lg={2}>
                <TextField
                  label="Largo"
                  value={ambienteData.largo}
                  onChange={(e) => handleInputChange("ambiente", "largo", e.target.value)}
                  onKeyDown={handleKeyDown}
                  fullWidth
                  size="medium"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2} lg={2}>
                <TextField
                  label="Ancho"
                  value={ambienteData.ancho}
                  onChange={(e) => handleInputChange("ambiente", "ancho", e.target.value)}
                  onKeyDown={handleKeyDown}
                  fullWidth
                  size="medium"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2} lg={2}>
                <TextField
                  label="Alto"
                  value={ambienteData.alto}
                  onChange={(e) => handleInputChange("ambiente", "alto", e.target.value)}
                  onKeyDown={handleKeyDown}
                  fullWidth
                  size="medium"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <TextField
                  label="Peso"
                  value={ambienteData.peso}
                  onChange={(e) => handleInputChange("ambiente", "peso", e.target.value)}
                  onKeyDown={handleKeyDown}
                  fullWidth
                  size="medium"
                />
              </Grid>
            </Grid>
          </Paper>
        </>
      )}
      {isChecked && <GestionMedidasMasiva></GestionMedidasMasiva>}
    </>
  );
};

export default GestionMedidas;
