import React, { useState, useEffect } from "react";
import { Grid, Button, TextField } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
import "react-toastify/dist/ReactToastify.css";
import Table from "react-bootstrap/Table";

const DocumentHandler = ({ ordenData, guias, setGuias }) => {
  const [documentoActual, setDocumentoActual] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [editedDocumentIndex, setEditedDocumentIndex] = useState(null);
  const [editedDocumentValue, setEditedDocumentValue] = useState("");

  useEffect(() => {
    if (ordenData && ordenData.GUIA) {
      setGuias(ordenData.GUIA.split("-").map((guia) => guia.trim()));
    }
  }, [ordenData, setGuias]);

  const handleInputChangeDocument = (e) => {
    setDocumentoActual(e.target.value);
  };

  const handleIngresarDocumento = () => {
    setGuias((prevGuias) => [...prevGuias, documentoActual.trim()]);
    setDocumentoActual("");
  };

  const handleSaveEditedDocument = () => {
    const newGuias = [...guias];
    newGuias[editedDocumentIndex] = editedDocumentValue;
    setGuias(newGuias);
    setIsEditing(false);
    setEditedDocumentIndex(null);
    setEditedDocumentValue("");
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleIngresarDocumento();
      event.preventDefault();
    }
  };

  const handleEliminarDocumento = (indexToRemove) => {
    setGuias((prevGuias) => prevGuias.filter((_, index) => index !== indexToRemove));
  };

  const handleEditarDocumento = (index) => {
    setEditedDocumentIndex(index);
    setEditedDocumentValue(guias[index]);
    setIsEditing(true);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={5} md={4} lg={2}>
        <TextField
          label="N.º Guia"
          variant="outlined"
          value={documentoActual || ""}
          onKeyDown={handleKeyDown}
          onChange={handleInputChangeDocument}
          fullWidth
          margin="dense"
          size="small"
        />
      </Grid>
      <Grid item xs={12} sm={5} md={4} lg={2}>
        <Button
          fullWidth
          variant="outlined"
          onClick={handleIngresarDocumento}
          style={{
            backgroundColor: "#041562",
            color: "#FFFFFF",
            marginTop: 10,
          }}
        >
          Ingresar
        </Button>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Guias</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {guias.map((guia, index) => (
              <tr key={index}>
                <td>
                  {isEditing && editedDocumentIndex === index ? (
                    <TextField
                      fullWidth
                      value={editedDocumentValue}
                      onChange={(e) => setEditedDocumentValue(e.target.value)}
                    />
                  ) : (
                    guia
                  )}
                </td>
                <td>
                  {isEditing && editedDocumentIndex === index ? (
                    <Button
                      onClick={handleSaveEditedDocument}
                      style={{
                        backgroundColor: "transparent",
                        border: "1px solid #041562",
                        color: "#041562",
                        padding: "5px 10px",
                        cursor: "pointer",
                      }}
                    >
                      Guardar
                    </Button>
                  ) : (
                    <>
                      <Button
                        onClick={() => handleEliminarDocumento(index)}
                        style={{
                          backgroundColor: "transparent",
                          color: "red",
                          padding: "5px 10px",
                          cursor: "pointer",
                        }}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                      <Button
                        onClick={() => handleEditarDocumento(index)}
                        style={{
                          backgroundColor: "transparent",
                          color: "#041562",
                          padding: "5px 10px",
                          cursor: "pointer",
                        }}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </Button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Grid>
    </Grid>
  );
};

export default DocumentHandler;
