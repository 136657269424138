import axios from "axios";
/* Usa la variable de entorno para la URL base */
const BASE_API_URL = process.env.REACT_APP_BACKEND_URL;

const apiClient = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

/* obtenemos Choferes */
export const obtenerChoferes = async () => {
  try {
    const response = await axios.get(`${BASE_API_URL}choferes/obtenerChoferes`);
    return response.data;
  } catch (error) {
    throw error;
  } 
};

/* actualizar Choferes */
export const actualizarChoferes = async (id, choferesData) => {
  try {
    const response = await axios.put(`${BASE_API_URL}choferes/actualizarChoferes/${id}`, choferesData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/* crear Choferes */
export const crearChoferes = async (choferesData) => {
  try {
    const response = await axios.post(`${BASE_API_URL}choferes/crearChoferes`, choferesData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/* eliminar choferes */
export const eliminarChoferes = async (id) => {
  try {
    const response = await axios.delete(`${BASE_API_URL}choferes/eliminarChoferes/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};


/* aqui actualizamos el hash */
export const actualizarPasswordUsuario = async (nuevaPassword) => {
  try {
    const respuesta = await apiClient.post("/usuarios/actualizarPassword", {
      nuevaPassword,
    });
    return respuesta.data;
  } catch (error) {
    console.error("Error al actualizar la contraseña:", error);
    throw error;
  }
};

/* obtenemos agentes de negocios */
export const obtenerAgentesNegocios = async () => {
  try {
    const response = await axios.get(`${BASE_API_URL}usuarios/obtenerAgentesNegocios`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/* obtenemos clientes */
export const obtenerClientes = async () => {
  try {
    const response = await axios.get(`${BASE_API_URL}cedibles/obtenerClientes`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/* obtener codigo del chofer */
export const obtenerCodigoChofer = async () => {
  try {
    const response = await axios.get(`${BASE_API_URL}usuarios/obtenerProximoCodChofer`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

