import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Link } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { iniciarSesion } from "../services/LoginServices";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login } from "../components/componentesRedux/userSlice";
import fondo from "../components/assets/img/fotosInicio/foto2.jpg";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import logo from "../components/assets/img/logoCargoex.png";
import { CircularProgress } from "@mui/material";
import { IconButton } from "@mui/material";
import Typography from "@mui/material/Typography";

function LoginPage() {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const iniciarlogin = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(false);
    try {
      const response = await iniciarSesion(email, password);
      if (response.success) {
        dispatch(login(response.data));
        setLoading(false);
        navigate("/");
        toast.success("Inicio de sesión exitoso");
      } else {
        toast.error(response.message || "Error en el inicio de sesión");
        setError(true);
      }
    } catch (error) {
      console.error("Error al intentar iniciar sesión:", error);
      toast.error("Error al iniciar sesión. Por favor, inténtalo de nuevo.");
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ThemeProvider theme={createTheme()}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.3)), url(${fondo})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) => (t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900]),
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></Grid>

        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img src={logo} alt="Logo" style={{ height: "80px", width: "300px", maxWidth: "100%" }} />
            <Box component="form" noValidate onSubmit={iniciarlogin} sx={{ mt: 1 }}>
            <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Correo electrónico"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value.trim())}
                error={error}
              />
            <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Contraseña"
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value.trim())}
                error={error}
                InputProps={{
                  endAdornment: (
                    <IconButton aria-label="toggle password visibility" onClick={togglePasswordVisibility} edge="end">
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  ),
                }}
              />
              <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Recuérdame" />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={loading}
                style={{ backgroundColor: "#041562", color: "#fff" }}
              >
                {loading ? <CircularProgress size={24} color="inherit" /> : "Iniciar sesión"}
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link to="/solicitarRestablecimiento" style={{ textDecoration: "none" }}>
                    ¿Olvidaste tu contraseña?
                  </Link>
                </Grid>
              </Grid>
            </Box>
            <Typography variant="body2" color="textSecondary" align="center" sx={{ mt: 8 }}>
              &copy; {new Date().getFullYear()} CargoEx. Todos los derechos reservados.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default LoginPage;
