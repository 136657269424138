import React, { useState, useEffect, useCallback, useRef } from "react";
import { Button, Grid, TextField, Autocomplete, Typography, Paper, CircularProgress } from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import moment from "moment";
import useSound from "use-sound";
import sonido1 from "../../../components/OperacionesComponentes/componentesManifiestos/Sonidos/AlertaMa.mp3";
import {
  buscarChoferesInhouse,
  insertPickingTemp,
  insertManifiesto,
  deletePickingTemp,
  insertPicking,
  buscarDatosPickingTempInhouse,
  downloadManifestPdfInhouse,
  getOrdenIdReferencia,
} from "../../../services/ManifiestosServices";
//import { obtenerClientesRetiro } from "../../../services/RetirosIntegracionServices";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { obtenerClienteModuloCliente } from "../../../services/ReportesServices";
import { toast } from "react-toastify";
import TablaManifiestos from "../../../components/OperacionesComponentes/componentesManifiestos/TablaManifiestos";
import DeleteIcon from "@mui/icons-material/Delete";

const ManifiestosSalidaInhouseRef = () => {
  /* accedemos al id de usuario */

  const inputRef = useRef(null);
  const userInfo = useSelector((state) => state.user.userInfo);
  const id_user = userInfo ? userInfo.id : null;
  const cod_cliente_user = userInfo ? userInfo.cod_cliente : null;
  const nombreUser = userInfo ? userInfo.name : null;
  //const [clienteDisabled, setClienteDisabled] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState(null);
  const [idCliente, setIdCliente] = useState("");
  const [proceso, setProceso] = useState("");
  const [fechaActual, setfechaActual] = useState("");
  const [ciudadUser] = useState("SANTIAGO");
  const [nota, setNota] = useState("");
  const [choferes, setChoferes] = useState([]);
  const [idChofer, setIdChofer] = useState();
  const [selectedChofer, setSelectedChofer] = useState(null);
  const [comunaRuta, setComunaRuta] = useState("");
  const [ordenesDataTabla, setOrdenesDataTabla] = useState([]);
  const [totalBultos, setTotalBultos] = useState(0);
  const [totalOrdenes, setTotalOrdenes] = useState(0);
  const [playsound] = useSound(sonido1);
  const [loadingEliminar, setLoadingEliminar] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [isbtnEnviar, setIsbtnEnviar] = useState(false);

  useEffect(() => {
    const fetchDataTemporal = async () => {
      try {
        const datos = {
          COD_USUARIO: id_user,
        };
        const dataPickingTemp = await buscarDatosPickingTempInhouse(datos);

        let totalBultosTemp = 0;
        if (dataPickingTemp && dataPickingTemp.results) {
          dataPickingTemp.results.forEach((orden) => {
            totalBultosTemp += orden.BULTOS;
          });
          setTotalOrdenes(dataPickingTemp.results.length);
        }

        setTotalBultos(totalBultosTemp);

        if (dataPickingTemp && dataPickingTemp.results) {
          setOrdenesDataTabla(dataPickingTemp.results);
        } else {
          setOrdenesDataTabla([]);
        }
      } catch {}

      setProceso("INHOUSE");
    };
    fetchDataTemporal();
  }, [id_user, userInfo]);

  useEffect(() => {
    const fetchChoferes = async () => {
      try {
        const fechaActual = moment().format("YYYY-MM-DD HH:mm");
        setfechaActual(fechaActual);

        const choferesData = await buscarChoferesInhouse(id_user);
        const choferesOptions = choferesData.map((choferes) => ({
          value: choferes.COD_CHOFER,
          label: choferes.NOMBRE,
        }));
        setChoferes(choferesOptions);
      } catch {}
    };
    fetchChoferes();
  }, [cod_cliente_user, id_user, userInfo.tipo_usuario]);

  useEffect(() => {
    const fetchClientes = async () => {
      try {
        if (userInfo.tipo_usuario === "CLIENTE") {
          const clientes = await obtenerClienteModuloCliente(cod_cliente_user);
          setSelectedCliente(clientes[0].NOMBRE);
          setIdCliente(clientes[0].ID);
        } else {
          const clientesData = [{ label: "NOVOFARMA SERVICE S.A.", value: 632 }];

          setSelectedCliente(clientesData[0].label);
          setIdCliente(clientesData[0].value);
          setOptions(clientesData);

          /* const dataAll = await obtenerClientesRetiro();

          // Extraer los nombres de los clientes y crear opciones
          const clientOptions = dataAll.map((cliente) => ({
            value: cliente.ID,
            label: cliente.NOMBRE,
          }));
          setOptions(clientOptions); */
        }
      } catch (error) {
        console.error("Error fetching dataAll:", error);
        toast.error("Error obteniendo datos. Por favor, inténtalo de nuevo.");
      }
    };
    fetchClientes();
  }, [cod_cliente_user, userInfo.tipo_usuario]);

  const columns = [
    { field: "COD_BARRA", headerName: "COD. BARRA", width: 100 },
    { field: "OD", headerName: "OD", width: 100 },
    { field: "NOMBRE", headerName: "Cliente", width: 400 },
    { field: "BULTOS", headerName: "Bultos", width: 100 },
    { field: "TIPO_ORDEN", headerName: "Tipo Orden", width: 200 },
    { field: "NOMBRE_DESTINO", headerName: "Comuna", width: 200 },
    {
      field: "delete",
      headerName: "Eliminar",
      width: 100,
      renderCell: (params) => (
        <DeleteIcon style={{ color: "#DA251C", cursor: "pointer" }} onClick={() => handleDelete(params.row.OD)} />
      ),
    },
  ];
  /* borrar ordenes de la tabla y también de la base de datos */
  const handleDelete = async (orden) => {
    setLoadingEliminar(true);
    const result = await Swal.fire({
      title: "¿Estás seguro?",
      text: "No podrás revertir esta acción",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, eliminar!",
    });

    if (result.isConfirmed) {
      try {
        const response = await deletePickingTemp({
          OD: orden,
          COD_USUARIO: id_user,
          PROCESO: proceso,
        });

        if (response.message === "PickingTemp Eliminado con éxito") {
          /* setOrdenesDataTabla((prevOrdenes) => prevOrdenes.filter((o) => o.OD !== orden)); */
          const datos = {
            COD_USUARIO: id_user,
            COD_CHOFER: idChofer,
            PROCESO: proceso,
          };
          try {
            const resultados = await buscarDatosPickingTempInhouse(datos);

            let totalBultosTemp = 0;
            if (resultados && resultados.results) {
              resultados.results.forEach((orden) => {
                totalBultosTemp += orden.BULTOS;
              });
              setTotalOrdenes(resultados.results.length);
            }

            setTotalBultos(totalBultosTemp);

            if (resultados && Array.isArray(resultados.results)) {
              setOrdenesDataTabla(resultados.results);
            } else {
              setOrdenesDataTabla([]);
            }
          } catch (error) {
            console.error("Error al buscar datos de picking temp:", error);
            setOrdenesDataTabla([]);
          }

          Swal.fire("Eliminado!", "El registro ha sido eliminado.", "success");
        } else {
          Swal.fire("No se pudo eliminar", response.message, "error");
        }
      } catch (error) {
        console.error("Error al eliminar el picking temp:", error);
        Swal.fire("Error!", "No se pudo eliminar el registro debido a un error en el servidor.", "error");
      } finally {
        setLoadingEliminar(false);
      }
    }
    setLoadingEliminar(false);
  };

  const showError = (message) => {
    Swal.fire({
      icon: "error",
      title: message,
    });
    playsound();
    inputRef.current.value = "";
  };

  const getFecha = () => {
    try {
      const fechaActual = new Date();
      // Convertir a fecha y hora local de Santiago de Chile
      const options = { timeZone: "America/Santiago" };
      const fecha = fechaActual.toLocaleDateString("es-CL", options);
      // Separar los elementos de la fecha
      const [dia, mes, año] = fecha.split("-");
      // Crear la nueva fecha con el año primero
      const nuevaFecha = `${año}-${mes}-${dia}`;
      const hora = fechaActual.toLocaleTimeString("es-CL", options);
      // Concatenar fecha y hora sin coma entre ellos
      const fechaHoraFormateada = `${nuevaFecha} ${hora}`;
      return fechaHoraFormateada;
    } catch (error) {
      console.error("Error al obtener la fecha:", error);
      return null; // O cualquier otro manejo de errores que desees
    }
  };

  const resetState = () => {
    inputRef.current.value = "";
    setNota("");
    setComunaRuta("");
    setOrdenesDataTabla([]);
    setTotalBultos(0);
    setIdChofer("");
    const fechaActual = moment().format("YYYY-MM-DD HH:mm");
    setfechaActual(fechaActual);
    setSelectedChofer(null);
    setTotalOrdenes("");
    setIsbtnEnviar(false);
    setLoading(false);
    /* setIdCliente("");
    setSelectedCliente(null); */
    //setClienteDisabled(false);
  };

  const handleInputCliente = async (event, newValue) => {
    try {
      setIdCliente(newValue.value);
      const codi = newValue.value;
      if (codi !== null) {
        try {
          setSelectedCliente(newValue);
          //    setClienteDisabled(true);
        } catch {}
      }
    } catch (error) {}
  };

  const handleChangeChoferes = async (event, newValue) => {
    try {
      setSelectedChofer(newValue);
      const codi = newValue.value;
      if (codi !== null) {
        setIdChofer(newValue.value);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangeNota = (event) => {
    setNota(event.target.value);
  };

  const ordenIngresada = useCallback(
    async (event) => {
      event.preventDefault();
      const ordenValue = inputRef.current.value.trim();

      try {
        if (!idCliente) {
          showError("Debe seleccionar un cliente!!!");
          return;
        } else if (!idChofer) {
          showError("Debe seleccionar un chofer!!!");
          return;
        }
        const codCliente = parseInt(idCliente);
        const datos = {
          COD_CLIENTE: idCliente,
          COD_BARRA: ordenValue,
        };
        const dataOrden = await getOrdenIdReferencia(datos);
        console.log(dataOrden);

        if (!dataOrden || dataOrden.length === null || dataOrden.length === 0) {
          showError("No se encontro orden con el codigo entregado para el cliente seleccionado!!!");
          return;
        } else if (dataOrden[0].COD_CLIENTE !== codCliente) {
          showError("Orden de Cliente distinto!!!");
          return;
        } else if (!ordenValue) {
          Swal.fire({
            icon: "warning",
            title: "Orden vacía",
            text: "Debe ingresar un valor de orden.",
          });
        }

        const ordenExistente = ordenesDataTabla.some((orden) => orden.ID === dataOrden[0].ID);
        if (ordenExistente) {
          showError("Orden ya ingresada al manifiesto!!!");
          return;
        } else {
          ingresarOrdenTabla(dataOrden);
          inputRef.current.value = "";
        }
      } catch (error) {
        console.error("Error al buscar los datos de la orden:", error);
      } finally {
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [idCliente, ordenesDataTabla, idChofer]
  );

  const ingresarOrdenTabla = async (dataOrden) => {
    try {
      if (dataOrden && dataOrden.length > 0) {
        const fecha = getFecha();

        try {
          await insertPickingTemp({
            FH_SYS: fecha,
            OD: dataOrden[0].OD,
            COD_USUARIO: id_user,
            COD_CHOFER: idChofer,
            COMUNA: "SCL",
            PROCESO: proceso,
          });

          setOrdenesDataTabla([...ordenesDataTabla, dataOrden[0]]);
          setTotalBultos(totalBultos + dataOrden[0].BULTOS);
          setTotalOrdenes(totalOrdenes + 1);
        } catch (error) {
          console.error("Error:", error);
        }
        inputRef.current.value = "";
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleIngresarOrden = async (event) => {
    await ordenIngresada(event);
  };

  const crearManifeisto = async () => {
    try {
      const fecha = await getFecha();

      const data = {
        FH_SYS: fecha,
        FH_CREACION: fecha,
        FH_CIERRE: fecha,
        COD_USUARIO: id_user,
        PROCESO: proceso,
        ID_CLIENTE: idCliente,
        COD_CHOFER: idChofer,
        ESTADO: "CERRADO",
        COD_OPERADOR_ENTRADA: idChofer,
        COD_CHOFER_ENTRADA: idChofer,
        NOMBRE_CHOFER_ENTRADA: nombreUser,
        SELLOS: "",
        CANTIDAD_VALIJAS: 0,
        TOTAL_BULTOS: totalBultos,
        TIPO_RUTA: "TERRESTRE",
        TIPO_MANIFIESTO: proceso,
        ORIGEN: "SCL",
        DESTINO: "SCL",
        NOTA: nota,
        FH_MAIL: "",
        RUTA: comunaRuta,
      };

      const response = await insertManifiesto(data);

      const datosParaEnviar = {
        COD_USUARIO: id_user,
        TIPO_MANIFIESTO: proceso,
        DESTINO: "SCL",
        ID_MANIFIESTO: response,
      };
      try {
        await insertPicking(datosParaEnviar);
      } catch (error) {
        console.log("Fallo el envio");
      }
      return response;
    } catch (error) {
      console.error("Error al crear el manifiesto:", error);
    }
  };

  const handleCrearManifiesto = async () => {
    setLoading(true);
    setIsbtnEnviar(false);
    if (ordenesDataTabla.length === 0) {
      showError("Manifiesto debe contener alguna orden!!!");
      return;
    } else {
      Swal.fire({
        title: "Seguro de Crear Manifiesto?",
        text: "Esta a punto de crear un nuevo Manifiesto!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, Crear!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          setLoading(false);
          const numeroManifiesto = await crearManifeisto();
          resetState();
          Swal.fire({
            title: "Manifiesto Creado",
            text: "N°: " + numeroManifiesto,
            icon: "success",
          });
          try {
            await downloadManifestPdfInhouse(numeroManifiesto, true);
          } catch (error) {
            showError("Error al descargar el PDF del manifiesto");
            return;
          }
        }
        setLoading(false);
      });
    }
  };

  return (
    <div>
      <Paper style={{ padding: "10px" }}>
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <Autocomplete
                disablePortal
                size="small"
                id="combo-box-demo1"
                onChange={handleInputCliente}
                options={options}
                value={selectedCliente}
                renderInput={(params) => <TextField {...params} label="CLIENTE" />}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                disabled
                size="small"
                id="outlined-size-small"
                label="ORIGEN MANIFIESTO"
                value={ciudadUser}
                sx={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Autocomplete
                disableClearable
                value={selectedChofer}
                disablePortal
                id="size-small-outlined-multi"
                size="small"
                options={choferes}
                onChange={handleChangeChoferes}
                sx={{ width: "100%" }}
                PaperComponent={({ children }) => (
                  <Paper
                    sx={{
                      width: "100%",
                      textAlign: "left",
                      "& li": { textAlign: "left" },
                    }}
                  >
                    {children}
                  </Paper>
                )}
                renderInput={(params) => <TextField {...params} label="CHOFER" />}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                disabled
                size="small"
                id="outlined-size-small"
                name="FH_ACTUAL"
                type="datetime-local"
                label="Fecha"
                sx={{ width: "100%" }}
                value={fechaActual}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                size="small"
                id="outlined-size-small"
                fullWidth
                label="Nota"
                multiline
                rows={1}
                name="message"
                value={nota}
                onChange={handleChangeNota}
                required
              />
            </Grid>
            <Grid item xs={12} sm={2}></Grid>
            <Grid item xs={12} sm={6} justifyContent="flex-end">
              <Button
                variant="contained"
                size="small"
                sx={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#041562",
                }}
                disabled={isbtnEnviar}
                onClick={handleCrearManifiesto}
              >
                {loading ? <CircularProgress sx={{ color: "#FFFFFF" }} size={24} /> : "Crear Manifiesto"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
      <Paper style={{ padding: "10px", marginTop: "10px" }}>
        <form>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={3}>
              <input
                ref={inputRef}
                type="text"
                placeholder="Ingrese CODIGO BARRA"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    ordenIngresada(e);
                  }
                }}
                required
              />
            </Grid>
            <Grid item xs={12} sm={2} justifyContent="flex-end">
              <Button
                variant="contained"
                size="medium"
                sx={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#041562",
                }}
                onClick={handleIngresarOrden}
              >
                INGRESAR
              </Button>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Arial, sans-serif",
                  color: "#041562",
                  fontWeight: "bold",
                  fontSize: "1.5rem",
                }}
              >
                ORDENES: {totalOrdenes}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Arial, sans-serif",
                  color: "#041562",
                  fontWeight: "bold",
                  fontSize: "1.5rem",
                }}
              >
                BULTOS: {totalBultos}
              </Typography>
            </Grid>
          </Grid>
          <Grid>
            <Grid style={{ marginTop: "10px" }}>
              <TablaManifiestos rows={ordenesDataTabla} columns={columns} loading={loadingEliminar} />
            </Grid>
          </Grid>
        </form>
      </Paper>
    </div>
  );
};

export default ManifiestosSalidaInhouseRef;
